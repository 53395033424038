import ApprovedNotPaidInvoices from "@/components/report/ApprovedNotPaidInvoices";
import InProgressInvoices from "@/components/report/InProgressInvoices";
import PaidInvoices from "@/components/report/PaidInvoices";
import RejectedInvoices from "@/components/report/RejectedInvoices";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

const AllInvoices = () => {
	return (
		<div>
			<h1 className="text-4xl font-bold mb-2">Reports</h1>
			<Tabs defaultValue="paid" className="mb-2">
				<TabsList className="w-full gap-2">
					<TabsTrigger
						value="paid"
						className="data-[state=active]:bg-primary data-[state=active]:text-white rounded-md hover:bg-primary/10"
					>
						Paid Claims
					</TabsTrigger>
					<TabsTrigger
						value="approved"
						className="data-[state=active]:bg-primary data-[state=active]:text-white rounded-md hover:bg-primary/10"
					>
						Approved Not Paid
					</TabsTrigger>
					<TabsTrigger
						value="progress"
						className="data-[state=active]:bg-primary data-[state=active]:text-white rounded-md hover:bg-primary/10"
					>
						In Progress
					</TabsTrigger>
					<TabsTrigger
						value="rejected"
						className="data-[state=active]:bg-primary data-[state=active]:text-white rounded-md hover:bg-primary/10"
					>
						Disapproved
					</TabsTrigger>
				</TabsList>
				<TabsContent value="paid">
					<PaidInvoices />
				</TabsContent>
				<TabsContent value="approved">
					<ApprovedNotPaidInvoices />
				</TabsContent>
				<TabsContent value="progress">
					<InProgressInvoices />
				</TabsContent>
				<TabsContent value="rejected">
					<RejectedInvoices />
				</TabsContent>
			</Tabs>
		</div>
	);
};

export default AllInvoices;
