import React, { useState } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, 
	// useQueryClient 
} from "react-query";
import { toast } from "sonner";
// import { useNavigate } from "react-router-dom";
import { HiOutlinePencilAlt } from "react-icons/hi";
import {
	getAllDepartments,
	getUserByEmail,
	// getUserTypes,
	UpdateUserdepartment,
} from "@/services/auth-functions";
import {
	// EditUserByIdParams,
	UserDepartment,
	// UserSystem,
} from "@/context/User/UserContext";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Check, ChevronsUpDown,
	//  Phone 
	} from "lucide-react";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import { cn } from "@/lib/utils";
import { STALE_TIME } from "@/constants";
import { UserState } from "@/context/User/UserReducer";
import { Input } from "../ui/input";

const formSchema = z.object({
	email: z.string().email(),
	department: z.number(),
	phone: z
		.string()
		.max(12, { message: "Phone number is too long" })
		.regex(/^254\d{9}$/, {
			message: "Phone number must start with '254' and be exactly 12 digits.",
		}),
});

const UpdateUserInfo: React.FC<{
	user: UserState;
	accessToken: string;
}> = ({ user, accessToken }) => {
	const [open, setOpen] = useState(false);
	const [departmentOpen, setDepartmentOpen] = useState(false);

	const getManager = useMutation({
		mutationKey: ["getManager"],
		mutationFn: (data: { email: string; department: number; phone: string }) =>
			getUserByEmail(accessToken, data.email),
		onSuccess: (response: UserDepartment, { department, phone }) => {
			setManager.mutate({
				managername: response.displayName,
				manageremail: response.userPrincipalName,
				department,
				phone,
			});
		},
		onError: () => {
			toast.error("Error", { description: "User Not found" });
		},
	});

	const setManager = useMutation({
		mutationKey: ["setManager"],
		mutationFn: (data: {
			managername: string;
			manageremail: string;
			department: number;
			phone: string;
		}) =>
			UpdateUserdepartment(accessToken, {
				managername: data.managername,
				manageremail: data.manageremail,
				department: data.department.toString(),
				phone: data.phone.toString(),
			}),
		onSuccess: (data) => {
			toast.success("Success", {
				description: data.message,
			});
			setOpen(false);
			form.reset();
			setManager.reset();
			console.log(data);
			window.location.reload();
		},
		onError: () => {
			toast.error("Error: User not found", {
				description: "The email address provided does not belong to any user.",
			});
		},
	});

	const departments = useQuery({
		queryKey: ["all-departments"],
		queryFn: () => getAllDepartments(accessToken),
		staleTime: STALE_TIME,
	});

	// const usertypes = useQuery({
	// 	queryKey: ["all-usertypes"],
	// 	queryFn: () => getUserTypes(accessToken),
	// 	staleTime: STALE_TIME,
	// });

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			email: user.check?.manageremail ? user.check?.manageremail : undefined,
			department: user.check?.departmentid ? user.check?.departmentid : undefined,
			phone: user.check?.phone ? user.check?.phone.toString() : undefined,
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		console.log(data);
		getManager.mutate(data);
	};

	const disabled = () => {
		if (setManager.isLoading) return true;
		if (setManager.isSuccess) return true;
		if (setManager.isLoading) return true;
		if (setManager.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<div className="flex gap-2">
				<DialogTrigger asChild>
					<Button>Update My Details</Button>
				</DialogTrigger>
			</div>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					<DialogTitle className="text-primary flex gap-2 items-center mb-1">
						<div className="bg-primary/20 p-1 rounded-full">
							<HiOutlinePencilAlt className="text-xl" />
						</div>
						Update My details
					</DialogTitle>
					<DialogDescription>
						Update your details below in order to create a claim.
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex items-center space-x-2 mb-4">
							<div className="grid flex-1 gap-2">
								<FormField
									name="email"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Approver Email</FormLabel>
											<FormControl>
												<Input
													type="email"
													placeholder="Enter email"
													disabled={disabled()}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
								{departments.isLoading ? (
									<span>Loading GL Accounts</span>
								) : departments.data ? (
									<FormField
										name="department"
										render={({ field }) => (
											<FormItem className="flex flex-col">
												<FormLabel>Department</FormLabel>
												<Popover
													open={departmentOpen}
													onOpenChange={(open) => setDepartmentOpen(open)}
												>
													<PopoverTrigger asChild>
														<FormControl>
															<Button
																variant="outline"
																role="combobox"
																className={cn(
																	"justify-between font-normal",
																	!field.value && "text-muted-foreground"
																)}
																disabled={disabled()}
															>
																{field.value
																	? departments.data.find(
																			(department) => department.departmentid === field.value
																	  )?.departmentname
																	: "Select department"}
																<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
															</Button>
														</FormControl>
													</PopoverTrigger>
													<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
														<Command>
															<CommandInput placeholder="Search department..." />
															<CommandList>
																<CommandEmpty>No account found.</CommandEmpty>
																<CommandGroup>
																	{departments.data.map((item) => (
																		<CommandItem
																			value={item.departmentname}
																			key={item.departmentid.toString()}
																			onSelect={() => {
																				form.setValue("department", item.departmentid);
																				setDepartmentOpen(false);
																			}}
																		>
																			<Check
																				className={cn(
																					"mr-2 h-4 w-4",
																					item.departmentid.toString() === field.value
																						? "opacity-100"
																						: "opacity-0"
																				)}
																			/>
																			{item.departmentname}
																		</CommandItem>
																	))}
																</CommandGroup>
															</CommandList>
														</Command>
													</PopoverContent>
												</Popover>
												<FormMessage />
											</FormItem>
										)}
									/>
								) : null}
								<FormField
									name="phone"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Phone Number (Mpesa)</FormLabel>
											<FormControl>
												<Input
													placeholder="254712345678"
													type="text"
													disabled={disabled()}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<DialogFooter className="sm:justify-end">
							<DialogClose asChild>
								<Button type="button" variant="ghost">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit" disabled={disabled()}>
								{disabled() ? "Submitting..." : "Confirm"}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default UpdateUserInfo;
