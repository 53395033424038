import React, { useState, useContext } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/select";
import { Button } from "../ui/button";
import { z } from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "sonner";
import { Input } from "../ui/input";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import { MdOutlineCloudUpload } from "react-icons/md";
import {
  fileExtensions,
  getFileExtension,
  imageExtensions,
  invoiceFileExtensions,
  STALE_TIME,
} from "@/constants";
import { uploadInvoiceFile } from "@/services/invoice-functions";
import { UserContext } from "@/context/User/UserContext";
import { getLocalCities } from "@/services/auth-functions";

// Define form schema with desc and city (city is now a number)
const formSchema = z.object({
  attachment: z.array(z.instanceof(File)).min(0).max(1),
  attachment2: z.array(z.instanceof(File)).min(0).max(1),
  desc: z.string().min(1, "Description is required"),
  city: z.number().min(1, "City is required"), // City is a number now
});

const InvoiceFileUpload: React.FC<{
  accessToken: string;
}> = ({ accessToken }) => {
  const user = useContext(UserContext);
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);
  const [uploadedFiles2, setUploadedFiles2] = useState<FilePreview[]>([]);

  // Fetch available cities
  const localCities = useQuery({
    queryKey: ["getlocalcities"],
    queryFn: () => getLocalCities(user?.accessToken!),
    enabled: !!user?.accessToken,
    staleTime: STALE_TIME,
  });

  // Initialize form with default values
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      attachment: [],
      attachment2: [],
      desc: "",
      city: 0, // Default value for city is now a number
    },
  });

  // Mutation for uploading files
  const upload = useMutation({
    mutationKey: ["setManager"],
    mutationFn: ({
      files,
      desc,
      city,
    }: {
      files: File[];
      desc: string;
      city: number;
    }) => uploadInvoiceFile(accessToken, files, desc, city),
    onSuccess: (response) => {
      const res = response as any
      toast.success("Invoice File Uploaded", {
        description: res.message,
      });
      setOpen(false);
      form.reset();
      upload.reset();
      setUploadedFiles([]);
      setUploadedFiles2([]);
      queryClient.refetchQueries(["invoice", "user"]);
    },
    onError: (error) => {
      const err = error as any;
      toast.error("Error", { description: err.response.data.message });
    },
  });

  // Submit form data with combined files, description, and city (as a number)
  const onSubmit = (data: z.infer<typeof formSchema>) => {
    const allFiles = [...data.attachment, ...data.attachment2];
    const desc = data.desc;
    const city = data.city;
    upload.mutate({ files: allFiles, desc, city });
  };

  // Disable form while upload is loading or completed
  const disabled = () => upload.isLoading || upload.isSuccess;

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setFileFn: Function,
    formField: "attachment" | "attachment2"
  ) => {
    const rawFiles = Array.from(event.target.files || []);
    const files = rawFiles.filter((file) => {
      const fileExt = getFileExtension(file.name);
  
      // If the formField is 'attachment', accept both acceptedFiles and invoiceFileExtensions
      if (formField === "attachment") {
        if (fileExtensions.includes(fileExt) || invoiceFileExtensions.includes(fileExt)  || imageExtensions.includes(fileExt)) {
          return true;
        }
      }
      
      // If the formField is 'attachment2', only accept from invoiceFileExtensions
      if (formField === "attachment2") {
        if (invoiceFileExtensions.includes(fileExt)) {
          return true;
        }
      }
  
      // Show an error if the file doesn't match the allowed extensions
      toast.error("Error: File Type", {
        description: `File type ${fileExt} is not applicable for ${formField}`,
      });
      return false;
    });
  
    // Set the files and their previews
    form.setValue(formField, files);
    const filePreviews = files.map((file) => ({
      filename: file.name,
      preview: URL.createObjectURL(file),
    }));
    setFileFn(filePreviews);
  };
  

  // Remove file from attachment or attachment2
  const removeFile = (
    index: number,
    formField: "attachment" | "attachment2",
    setFileFn: Function
  ) => {
    const allFiles = form.getValues(formField);
    const updatedFiles = allFiles.filter((_, i) => i !== index);
    form.setValue(formField, updatedFiles);
    const updatedPreviews = updatedFiles.map((file: File) => ({
      filename: file.name,
      preview: URL.createObjectURL(file),
    }));
    setFileFn(updatedPreviews);
  };

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <DialogTrigger asChild>
        <Button className="self-start">Upload Per Diem</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-md">
        <DialogHeader className="text-left">
          <DialogTitle className="text-primary flex gap-2 items-center mb-1">
            Upload Per Diem
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex items-center space-x-2 mb-4">
              <div className="grid flex-1 gap-2">
                <FormField
                  name="attachment"
                  render={({ field }) => (
                    <FormItem className="mb-2">
                      <FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg px-4 py-8 cursor-pointer hover:bg-muted">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <MdOutlineCloudUpload className="text-4xl" />
                          <p>
                            <b>Click to upload budget File</b>
                          </p>
                        </div>
                        <FormControl className="hidden">
                          <Input
                            type="file"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                setUploadedFiles,
                                "attachment"
                              )
                            }
                            disabled={disabled()}
                          />
                        </FormControl>
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                {uploadedFiles.length > 0 && (
                  <AttachmentPreview
                    files={uploadedFiles}
                    deleteFile={(index) =>
                      removeFile(index, "attachment", setUploadedFiles)
                    }
                    disabled={disabled()}
                  />
                )}
                <FormField
                  name="attachment2"
                  render={({ field }) => (
                    <FormItem className="mb-2">
                      <FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg px-4 py-8 cursor-pointer hover:bg-muted">
                        <div className="flex flex-col items-center justify-center gap-2">
                          <MdOutlineCloudUpload className="text-4xl" />
                          <p>
                            <b>
                              Click to upload budget breakdown file (amount per
                              user)
                            </b>
                          </p>
                        </div>
                        <FormControl className="hidden">
                          <Input
                            type="file"
                            onChange={(e) =>
                              handleFileChange(
                                e,
                                setUploadedFiles2,
                                "attachment2"
                              )
                            }
                            disabled={disabled()}
                          />
                        </FormControl>
                      </FormLabel>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {uploadedFiles2.length > 0 && (
                  <AttachmentPreview
                    files={uploadedFiles2}
                    deleteFile={(index) =>
                      removeFile(index, "attachment2", setUploadedFiles2)
                    }
                    disabled={disabled()}
                  />
                )}

                <FormField
                  name="desc"
                  render={({ field }) => (
                    <FormItem className="mb-4">
                      <FormLabel>Event Description</FormLabel>
                      <FormControl>
                        <Input
                          type="text"
                          placeholder="Enter a description for the claim"
                          {...field}
                          disabled={disabled()}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  name="city"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        Select The City You're Travelling To
                      </FormLabel>
                      <FormControl>
                        <Select
                          onValueChange={(e) => field.onChange(Number(e))} // Parse value to number
                          disabled={disabled()}
                        >
                          <SelectTrigger>
                            <SelectValue placeholder="City" />
                          </SelectTrigger>
                          <SelectContent className="max-h-48">
                            {localCities.data &&
                              localCities.data.map((item) => (
                                <SelectItem
                                  key={item.cityid}
                                  value={String(item.cityid)}
                                >
                                  {item.city}
                                </SelectItem>
                              ))}
                          </SelectContent>
                        </Select>
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>

            <DialogFooter className="mt-2">
              <Button type="submit" disabled={disabled()} className="w-full">
                {upload.isLoading ? "Uploading..." : "Upload"}
              </Button>
            </DialogFooter>
          </form>
        </Form>

        <DialogClose />
      </DialogContent>
    </Dialog>
  );
};

export default InvoiceFileUpload;
