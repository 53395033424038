import { getManager, getPhoto, getProfile } from "@/authConfig";
import {
	Department,
	EditUserByIdParams,
	LocalCity,
	UserCheck,
	UserColleague,
	UserDepartment,
	UserJobGrade,
	UserManager,
	UserSystem,
	UserType,
} from "@/context/User/UserContext";
import axios from "axios";

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

export const getUserCheck = async (accessToken: string): Promise<UserCheck> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};
	const res = await axios.get(
		`${process.env.REACT_APP_SERVER_DOMAIN}auth/authcheck`,
		{ headers }
	);
	return res.data.user;
};

export const graphPhoto = async (accessToken: string): Promise<string> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	const options = {
		method: "GET",
		headers: headers,
		url: getPhoto.graphMeEndpoint,
		responseType: "blob" as const,
	};
	const res = await axios(options);
	return URL.createObjectURL(res.data);
};

export const getUserDetailById = async (
	accessToken: string,
	id: string
): Promise<UserSystem> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(
		`${server_url}auth/getsingleuserdetailbyid/${id}`,
		config
	);

	return res.data;
};

export const getSingleUserDetails = async (accessToken: string) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}auth/getsingleuserdetails`, config);

	return res.data;
};

export const getDepartment = async (
	accessToken: string
): Promise<UserDepartment> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	const options = {
		method: "GET",
		headers: headers,
		url: getProfile.graphMeEndpoint,
	};
	const res = await axios(options);
	return res.data;
};

export const getAllDepartments = async (
	accessToken: string
): Promise<Department[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(`${server_url}auth/getalldepartments`, config);

	return res.data;
};

export const getUserTypes = async (
	accessToken: string
): Promise<UserType[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(`${server_url}auth/getusertypes`, config);

	return res.data;
};

export const updateUserById = async (
	accessToken: string,
	details: EditUserByIdParams
): Promise<{ message: string }> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.post(
		`${server_url}auth/updateuserbyid`,
		details,
		config
	);

	return res.data;
};

export const getUserManager = async (
	accessToken: string
): Promise<{ data: UserManager; token: string }> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	const options = {
		method: "GET",
		headers: headers,
		url: getManager.graphMeEndpoint,
	};
	const res = await axios(options);

	return { data: res.data, token: accessToken };
};

export const getColleagues = async (
	accessToken: string,
	managerName: string
): Promise<{ value: UserColleague[] }> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	const options = {
		method: "GET",
		headers: headers,
		url: `https://graph.microsoft.com/v1.0/users/${managerName}/directReports`,
	};
	const res = await axios(options);
	return res.data;
};

export const getSystemUsers = async (
	accessToken: string
): Promise<UserSystem[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}auth/getallusers`, config);
	return res.data;
};

export const getUserByEmail = async (
	accessToken: string,
	principleName: string
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
	};

	const options = {
		method: "GET",
		headers: headers,
		url: `https://graph.microsoft.com/v1.0/users/${principleName}`,
	};

	const res = await axios(options);

	return res.data;
};

export const UpdateUserdepartment = async (
	accessToken: string,
	department: {
		managername: string;
		manageremail: string;
		department?: string;
		phone?: string;
	}
) => {
	console.log(department);

	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.post(
		`${server_url}auth/updateuserdepartment`,
		department,
		config
	);

	return res.data;
};

export const getJobGrades = async (
	accessToken: string
): Promise<UserJobGrade[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}auth/getjobgrades`, config);
	return res.data;
};

export const getLocalCities = async (
	accessToken: string
): Promise<LocalCity[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(`${server_url}auth/getlocalcities`, config);
	return res.data;
};
