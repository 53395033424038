import ColumnHeader from "@/components/data-table/ColumnHeader";
import DataTable from "@/components/data-table/DataTable";
import { STALE_TIME } from "@/constants";
import { UserContext, UserSystem } from "@/context/User/UserContext";
import { getSystemUsers } from "@/services/auth-functions";
import { ColumnDef } from "@tanstack/react-table";
import { useContext } from "react";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";

const SystemUsers = () => {
	const user = useContext(UserContext);

	const systemUsers = useQuery({
		queryKey: ["systemusers"],
		queryFn: () => getSystemUsers(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	return (
		<div>
			<h1 className="text-4xl font-bold mb-2">System Users</h1>
			<h3 className="text-zinc-600 font-light mb-4">
				Here is a list of users on the system.
			</h3>
			{systemUsers.data ? (
				<DataTable columns={Column} searchColumn="email" data={systemUsers.data} />
			) : null}
			{systemUsers.isLoading ? (
				<div className="flex justify-center">
					<span className="loader" />
				</div>
			) : systemUsers.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error showing invoices.</h5>
				</div>
			) : (
				systemUsers.data &&
				systemUsers.data.length === 0 && (
					<div className="align-centre">
						<h5 className="text-center">No invoices found</h5>
					</div>
				)
			)}
		</div>
	);
};

export default SystemUsers;

export const Column: ColumnDef<UserSystem>[] = [
	{
		accessorKey: "username",
		header: ({ column }) => <ColumnHeader column={column} title="Name" />,
		cell: ({ row }) => {
			const user = row.original;

			return (
				<NavLink
					to={`/allusers/${user.id}`}
					className="text-primary hover:underline"
				>
					{user.username}
				</NavLink>
			);
		},
	},
	{
		accessorKey: "email",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Email" />,
	},
	{
		accessorKey: "usertype",
		header: "User Type",
	},
	{
		accessorKey: "department",
		header: ({ column }) => <ColumnHeader column={column} title="Department" />,
	},
	{
		accessorKey: "country",
		header: ({ column }) => <ColumnHeader column={column} title="Country" />,
	},
];
