import React, { Fragment } from "react";
import spinner from "../../media/Double Ring-1s-200px.svg";

const Spinner = () => {
	return (
		<Fragment>
			<img src={spinner} alt="loading..." style={{ width: "120px" }} />
		</Fragment>
	);
};

export default Spinner;
