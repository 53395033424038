import ChangeFinanceApprover from "@/components/dialog/ChangeFinanceApprover";
import FinanceApproveOrReject from "@/components/dialog/FinanceApproveOrReject";
import InvoiceDetails from "@/components/InvoiceDetails";
import InvoiceSkeleton from "@/components/skeleton/InvoiceSkeleton";
import { STALE_TIME_INVOICE } from "@/constants";
import { UserContext } from "@/context/User/UserContext";
import { getSingleMasterInvoice } from "@/services/invoice-functions";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const FinanceApproval = () => {
	const { id } = useParams<{ id: string }>() as { id: string };
	const user = useContext(UserContext);

	const invoice = useQuery({
		queryKey: ["invoice", id],
		queryFn: () => getSingleMasterInvoice(user?.accessToken!, id),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
	});

	return (
		<div>
			{invoice.isLoading ? (
				<InvoiceSkeleton />
			) : invoice.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error loading claim.</h5>
				</div>
			) : (
				invoice.data && (
					<>
						<div className="flex gap-3 items-end justify-between flex-wrap">
							<div className="flex gap-3">
								<h1 className="font-medium text-4xl">
									CLAIM NO: {invoice.data.invoicemasterid}
								</h1>
								<ChangeFinanceApprover
									invoicemasterid={invoice.data.invoicemasterid}
									accessToken={user?.accessToken!}
								/>
							</div>

							{invoice.data.financeapproved === null &&
							invoice.data.financerejected === null ? (
								<FinanceApproveOrReject
									invoicemasterid={invoice.data.invoicemasterid}
									accessToken={user?.accessToken!}
									country={invoice.data.country}
								/>
							) : (
								<div className="bg-primary/20 text-primary px-4 py-2 rounded-md">
									Claim Processed
								</div>
							)}
						</div>
						<InvoiceDetails invoice={invoice.data} accessToken={user?.accessToken!} />
					</>
				)
			)}
		</div>
	);
};

export default FinanceApproval;
