import { z } from "zod";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import { acceptedFiles, STALE_TIME } from "@/constants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	CreateInvoiceParamsWithManagerApprover,
	getInvoiceTypes,
	InvoiceCreate,
} from "@/services/invoice-functions";
import { UserContext } from "@/context/User/UserContext";
import { toast } from "sonner";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import { MdOutlineCloudUpload } from "react-icons/md";
import { getSystemUsers } from "@/services/auth-functions";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import { Check, ChevronsUpDown } from "lucide-react";
import { cn } from "@/lib/utils";

const formSchema = z.object({
	invoicetype: z.number(),
	amount: z.number().min(1, {
		message: "Amount cannot be less than 1",
	}),
	kms: z
		.number()
		.min(1, {
			message: "Kms cannot be less than 1",
		})
		.optional(),
	description: z.string().min(1, {
		message: "Description is required",
	}),
	// attachment: z.string().min(1),
	managerapprovers: z.number(),
	attachment: z.array(z.instanceof(File)).min(0),
});

const CreateClaim = () => {
	const user = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [suOpen, setSuOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);
	const [showKms, setShowKms] = useState<boolean>(false);

	const queryClient = useQueryClient();

	const allowedKms = [1, 3];

	const invoiceTypes = useQuery({
		queryKey: ["invoiceTypes"],
		queryFn: () => getInvoiceTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const systemUsers = useQuery({
		queryKey: ["systemusers"],
		queryFn: () => getSystemUsers(user?.accessToken!),
		staleTime: STALE_TIME,
		enabled: !!user?.accessToken,
		onSuccess: (data) => {
			if (!user) return;
			if (!user.check) return;
			if (!user.check.manageremail) return;
			const manager = data.filter(
				(userCheck) => userCheck.email === user.check!.manageremail
			);
			if (manager && manager.length > 0 && "id" in manager[0])
				form.setValue("managerapprovers", manager[0].id);
		},
	});

	const invoiceCreate = useMutation({
		mutationKey: ["invoice_create"],
		mutationFn: (tableData: CreateInvoiceParamsWithManagerApprover) =>
			InvoiceCreate(user?.accessToken!, tableData),
		onSuccess: () => {
			queryClient.invalidateQueries(["invoice"]);
			setOpen(false);
			form.reset();
			toast.success("Invoice Created");
			invoiceCreate.reset();
			setUploadedFiles([]);
		},
		onError: (err) => {
			toast.error("Error creating claim, try again");
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			amount: 0,
			description: "",
			attachment: [],
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		const tableData: CreateInvoiceParamsWithManagerApprover = {
			amounts: data.amount,
			descriptions: data.description,
			invoicetypeids: data.invoicetype,
			files: data.attachment,
			kms: data.kms,
			managerapprovers: data.managerapprovers,
		};
		invoiceCreate.mutate(tableData);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawFiles = Array.from(event.target.files || []);
		const files = rawFiles.filter((file) => {
			if (acceptedFiles.includes(file.type)) return true;
			toast.error("File type not supported");
			return false;
		});
		const existingFiles = form.getValues("attachment");
		const allFiles = [...existingFiles, ...files];
		form.setValue("attachment", allFiles);
		const filePreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(filePreviews);
	};

	const disableFields = () => {
		if (invoiceCreate.isLoading) return true;
		if (invoiceCreate.isSuccess) return true;
		return false;
	};

	const removeFile = (index: number) => {
		const allFiles = form.getValues("attachment");
		const updatedFiles = allFiles.filter((_, i) => i !== index);
		form.setValue("attachment", updatedFiles);

		const updatedPreviews = updatedFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
	};

	useEffect(() => {
		if (open) {
			if (!systemUsers.data) return;
			if (!user) return;
			if (!user.check) return;
			if (!user.check.manageremail) return;
			const manager = systemUsers.data.filter(
				(userCheck) => userCheck.email === user.check!.manageremail
			);
			if (manager && manager.length > 0 && "id" in manager[0])
				form.setValue("managerapprovers", manager[0].id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button variant="default">Create Claim</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-lg">
				<DialogHeader>
					<DialogTitle className="text-left">Create Claim</DialogTitle>
				</DialogHeader>
				{/* <div className="grid gap-4 py-4">
					<div className="flex flex-col gap-1">
						<Label htmlFor="name">Invoice Type</Label>
						<Input id="name" placeholder="Select Invoice" className="col-span-3" />
					</div>
					<div className="flex flex-col gap-1">
						<Label htmlFor="username">Username</Label>
						<Input id="username" defaultValue="@peduarte" className="col-span-3" />
					</div>
				</div> */}
				{invoiceTypes.isLoading ? (
					<>Loading claim types</>
				) : invoiceTypes.data ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<FormField
								name="invoicetype"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Claim Type</FormLabel>
										<FormControl>
											<Select
												onValueChange={(e) => {
													field.onChange(parseFloat(e));
													if (allowedKms.includes(parseFloat(e))) {
														setShowKms(true);
														return;
													}
													setShowKms(false);
												}}
												disabled={disableFields()}
											>
												<SelectTrigger>
													<SelectValue placeholder="Select an option" />
												</SelectTrigger>
												<SelectContent>
													{invoiceTypes.data.map((item) => (
														<SelectItem value={item.invoicetypeid.toString()}>
															{item.description}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="amount"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Amount in KES</FormLabel>
										<FormControl>
											<Input
												type="number"
												placeholder="Enter amount"
												onChange={(e) => field.onChange(e.target.valueAsNumber)}
												disabled={disableFields()}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							{showKms ? (
								<FormField
									name="kms"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Number of Kms</FormLabel>
											<FormControl>
												<Input
													type="number"
													onChange={(e) => field.onChange(e.target.valueAsNumber)}
													disabled={disableFields()}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							) : null}
							<FormField
								name="description"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Description</FormLabel>
										<FormControl>
											<Textarea {...field} disabled={disableFields()} />
										</FormControl>
										<FormMessage className="font-light" />
									</FormItem>
								)}
							/>

							{systemUsers.isLoading ? (
								<span>Loading Users</span>
							) : systemUsers.data ? (
								<FormField
									name="managerapprovers"
									render={({ field }) => (
										<FormItem className="flex flex-col mb-2">
											<FormLabel>To Be Approved By:</FormLabel>
											<Popover open={suOpen} onOpenChange={(open) => setSuOpen(open)}>
												<PopoverTrigger asChild>
													<FormControl>
														<Button
															variant="outline"
															role="combobox"
															className={cn(
																"justify-between font-normal",
																!field.value && "text-muted-foreground"
															)}
															disabled={disableFields()}
														>
															{field.value
																? systemUsers.data.find((user) => user.id === field.value)
																		?.username
																: "Select approver"}
															<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
														</Button>
													</FormControl>
												</PopoverTrigger>
												<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
													<Command>
														<CommandInput placeholder="Search account..." />
														<CommandList>
															<CommandEmpty>No account found.</CommandEmpty>
															<CommandGroup>
																{systemUsers.data.map((item) => (
																	<CommandItem
																		value={item.username}
																		key={item.id}
																		onSelect={() => {
																			form.setValue("managerapprovers", item.id);
																			setSuOpen(false);
																		}}
																	>
																		<Check
																			className={cn(
																				"mr-2 h-4 w-4",
																				item.id.toString() === field.value
																					? "opacity-100"
																					: "opacity-0"
																			)}
																		/>
																		{item.username}
																	</CommandItem>
																))}
															</CommandGroup>
														</CommandList>
													</Command>
												</PopoverContent>
											</Popover>
											<FormMessage />
										</FormItem>
									)}
								/>
							) : null}
							<FormField
								name="attachment"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Attachments</FormLabel>
										<FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg p-4 cursor-pointer hover:bg-muted">
											<div className="flex flex-col items-center justify-center gap-2">
												<MdOutlineCloudUpload className="text-4xl" />
												<p>
													<b>Click to upload</b>
												</p>
												<p className="font-light">SVG, PNG, JPG or PDF</p>
											</div>
											<FormControl className="hidden">
												<Input
													type="file"
													multiple
													onChange={(e) => {
														handleFileChange(e);
														// field.onChange(e.target.files);
													}}
													disabled={disableFields()}
												/>
											</FormControl>
										</FormLabel>
										<FormMessage />
									</FormItem>
								)}
							/>
							{uploadedFiles.length === 0 ? null : (
								<AttachmentPreview
									files={uploadedFiles}
									deleteFile={removeFile}
									disabled={disableFields()}
								/>
							)}
							<Button
								type="submit"
								className="mt-4 w-full"
								disabled={invoiceCreate.isLoading || invoiceCreate.isSuccess}
							>
								{invoiceCreate.isLoading ? "Creating..." : "Submit Claim"}
							</Button>
						</form>
					</Form>
				) : invoiceTypes.error ? (
					<div className="text-red-500">There was an error loading claim types</div>
				) : null}
				{/* <DialogFooter>
					<Button type="submit">Save changes</Button>
				</DialogFooter> */}
			</DialogContent>
		</Dialog>
	);
};

export default CreateClaim;
