import { UserContext } from "@/context/User/UserContext";
import { useContext } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../components/ui/table";
import UpdateManager from "@/components/dialog/UpdateManager";

const Profile = () => {
	const user = useContext(UserContext);
	return (
		<div className="bg-white p-4">
			{user ? (
				<div>
					<div className="flex flex-col sm:flex-row gap-3 items-center sm:items-end">
						{user.profilePhoto ? (
							<div className="h-16 sm:h-20 rounded-full overflow-hidden">
								<img
									src={user.profilePhoto}
									alt="User Profile"
									className=" h-full w-full"
								/>
							</div>
						) : null}
						{user.department ? (
							<div className="flex flex-col">
								<h3 className="font-semibold self-center sm:self-start">
									{user.department.displayName}
								</h3>
								<h5 className="text-zinc-500 font-light">{user.department.jobTitle}</h5>
							</div>
						) : null}
					</div>
					{user.department ? (
						<div className="flex flex-wrap my-4 py-4 gap-4 border-y">
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Email Address</span>
								</div>
								<p className="text-zinc-600 font-light">
									{user.department.userPrincipalName}
								</p>
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Business Phone</span>
								</div>
								{user.department.businessPhones.map((phones, index) => (
									<p key={index} className="text-zinc-600 font-light">
										{phones}
									</p>
								))}
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Mobile Phone</span>
								</div>
								<p className="text-zinc-600 font-light">
									{user.department.mobilePhone}
								</p>
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Department</span>
								</div>
								<p className="text-zinc-600 font-light">{user.department.department}</p>
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Country</span>
								</div>
								<p className="text-zinc-600 font-light">{user.department.country}</p>
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">City</span>
								</div>
								<p className="text-zinc-600 font-light">{user.department.city}</p>
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Office</span>
								</div>
								<p className="text-zinc-600 font-light">
									{user.department.officeLocation}
								</p>
							</div>
							<div>
								<div className="text-primary flex items-center gap-1">
									<span className="text-sm">Domain</span>
								</div>
								<p className="text-zinc-600 font-light">
									{user.department.onPremisesDomainName}
								</p>
							</div>
						</div>
					) : null}
					{user.manager ? (
						<>
							<h2 className="text-sm font-semibold mb-2 uppercase">My Manager</h2>
							<div className="flex flex-wrap gap-4 border-b pb-4 mb-4">
								<div>
									<div className="text-primary flex items-center gap-1">
										<span className="text-sm">Name</span>
									</div>
									<p className="text-zinc-600 font-light">{user.manager.displayName}</p>
								</div>
								<div>
									<div className="text-primary flex items-center gap-1">
										<span className="text-sm">Email Address</span>
									</div>
									<p className="text-zinc-600 font-light">
										{user.manager.userPrincipalName}
									</p>
								</div>
								<div>
									<div className="text-primary flex items-center gap-1">
										<span className="text-sm">Business Phone</span>
									</div>
									{user.manager.businessPhones.map((phones) => (
										<p className="text-zinc-600 font-light">{phones}</p>
									))}
								</div>
							</div>
						</>
					) : null}
					<div className="pb-4">
						<UpdateManager accessToken={user.accessToken!} />
					</div>
					{user.directReports.length > 0 ? (
						<>
							<h2 className="text-sm font-semibold mb-2 uppercase">Sharing Manager</h2>
							<div className="border rounded-md">
								<Table className="">
									<TableHeader>
										<TableRow>
											<TableHead>Name</TableHead>
											<TableHead>Job Title</TableHead>
											<TableHead>Email</TableHead>
										</TableRow>
									</TableHeader>
									<TableBody>
										{user.directReports.map((colleague) => (
											<TableRow key={colleague.id}>
												<TableCell>{colleague.displayName}</TableCell>
												<TableCell>{colleague.jobTitle}</TableCell>
												<TableCell>{colleague.userPrincipalName}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</div>
						</>
					) : null}
				</div>
			) : (
				""
			)}
		</div>
	);
};

export default Profile;
