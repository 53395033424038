import React, { useReducer } from "react";
import AuthContext from "./AuthContext";
import AuthReducer from "./AuthReducer";
import axios from "axios";
import { getProfile, getPhoto, getManager } from "../../authConfig";

import {
	CLEAR_AUTH_ERRORS,
	GET_ALL_DEPARTMENTS,
	GET_ALL_DEPARTMENTS_ERROR,
	GET_ALL_USERS,
	GET_ALL_USERS_ERROR,
	GET_JOB_GRADES,
	GET_JOB_GRADES_ERROR,
	GET_LOCAL_CITIES,
	GET_LOCAL_CITIES_ERROR,
	GET_MANAGER,
	GET_MANAGER_ERROR,
	GET_PROFILE,
	GET_PROFILE_ERROR,
	GET_PROFILE_PHOTO,
	GET_PROFILE_PHOTO_ERROR,
	GET_SINGLE_USER_DETAILS,
	GET_SINGLE_USER_DETAILS_ERROR,
	GET_USER_BY_EMAIL,
	GET_USER_BY_EMAIL_ERROR,
	GET_USER_CHECK,
	GET_USER_CHECK_ERROR,
	GET_USER_DETAIL_BY_ID,
	GET_USER_DETAIL_BY_ID_ERROR,
	GET_USER_TYPES,
	GET_USER_TYPES_ERROR,
	MANAGER_DIRECT_REPORTS,
	MANAGER_DIRECT_REPORTS_ERROR,
	ME_DIRECT_REPORTS,
	ME_DIRECT_REPORTS_ERROR,
	UPDATE_USER_BY_ID,
	UPDATE_USER_BY_ID_ERROR,
	UPDATE_USER_DEPARTMENT,
	UPDATE_USER_DEPARTMENT_ERROR,
} from "../types";

export type User = {
	id: number;
	last_login: string;
	username: string;
	first_name: string;
	last_name: string;
	email: string;
	countrycode: number;
	country: string;
	department: string;
	manageremail: string;
	managername: string;
	usertype: string;
	hodname: string;
	hodemail: string;
	departmentname: string;
	phone: string;
	usertypeid: number;
	departmentid: number;
};

export type Colleague = {
	"@odata.type": string;
	id: string;
	businessPhones: string[];
	displayName: string;
	givenName: string;
	jobTitle: string;
	mail: string;
	mobilePhone: string;
	officeLocation: string;
	preferredLanguage: null | string;
	surname: string;
	userPrincipalName: string;
};

export type UserType = {
	usertypeid: number;
	usertype: string;
};

export type DepartmentType = {
	departmentid: number;
	departmentname: string;
};

const AuthState = ({ children }: { children: React.ReactNode }) => {
	const initialState = {
		// token: localStorage.getItem("token"),
		profile: null,
		profilephoto: null,
		loadtoken: null,
		usercheck: null,
		graphmanager: null,
		getdirectreports: null,
		getlocalcities: null,
		getjobgrades: null,
		getallusers: null,
		updateuserdepartment: null,
		getuserbyemail: null,
		autherror: null,
		getalldepartments: null,
		getsingleuserdetails: null,
		mydirectreports: null,
		getsingleuserdetailbyid: null,
		getusertypes: null,
		updateuserbyid: null,
	};

	//   const server_url = process.env.REACT_APP_SERVER_DOMAIN;

	const [state, dispatch] = useReducer(AuthReducer, initialState);

	const server_url = process.env.REACT_APP_SERVER_DOMAIN;

	const callMsGraph = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
		};

		const options = {
			method: "GET",
			headers: headers,
			url: getProfile.graphMeEndpoint,
		};

		try {
			const res = await axios(options);
			dispatch({
				type: GET_PROFILE,
				payload: res.data,
			});
			localStorage.setItem("mydepartment", res.data.department);
		} catch (err) {
			dispatch({
				type: GET_PROFILE_ERROR,
				payload: err,
			});
		}
	};

	const getUserCheck = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}auth/authcheck`, config);
			dispatch({
				type: GET_USER_CHECK,
				payload: res.data.user,
			});
			localStorage.setItem("claimsuser", JSON.stringify(res.data.user));
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_USER_CHECK_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	const UpdateUserdepartment = async (department: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}auth/updateuserdepartment`,
				department,
				config
			);
			dispatch({
				type: UPDATE_USER_DEPARTMENT,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: UPDATE_USER_DEPARTMENT_ERROR,
				payload: err.response.data,
			});
		}
	};

	const UpdateUserById = async (user: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}auth/updateuserbyid`,
				user,
				config
			);
			dispatch({
				type: UPDATE_USER_BY_ID,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: UPDATE_USER_BY_ID_ERROR,
				payload: err.response.data,
			});
		}
	};

	// const getUserDetailById = async (id : number) : Promise<User> => {
	const getUserDetailById = async (id: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}auth/getsingleuserdetailbyid/${id}`,
				config
			);
			dispatch({
				type: GET_USER_DETAIL_BY_ID,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_USER_DETAIL_BY_ID_ERROR,
				payload: err.response,
			});
		}
	};

	const getLocalCities = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}auth/getlocalcities`, config);
			dispatch({
				type: GET_LOCAL_CITIES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_LOCAL_CITIES_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	// const getUserTypes = async () : Promise<UserType[]> => {
	const getUserTypes = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}auth/getusertypes`, config);
			dispatch({
				type: GET_USER_TYPES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_USER_TYPES_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	const getAllUsers = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}auth/getallusers`, config);
			dispatch({
				type: GET_ALL_USERS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_ALL_USERS_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	const graphPhoto = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
		};

		const options = {
			method: "GET",
			headers: headers,
			url: getPhoto.graphMeEndpoint,
			responseType: "blob" as const,
		};

		try {
			const res = await axios(options);
			const imageUrl = URL.createObjectURL(res.data);
			dispatch({
				type: GET_PROFILE_PHOTO,
				payload: imageUrl,
			});
		} catch (err) {
			dispatch({
				type: GET_PROFILE_PHOTO_ERROR,
				payload: err,
			});
		}
	};

	const graphManager = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
		};

		const options = {
			method: "GET",
			headers: headers,
			url: getManager.graphMeEndpoint,
		};

		try {
			const res = await axios(options);
			dispatch({
				type: GET_MANAGER,
				payload: res.data,
			});

			localStorage.setItem("managerprinciple", res.data.userPrincipalName);
			localStorage.setItem("managername", res.data.displayName);
		} catch (err) {
			dispatch({
				type: GET_MANAGER_ERROR,
				payload: err,
			});
		}
	};

	// /me/directReports
	const meDirectReports = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
		};

		const options = {
			method: "GET",
			headers: headers,
			url: `https://graph.microsoft.com/v1.0/me/directReports`,
		};

		try {
			const res = await axios(options);
			dispatch({
				type: ME_DIRECT_REPORTS,
				payload: res.data.value,
			});
		} catch (err) {
			dispatch({
				type: ME_DIRECT_REPORTS_ERROR,
				payload: err,
			});
		}
	};

	// const getDirectReports = async (principleName : string) : Promise<Colleague[]> => {
	const getDirectReports = async (principleName: string) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
		};

		const options = {
			method: "GET",
			headers: headers,
			url: `https://graph.microsoft.com/v1.0/users/${principleName}/directReports`,
		};

		try {
			const res = await axios(options);
			dispatch({
				type: MANAGER_DIRECT_REPORTS,
				payload: res.data.value,
			});
		} catch (err) {
			dispatch({
				type: MANAGER_DIRECT_REPORTS_ERROR,
				payload: err,
			});
		}
	};

	const getUserByEmail = async (principleName: string) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
		};

		const options = {
			method: "GET",
			headers: headers,
			url: `https://graph.microsoft.com/v1.0/users/${principleName}`,
		};

		try {
			const res = await axios(options);
			dispatch({
				type: GET_USER_BY_EMAIL,
				payload: res.data,
			});
		} catch (err) {
			dispatch({
				type: GET_USER_BY_EMAIL_ERROR,
				payload: err,
			});
		}
	};

	const getJobGrades = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}auth/getjobgrades`, config);
			dispatch({
				type: GET_JOB_GRADES,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_JOB_GRADES_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	const clearAuthErrors = () => {
		dispatch({
			type: CLEAR_AUTH_ERRORS,
		});
	};

	const getAllDepartments = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}auth/getalldepartments`, config);
			dispatch({
				type: GET_ALL_DEPARTMENTS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_ALL_DEPARTMENTS_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	const getSingleUserDetails = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}auth/getsingleuserdetails`,
				config
			);
			dispatch({
				type: GET_SINGLE_USER_DETAILS,
				payload: res.data,
			});
			// console.log(res.data);
		} catch (err) {
			dispatch({
				type: GET_SINGLE_USER_DETAILS_ERROR,
				payload: err,
			});
			// console.log(err);
		}
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				callMsGraph,
				graphPhoto,
				getUserCheck,
				graphManager,
				getDirectReports,
				getLocalCities,
				getJobGrades,
				getAllUsers,
				UpdateUserdepartment,
				getUserByEmail,
				clearAuthErrors,
				getAllDepartments,
				getSingleUserDetails,
				meDirectReports,
				getUserDetailById,
				getUserTypes,
				UpdateUserById,
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthState;
