import React from "react";
import "./styles/App.scss";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "react-responsive-modal/styles.css";
import { QueryClient, QueryClientProvider } from "react-query";
import MainContent from "./components/MainContent";
import { Toaster } from "sonner";

const queryClient = new QueryClient();

export default function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<MainContent />
			<Toaster richColors position="top-center" />
		</QueryClientProvider>
	);
}
