/* eslint-disable import/no-anonymous-default-export */
import {
  CLEAR_AUTH_ERRORS,
  GET_ALL_DEPARTMENTS,
  GET_ALL_DEPARTMENTS_ERROR,
  GET_ALL_USERS,
  GET_ALL_USERS_ERROR,
  GET_JOB_GRADES,
  GET_JOB_GRADES_ERROR,
  GET_LOCAL_CITIES,
  GET_LOCAL_CITIES_ERROR,
  GET_MANAGER,
  GET_MANAGER_ERROR,
  GET_PROFILE,
  GET_PROFILE_ERROR,
  GET_PROFILE_PHOTO,
  GET_PROFILE_PHOTO_ERROR,
  GET_SINGLE_USER_DETAILS,
  GET_SINGLE_USER_DETAILS_ERROR,
  GET_USER_BY_EMAIL,
  GET_USER_BY_EMAIL_ERROR,
  GET_USER_CHECK,
  GET_USER_CHECK_ERROR,
  GET_USER_DETAIL_BY_ID,
  GET_USER_DETAIL_BY_ID_ERROR,
  GET_USER_TYPES,
  GET_USER_TYPES_ERROR,
  MANAGER_DIRECT_REPORTS,
  MANAGER_DIRECT_REPORTS_ERROR,
  ME_DIRECT_REPORTS,
  ME_DIRECT_REPORTS_ERROR,
  UPDATE_USER_BY_ID,
  UPDATE_USER_BY_ID_ERROR,
  UPDATE_USER_DEPARTMENT,
  UPDATE_USER_DEPARTMENT_ERROR,
} from "../types";

const AuthReducer = (state, action) => {
  switch (action.type) {
    case GET_USER_TYPES:
      return {
        ...state,
        loading: false,
        getusertypes: action.payload,
      };

    case GET_USER_DETAIL_BY_ID:
      return {
        ...state,
        loading: false,
        getsingleuserdetailbyid: action.payload,
      };
    case GET_PROFILE:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case GET_MANAGER:
      return {
        ...state,
        loading: false,
        graphmanager: action.payload,
      };

    case ME_DIRECT_REPORTS:
      return {
        ...state,
        loading: false,
        mydirectreports: action.payload,
      };
    case GET_USER_CHECK:
      return {
        ...state,
        loading: false,
        usercheck: action.payload,
      };
    case GET_PROFILE_PHOTO:
      return {
        ...state,
        
        loading: false,
        profilephoto: action.payload,
      };
    case MANAGER_DIRECT_REPORTS:
      return {
        ...state,
        loading: false,
        getdirectreports: action.payload,
      };

    case GET_LOCAL_CITIES:
      return {
        ...state,
        loading: false,
        getlocalcities: action.payload,
      };

    case GET_JOB_GRADES:
      return {
        ...state,
        loading: false,
        getjobgrades: action.payload,
      };

    case GET_ALL_USERS:
      return {
        ...state,
        loading: false,
        getallusers: action.payload,
      };

    case GET_USER_BY_EMAIL:
      return {
        ...state,
        loading: false,
        getuserbyemail: action.payload,
      };

    case CLEAR_AUTH_ERRORS:
      return {
        ...state,
        getuserbyemail: null,
        autherror: null,
        updateuserdepartment: null,
        updateuserbyid:null
      };

    case GET_ALL_DEPARTMENTS:
      return {
        ...state,
        loading: false,
        getalldepartments: action.payload,
      };

    case UPDATE_USER_DEPARTMENT:
      return {
        ...state,
        loading: false,
        updateuserdepartment: action.payload,
      };

    case GET_SINGLE_USER_DETAILS:
      return {
        ...state,
        loading: false,
        getsingleuserdetails: action.payload,
      };

    case UPDATE_USER_BY_ID:
      return {
        ...state,
        loading: false,
        updateuserbyid: action.payload,
      };

    case GET_USER_CHECK_ERROR:
    case GET_LOCAL_CITIES_ERROR:
    case GET_JOB_GRADES_ERROR:
    case GET_ALL_USERS_ERROR:
    case GET_ALL_DEPARTMENTS_ERROR:
    case GET_SINGLE_USER_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        geterror: action.payload,
      };

    case GET_PROFILE_ERROR:
    case GET_PROFILE_PHOTO_ERROR:
    case GET_MANAGER_ERROR:
    case MANAGER_DIRECT_REPORTS_ERROR:
    case GET_USER_BY_EMAIL_ERROR:
    case UPDATE_USER_DEPARTMENT_ERROR:
    case ME_DIRECT_REPORTS_ERROR:
    case GET_USER_DETAIL_BY_ID_ERROR:
    case GET_USER_TYPES_ERROR:
    case UPDATE_USER_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        autherror: action.payload,
      };

    default:
      return state;
  }
};

export default AuthReducer;
