// import { countryData } from "./Countries";
// import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import Countries from "./data/countries.json";
import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";

export const S3_URL = "https://nmgcourier.s3.eu-west-1.amazonaws.com/";

export const currencies = require("currency-codes");

// Stale time for queries set to 9 hours
export const STALE_TIME = 32400000;
export const STALE_TIME_INVOICE = 600000;

export const groupBy = (array: any[], key: number) => {
	// Return the end result
	return array.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		(result[currentValue[key]] = result[currentValue[key]] || []).push(
			currentValue
		);
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result;
	}, {}); // empty object is the initial value for result object
};

export const getFileExtension = (filename: any) => {
	// get file extension
	const extension = filename.split(".").pop();
	return extension;
};

export const imageExtensions = [
	"apng",
	"gif",
	"ico",
	"cur",
	"jpg",
	"jpeg",
	"jfif",
	"pjpeg",
	"pjp",
	"png",
	"svg",
];

export const acceptedFiles = [
	"image/apng",
	"image/gif",
	"image/ico",
	"image/cur",
	"image/jpg",
	"image/jpeg",
	"image/jfif",
	"image/pjpeg",
	"image/pjp",
	"image/png",
	"image/svg",
	"application/pdf",
];

export const invoiceFileExtensions = [
	"xlsx",
	"xls",
	"xlsm",
	"xlsb",
	"xlsm",
	"xltx",
	"xltm",
	"xlt",
	"xlr",
	"xlw",
	"xla",
	"xlam",
	"xml",
];

export const fileExtensions = ["pdf"];

export const docsviewer = ["docx", "doc"];

export const office365url =
	"https://view.officeapps.live.com/op/embed.aspx?src=";

export const DateConverter = (datetobeconverted: any) => {
	let options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	let stringdate = new Date(datetobeconverted);
	let formattedDate = stringdate.toLocaleDateString(undefined, options);

	return formattedDate;
};

export const TimeConverter = (inputTime: string) => {
	// Split the time string into its components
	const [hours, minutes, secondscombined] = inputTime.split(":");
	const milliseconds = parseFloat(secondscombined.split(".")[1]);
	const seconds = secondscombined.split(".")[0];
	// Determine whether it's AM or PM
	const amOrPm = parseInt(hours) >= 12 ? "PM" : "AM";
	// Convert hours to 12-hour format
	const formattedHours = parseInt(hours) % 12 || 12;
	// Format the time in a human-friendly way
	const formattedTime = `${formattedHours}:${minutes}:${seconds}.${milliseconds} ${amOrPm}`;

	return formattedTime;
};

export const getCountryData = (countryName: any) => {
	const country = Countries.find(
		(data) => data.country.toLowerCase() === countryName.toLowerCase()
	);
	return country ? country : null;
};

export const FormatTimeDate = (inputDateTimeString: string) => {
	const inputDate = new Date(inputDateTimeString);

	// Define options for formatting
	const options: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		second: "2-digit",
	};

	// Format the date and time in East African Time (EAT)
	const formattedDateTime = inputDate.toLocaleDateString("en-US", options);

	// Get the time zone abbreviation for East African Time (EAT)
	const timeZoneAbbreviation = "EAT";

	// Combine the formatted date and time with the time zone abbreviation
	const formattedDateTimeWithTimezone = `${formattedDateTime}, ${inputDate.toLocaleTimeString()} ${timeZoneAbbreviation}`;

	// console.log(formattedDateTimeWithTimezone);
	return formattedDateTimeWithTimezone;
};

const date = new Date();

// ✅ Reset a Date's time to midnight
date.setHours(0, 0, 0, 0);

// ✅ Format a date to YYYY-MM-DD (or any other format)
const padTo2Digits = (num: number) => {
	return num.toString().padStart(2, "0");
};

export const ISODateFormat = (date: any) => {
	return [
		date.getFullYear(),
		padTo2Digits(date.getMonth() + 1),
		padTo2Digits(date.getDate()),
	].join("-");
};

export const convertToTimeFormat = (timeString: string): string => {
	// Extract the time portion HH:MM:SS
	const timePortion = timeString.split(".")[0];

	// Convert to Date object to extract HH, MM, and SS
	const date = new Date(`1970-01-01T${timePortion}Z`);

	// Format hours, minutes, and seconds
	let hours = date.getUTCHours();
	const minutes = String(date.getUTCMinutes()).padStart(2, "0");
	const seconds = String(date.getUTCSeconds()).padStart(2, "0");

	// Determine AM or PM
	const ampm = hours >= 12 ? "PM" : "AM";

	// Convert to 12-hour format
	hours = hours % 12;
	hours = hours ? hours : 12; // The hour '0' should be '12'
	const formattedHours = String(hours).padStart(2, "0");

	// Return formatted time HH:MM:SS AM/PM
	return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
};

export const formatAmount = (amount: number, currency: string) => {
	return Intl.NumberFormat("en-US", {
		style: "currency",
		currency: currency,
		minimumFractionDigits: 2,
	}).format(amount);
};

export const getUploadedFile = async (url: string) => {
	try {
		const response = await fetch(url);
		if (!response.ok) {
			throw new Error(`Error fetching file: ${response.statusText}`);
		}
		const segments = url.split("/");
		const fileName = segments[segments.length - 1];

		const blob = await response.blob();
		const file = new File([blob], fileName, { type: blob.type });

		const dataTransfer = new DataTransfer();
		dataTransfer.items.add(file);
		return dataTransfer.files[0];
	} catch (error) {
		console.error("Error uploading file:", error);
		return null;
	}
};

const bucket = "nmgcourier"


// Initialize the S3 client
const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION as string,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
  },
});

/**
 * Fetch a pre-signed URL for a file in S3.
 *
 * @param {string} key - The key of the file in the S3 bucket.
 * @returns {Promise<string>} - The pre-signed URL of the file.
 */
export const getS3FileUrl = async (key: string): Promise<string> => {
	try {
	  const command = new GetObjectCommand({
		Bucket: bucket,
		Key: key,
	  });
  
	  // Generate a signed URL that is valid for 60 minutes
	  const signedUrl = await getSignedUrl(s3Client, command, { expiresIn: 3600 });
  
	  console.log("Generated Signed URL: ", signedUrl);
  
	  return signedUrl;
	} catch (error) {
	  console.error("Error generating signed URL:", error);
	  throw error;
	}
  };