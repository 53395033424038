import EditUser from "@/components/dialog/EditUser";
import { Skeleton } from "@/components/ui/skeleton";
import { STALE_TIME } from "@/constants";
import { UserContext } from "@/context/User/UserContext";
import { getUserDetailById } from "@/services/auth-functions";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";

const SystemUser = () => {
	const { id } = useParams<{ id: string }>() as { id: string };
	const user = useContext(UserContext);

	const userDetail = useQuery({
		queryKey: ["user-detail", id],
		queryFn: () => getUserDetailById(user?.accessToken!, id),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	return (
		<div>
			{userDetail.isLoading ? (
				<div>
					<Skeleton className="h-14 w-1/2" />
					<div className="border-y py-4 my-4">
						<Skeleton className="h-14 w-full" />
					</div>
					<div>
						<Skeleton className="h-20 w-full" />
					</div>
				</div>
			) : userDetail.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error loading invoice.</h5>
				</div>
			) : userDetail.data ? (
				<>
					<div className="flex flex-col md:flex-row gap-1 items-center justify-between">
						<div>
							<h1 className="text-4xl font-bold">{userDetail.data.username}</h1>
							<div className="flex flex-col">
								<h3 className="text-zinc-500">{userDetail.data.department}</h3>
							</div>
						</div>
						<EditUser user={userDetail.data} accessToken={user?.accessToken!} />
					</div>
					<div className="flex flex-wrap my-4 py-4 gap-4 border-y">
						<div>
							<div className="text-primary flex items-center gap-1">
								<span className="text-sm">Email Address</span>
							</div>
							<p className="text-zinc-600 font-light">{userDetail.data.email}</p>
						</div>
						<div>
							<div className="text-primary flex items-center gap-1">
								<span className="text-sm">Mobile Phone</span>
							</div>
							<p className="text-zinc-600 font-light">
								{userDetail.data.phone ?? "Not Set"}
							</p>
						</div>
						<div>
							<div className="text-primary flex items-center gap-1">
								<span className="text-sm">Department</span>
							</div>
							<p className="text-zinc-600 font-light">
								{userDetail.data.department ?? "Not Set"}
							</p>
						</div>
						<div>
							<div className="text-primary flex items-center gap-1">
								<span className="text-sm">Country</span>
							</div>
							<p className="text-zinc-600 font-light">{userDetail.data.country}</p>
						</div>
					</div>
					{userDetail.data.managername && userDetail.data.manageremail ? (
						<>
							<h2 className="text-sm font-semibold mb-2 uppercase">My Manager</h2>
							<div className="flex flex-wrap gap-4 border-b pb-4 mb-4">
								<div>
									<div className="text-primary flex items-center gap-1">
										<span className="text-sm">Name</span>
									</div>
									<p className="text-zinc-600 font-light">
										{userDetail.data.managername}
									</p>
								</div>
								<div>
									<div className="text-primary flex items-center gap-1">
										<span className="text-sm">Email Address</span>
									</div>
									<p className="text-zinc-600 font-light">
										{userDetail.data.manageremail}
									</p>
								</div>
							</div>
						</>
					) : null}
				</>
			) : null}
		</div>
	);
};

export default SystemUser;
