import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import ManagerMainApporvals from "./ManagerMainApprovals";
import ManagerSecondaryApprovals from "./ManagerSecondaryApprovals";
import { useContext, useEffect, useState } from "react";
import { STALE_TIME_INVOICE } from "@/constants";
import {
	ManagerListInvoices,
	ManagerSecondaryListInvoices,
} from "@/services/invoice-functions";
import { useQuery } from "react-query";
import { UserContext } from "@/context/User/UserContext";

const ManagerApporvals = () => {
	const user = useContext(UserContext);
	const [totalMainApprovals, setTotalMainApprovals] = useState(0);
	const [totalSecondaryApprovals, setTotalSecondaryApprovals] = useState(0);
	const managerInvoices = useQuery({
		queryKey: ["invoice", "manager"],
		queryFn: () => ManagerListInvoices(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
		onSuccess: (data) => {
			setTotalMainApprovals(data.length);
		},
	});
	const managerSecondaryInvoices = useQuery({
		queryKey: ["invoice", "manager", "secondary"],
		queryFn: () => ManagerSecondaryListInvoices(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
		onSuccess: (data) => {
			setTotalSecondaryApprovals(data.length);
		},
	});

	useEffect(() => {
		if (managerInvoices.isSuccess) {
			setTotalMainApprovals(managerInvoices.data.length);
		}
		if (managerSecondaryInvoices.isSuccess) {
			setTotalSecondaryApprovals(managerSecondaryInvoices.data.length);
		}
	}, []);

	return (
		<>
			<h1 className="text-4xl font-bold mb-3">Departmental Approvals</h1>
			<h3 className="text-zinc-600 font-light mb-4">
				Here are a list of claims that wait your approval
			</h3>
			<Tabs defaultValue="main">
				<TabsList className="p-0 bg-white border-b rounded-none w-full justify-start h-auto">
					<TabsTrigger
						value="main"
						className="data-[state=active]:border-b data-[state=active]:text-foreground data-[state=active]:border-primary"
					>
						Approvals
						{totalMainApprovals > 0 ? (
							<small className="text-orange-600 bg-orange-100 py-0.5 px-2 rounded-xl self-center ml-2">
								{totalMainApprovals}
							</small>
						) : null}
					</TabsTrigger>
					<TabsTrigger
						value="secondary"
						className="data-[state=active]:border-b data-[state=active]:text-foreground data-[state=active]:border-primary"
					>
						Secondary Approvals
						{totalSecondaryApprovals > 0 ? (
							<small className="text-orange-600 bg-orange-100 py-0.5 px-2 rounded-xl self-center ml-2">
								{totalSecondaryApprovals}
							</small>
						) : null}
					</TabsTrigger>
				</TabsList>
				<TabsContent value="main">
					<ManagerMainApporvals />
				</TabsContent>
				<TabsContent value="secondary">
					<ManagerSecondaryApprovals />
				</TabsContent>
			</Tabs>
		</>
	);
};

export default ManagerApporvals;
