import ReactDOM from "react-dom/client";
import App from "./App";
import Modal from "react-modal";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import AuthProvider from "./auth/AuthProvider";

Modal.setAppElement("#root");

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<AuthProvider>
		<App />
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
