import { useContext } from "react";
import { UserContext } from "@/context/User/UserContext";
import { getSingleMasterInvoice } from "@/services/invoice-functions";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import ManagerApproveOrReject from "@/components/dialog/ManagerApproveOrReject";
import InvoiceDetails from "@/components/InvoiceDetails";
import InvoiceSkeleton from "@/components/skeleton/InvoiceSkeleton";
import { STALE_TIME_INVOICE } from "@/constants";

const ManagerApproval = () => {
	const { id } = useParams<{ id: string }>() as { id: string };
	const user = useContext(UserContext);

	const invoice = useQuery({
		queryKey: ["invoice", id],
		queryFn: () => getSingleMasterInvoice(user?.accessToken!, id),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
	});

	return (
		<div>
			{invoice.isLoading ? (
				<InvoiceSkeleton />
			) : invoice.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error loading claim.</h5>
				</div>
			) : (
				invoice.data && (
					<>
						<div className="flex flex-col sm:flex-row gap-3 sm:items-end justify-between">
							<h1 className="font-medium text-4xl">
								CLAIM NO: {invoice.data.invoicemasterid}
							</h1>
							{/* <div>
								<Button>Approve</Button>
								<Button variant="secondary" className="ml-3">
									Reject
								</Button>
							</div> */}
							{invoice.data.managerapproved === null &&
							invoice.data.managerrejected === null ? (
								<ManagerApproveOrReject
									invoicemasterid={invoice.data.invoicemasterid}
									accessToken={user?.accessToken!}
								/>
							) : (
								<div className="bg-primary/20 text-primary px-4 py-2 rounded-md">
									Claim Processed
								</div>
							)}
						</div>
						<InvoiceDetails invoice={invoice.data} accessToken={user?.accessToken!} />
					</>
				)
			)}
		</div>
	);
};

export default ManagerApproval;
