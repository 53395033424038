import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/context/User/UserContext";
// import InvoiceFileUpload from "@/components/dialog/InvoiceFileUpload";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import FinanceMainApprovals from "./FinanceMainApprovals";
import FinanceSecondaryApprovals from "./FinanceSecondaryApprovals";
import { useQuery } from "react-query";
import {
	FinanceListInvoices,
	FinanceSecondaryListInvoices,
} from "@/services/invoice-functions";
import { STALE_TIME_INVOICE } from "@/constants";

const FinanceApprovals = () => {
	const user = useContext(UserContext);
	const [totalApprovals, setTotalApprovals] = useState(0);
	const [totalSecondaryApprovals, setTotalSecondaryApprovals] = useState(0);

	const financeinvoices = useQuery({
		queryKey: ["invoice", "finance"],
		queryFn: () => FinanceListInvoices(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
		onSuccess: (data) => {
			setTotalApprovals(data.length);
		},
	});

	const invoices = useQuery({
		queryKey: ["invoice", "finance", "secondary"],
		queryFn: () => FinanceSecondaryListInvoices(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
		onSuccess: (data) => {
			setTotalSecondaryApprovals(data.length);
		},
	});

	useEffect(() => {
		if (financeinvoices.isSuccess) {
			setTotalApprovals(financeinvoices.data.length);
		}
		if (invoices.isSuccess) {
			setTotalSecondaryApprovals(invoices.data.length);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div className="flex flex-col sm:flex-row justify-between pb-4 sm:pb-0">
				<div>
					<h1 className="text-4xl font-bold mb-2">Finance Approvals</h1>
					<h3 className="text-zinc-600 font-light mb-4">
						Here are a list of claims that wait your approval
					</h3>
				</div>
				{/* <InvoiceFileUpload accessToken={user?.accessToken!} /> */}
			</div>
			<Tabs defaultValue="main">
				<TabsList className="p-0 bg-white border-b rounded-none w-full justify-start h-auto">
					<TabsTrigger
						value="main"
						className="data-[state=active]:border-b data-[state=active]:text-foreground data-[state=active]:border-primary"
					>
						Approvals
						{totalApprovals > 0 ? (
							<small className="text-orange-600 bg-orange-100 py-0.5 px-2 rounded-xl self-center ml-2">
								{totalApprovals}
							</small>
						) : null}
					</TabsTrigger>
					<TabsTrigger
						value="secondary"
						className="data-[state=active]:border-b data-[state=active]:text-foreground data-[state=active]:border-primary"
					>
						Secondary Approvals
						{totalSecondaryApprovals > 0 ? (
							<small className="text-orange-600 bg-orange-100 py-0.5 px-2 rounded-xl self-center ml-2">
								{totalSecondaryApprovals}
							</small>
						) : null}
					</TabsTrigger>
				</TabsList>
				<TabsContent value="main">
					<FinanceMainApprovals />
				</TabsContent>
				<TabsContent value="secondary">
					<FinanceSecondaryApprovals />
				</TabsContent>
			</Tabs>
		</>
	);
};

export default FinanceApprovals;
