import Logo from "../../media/nation.png";
import { NavLink } from "react-router-dom";
import { HiUserCircle } from "react-icons/hi";
import { PiSignOutBold } from "react-icons/pi";
import { useMsal } from "@azure/msal-react";
import Menu from "./Menu";

const Sidebar = () => {
	const { instance, accounts } = useMsal();

	const currentAccount = instance.getAccountByHomeId(accounts[0]?.homeAccountId);

	const redirecturi = process.env.REACT_APP_REDIRECT_URI;

	const handleLogoutRedirect = () => {
		instance.logoutRedirect({
			postLogoutRedirectUri: redirecturi,
			account: currentAccount,
		});
		window.location.reload();
	};

	return (
		<div className="w-[300px] hidden h-full lg:flex flex-col bg-zinc-100 fixed border-r border-zinc-200">
			<div className="flex items-center gap-2 px-4 py-2 border-b border-zinc-300">
				<div className="h-8">
					<img src={Logo} alt="logo" className="h-full w-full" />
				</div>
				<h3 className="text-xl font-bold">Nation</h3>
			</div>
			<div className="flex flex-col gap-4 px-4 py-2 justify-between grow overflow-y-auto">
				<Menu />
				<ul className="flex flex-col gap-1">
					<li>
						<NavLink to="/profile" className="sidebar-link">
							<HiUserCircle className="text-2xl" />
							<span>Profile</span>
						</NavLink>
					</li>
					<li>
						<button className="sidebar-link" onClick={handleLogoutRedirect}>
							<PiSignOutBold className="text-2xl" />
							<span>Logout</span>
						</button>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default Sidebar;
