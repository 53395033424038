import React, { useState } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	CashCollectionParams,
	CashOfficeCollection,
	getPaymentMethods,
} from "@/services/invoice-functions";
import { toast } from "sonner";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { Input } from "../ui/input";
import { Label } from "../ui/label";
import { STALE_TIME } from "@/constants";

const formSchema = z.object({
	collectedby: z.string().min(1),
	paymentmethod: z.number(),
});

const CollectFunds: React.FC<{
	invoicemasterid: number;
	accessToken: string;
	amountcollected: string;
	phone: string;
}> = ({ invoicemasterid, accessToken, amountcollected, phone }) => {
	const [open, setOpen] = useState(false);

	const queryClient = useQueryClient();

	const paymentMethods = useQuery({
		queryKey: ["paymentMethods"],
		queryFn: () => getPaymentMethods(accessToken),
		staleTime: STALE_TIME,
	});

	const makeCollection = useMutation({
		mutationKey: ["cashcollection", invoicemasterid],
		mutationFn: (collection: CashCollectionParams) =>
			CashOfficeCollection(accessToken, collection),
		onSuccess: () => {
			queryClient.invalidateQueries(["invoice"]);
			toast.success("Cash Collection submitted successfully");
			setOpen(false);
			form.reset();
			makeCollection.reset();
		},
		onError: () => {
			toast.error("Error submitting cash collection");
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		makeCollection.mutate({
			amountcollected: amountcollected,
			collectedby: data.collectedby,
			paymentmethod: data.paymentmethod,
			invoicemasterid: invoicemasterid.toString(),
		});
	};

	const disabled = () => {
		if (makeCollection.isLoading) return true;
		if (makeCollection.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<div className="flex gap-2">
				<DialogTrigger asChild>
					<Button>Make Collection</Button>
				</DialogTrigger>
			</div>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					<DialogTitle className="flex gap-2 items-center mb-1">
						Cash Collection
					</DialogTitle>
					<DialogDescription>Fill in the details below.</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex items-center space-x-2 mb-4">
							<div className="grid flex-1 gap-2">
								<Label>Amount</Label>
								<Input type="text" readOnly value={amountcollected} />
								{paymentMethods.isLoading ? (
									<span>Loading Cost Centres</span>
								) : paymentMethods.data ? (
									<FormField
										name="paymentmethod"
										render={({ field }) => (
											<FormItem className="mb-2">
												<FormLabel>Payment Method</FormLabel>
												<FormControl>
													<Select
														onValueChange={(e) => {
															field.onChange(parseFloat(e));
														}}
													>
														<SelectTrigger>
															<SelectValue placeholder="Select an option" />
														</SelectTrigger>
														<SelectContent>
															{paymentMethods.data.map((item) => (
																<SelectItem value={item.paymentmethodid.toString()}>
																	{item.paymentmethoddes}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								) : null}
								<FormField
									name="collectedby"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Collected By</FormLabel>
											<FormControl>
												<Input {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<DialogFooter className="sm:justify-end">
							<DialogClose asChild>
								<Button type="button" variant="ghost">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit" disabled={disabled()}>
								{disabled() ? "Submitting..." : "Submit"}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default CollectFunds;
