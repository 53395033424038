/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useContext, useEffect } from "react";
import InvoiceContext from "../../context/Invoice/InvoiceContext";
import AlertContext from "../../context/alert/AlertContext";
import AuthContext from "../../context/Auth/AuthContext";
import { useDropzone } from "react-dropzone";
import Select from "react-select";
import Modal from "react-modal";
import { Modal as ResponsiveModal } from "react-responsive-modal";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
// import { countryData } from "../../Countries";
import Countries from "../../data/countries.json";
import { Typeahead } from "react-bootstrap-typeahead";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import {
	getFileExtension,
	imageExtensions,
	fileExtensions,
	acceptedFiles,
	getCountryData,
} from "../../constants";
import UpdateUserApproverDetails from "../common/UpdateUserApproverDetails";

const TablePerDiem = () => {
	const authcontext = useContext(AuthContext);
	const invoiceContext = useContext(InvoiceContext);
	const alertContext = useContext(AlertContext);

	const {
		createperdiem,
		CreatePerDiem,
		clearInvoiceErrors,
		createlocalperdiem,
		CreateLocalPerDiem,
		getperdiemtypes,
		getPerdiemTypes,
		invoiceerror,
	} = invoiceContext;

	// const { setAlert } = alertContext;
	const {
		getlocalcities,
		getLocalCities,
		getJobGrades,
		getjobgrades,
		getsingleuserdetails,
		getSingleUserDetails,
	} = authcontext;

	const [perdiemState, setPerDiemState] = useState(null);
	const [jobgradeState, setJobgradeState] = useState<any[]>([]);
	const [selectedCountry, setSelectedCountry] = useState<{
		value: string;
		label: string;
	} | null>(null);
	const [description, setDescription] = useState("");
	const [totalAmount, setTotalAmount] = useState("");
	const [files, setFiles] = useState<any[]>([]);
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalIsOpenUpdateT, setModalIsOpenUpdateT] = useState(false);

	// const MAX_FILE_SIZE = 2097152;

	useEffect(() => {
		getLocalCities();
		getJobGrades();
		getPerdiemTypes();
		getSingleUserDetails();
		if (createperdiem) {
			if (alertContext) alertContext.setAlert(createperdiem.message, "success");
			setVisible(false);
			setJobgradeState([]);
			setFiles([]);
			setSelectedCountry(null);
			setPerDiemState(null);
			setTotalAmount("");
			setDescription("");
			setRows([first_item]);
			clearInvoiceErrors();
		}

		if (createlocalperdiem) {
			if (alertContext)
				alertContext.setAlert(createlocalperdiem.message, "success");
			setVisible(false);
			setJobgradeState([]);
			setFiles([]);
			setSelectedCountry(null);
			setPerDiemState(null);
			setTotalAmount("");
			setDescription("");
			setRows([first_item]);
			clearInvoiceErrors();
		}

		if (invoiceerror) {
			setVisible(false);
			if (alertContext) alertContext.setAlert(invoiceerror.message, "danger");
			clearInvoiceErrors();
		}
		// eslint-disable-next-line
	}, [createperdiem, createlocalperdiem, invoiceerror]);

	// Handle file drop
	const handleFileChange = (acceptedFiles: any, rejectedFiles: any) => {
		if (rejectedFiles.length > 0) {
			// Handle rejected files (size or type restrictions)
			rejectedFiles.forEach((file: any) => {
				if (!alertContext) return;
				alertContext.setAlert(file.errors[0].message, "danger");
			});
		} else {
			// Handle acceptedFiles (upload, store, etc.)
			setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		}
	};

	const handleFileDrop = (index: number) => {
		setFiles((prevTableData) => {
			const updatedTableData = [...prevTableData];
			updatedTableData.splice(index, 1);
			return updatedTableData;
		});
	};

	// React Dropzone configuration
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleFileChange,
		accept: acceptedFiles.join(",") as any,
		multiple: true,
		// maxSize: MAX_FILE_SIZE,
	});

	//   handle row addition

	const first_item = {
		id: 1,
		city: "",
		typeofpayment: "",
		numberofnights: "",
		amount: "",
		fullamount: "",
	};
	const [rows, setRows] = useState<any[]>([first_item]);
	const [visible, setVisible] = useState(false);
	const [daysnow, setDaysNow] = useState<any>({});
	const [amountnow, setAmountNow] = useState<any>({});
	const [fullamountnow, setFullAmountNow] = useState<any>({});

	const handleChange = (item: any) => (e: any, val: any) => {
		let name = "";
		let value = "";

		if (e.target === undefined) {
			if (val.name === "typeofpayment") {
				name = "typeofpayment";
				value = e.value;
			} else if (val.name === "city") {
				name = "city";
				value = e.value;
			}
		} else {
			name = e.target.name;
			value = e.target.value;
		}

		let items = rows.map((row: any) => {
			if (row.id === item.id) {
				row[name] = value;
			}

			const currentDaysNow = parseFloat(row["numberofnights"]);
			const currentAmountNow = parseFloat(row["amount"]);
			const currentFullAmountNow = parseFloat(row["fullamount"]);

			const daysChanged = currentDaysNow !== parseFloat(daysnow[row.id]);
			const amountChanged = currentAmountNow !== parseFloat(amountnow[row.id]);
			const fullamountChanged =
				currentFullAmountNow !== parseFloat(fullamountnow[row.id]);
			// row["amount"] = row["price"];

			if (daysChanged || amountChanged || fullamountChanged) {
				row["fullamount"] = currentAmountNow * currentDaysNow;

				setDaysNow((prevState: any) => ({
					...prevState,
					[row.id]: currentDaysNow,
				}));

				setAmountNow((prevState: any) => ({
					...prevState,
					[row.id]: currentAmountNow,
				}));

				setFullAmountNow((prevState: any) => ({
					...prevState,
					[row.id]: currentFullAmountNow,
				}));
			}
			return row;
		});

		setRows(items);
		let amount_list: any = [];
		for (let oneRow of rows) {
			// eslint-disable-next-line array-callback-return
			Object.entries(oneRow).map(([key, value]) => {
				if (key === "fullamount") {
					amount_list.push(parseFloat(value as string));
				}
			});
		}
		let sum = amount_list.reduce(function (a: number, b: number) {
			return a + b;
		}, 0);
		setTotalAmount(sum.toString());
		// window.$(".total-amount").val(sum.toString());
	};
	const handleAddRow = () => {
		setRows((prevRows) => {
			const item = {
				id: prevRows.length + 1,
				typeofpayment: "",
				numberofnights: "",
				amount: "",
				fullamount: "",
			};
			return [...prevRows, item];
		});
	};
	const handleRemoveSpecificRow = (item: any) => () => {
		let items = rows.filter((row) => row.id !== item.id);
		let amount_list: any[] = [];
		for (let oneRow of items) {
			// eslint-disable-next-line array-callback-return
			Object.entries(oneRow).map(([key, value]) => {
				if (key === "fullamount") {
					amount_list.push(parseFloat(value as string));
				}
			});
		}
		let sum = amount_list.reduce(function (a, b) {
			return a + b;
		}, 0);
		setTotalAmount(sum.toString());
		setRows(items);
	};

	// end of handling row addition
	let loadedcities = [];
	if (getlocalcities) {
		loadedcities = getlocalcities;
	} else {
		loadedcities = [];
	}

	let mappedcities: any[] = [];

	for (let value of loadedcities) {
		// eslint-disable-next-line array-callback-return
		let singleObject = {
			label: value["city"],
			value: value["cityid"],
		};
		mappedcities.push(singleObject);
	}

	let loadedjobgrades = [];
	if (getjobgrades) {
		loadedjobgrades = getjobgrades;
	} else {
		loadedjobgrades = [];
	}

	let loadedperdiemtypes = [];
	if (getperdiemtypes) {
		loadedperdiemtypes = getperdiemtypes;
	} else {
		loadedperdiemtypes = [];
	}

	let mappedperdiemtypes: any[] = [];

	for (let value of loadedperdiemtypes) {
		// eslint-disable-next-line array-callback-return
		let singleObject = {
			label: value["perdiemtype"],
			value: value["perdiemtypeid"],
		};
		mappedperdiemtypes.push(singleObject);
	}

	const handleOptionChange = (e: any) => {
		setPerDiemState(e.target.value);
	};

	const handleSelectChange = (selectedOption: {
		value: string;
		label: string;
	}) => {
		setSelectedCountry(selectedOption);
	};

	const countryOptions = Countries.map((data) => ({
		value: data.country,
		label: data.country,
	}));

	// Handle form submit
	const typeofpayments: any[] = [];
	const numberofnights: any[] = [];
	const amounts: any[] = [];
	const cities: any[] = [];

	const onSubmit = (e: any) => {
		e.preventDefault();

		for (let value of rows) {
			// eslint-disable-next-line array-callback-return
			Object.entries(value).map(([key, value]) => {
				if (key === "typeofpayment") {
					typeofpayments.push(parseInt(value as any));
				}
				if (key === "numberofnights") {
					numberofnights.push(parseInt(value as any));
				}
				if (key === "fullamount") {
					amounts.push(parseFloat(value as any));
				}
				if (key === "city") {
					cities.push(parseFloat(value as any));
				}
			});
		}

		if (perdiemState === "international") {
			if (selectedCountry === null) return;
			let submittedcountry = getCountryData(selectedCountry.value)!.country;
			let submittedcontinent = getCountryData(selectedCountry.value)!.continent;
			let jobgradeid = jobgradeState[0].jobgradeid;
			let totalamount = totalAmount;

			CreatePerDiem({
				files,
				submittedcontinent,
				jobgradeid,
				submittedcountry,
				totalamount,
				typeofpayments,
				amounts,
				numberofnights,
				description,
			});
		}

		if (perdiemState === "local") {
			let jobgradeid = jobgradeState[0].jobgradeid;
			let totalamount = totalAmount;

			CreateLocalPerDiem({
				cities,
				files,
				jobgradeid,
				totalamount,
				typeofpayments,
				amounts,
				numberofnights,
				description,
			});
		}
		setVisible(true);
	};

	const openPdfModal = () => {
		setModalIsOpen(true);
	};

	const closePdfModal = () => {
		setModalIsOpen(false);
	};

	const perdiempdf = "media/perdiem.pdf";

	const modalIsOpenUpdateDetailsT = () => {
		setModalIsOpenUpdateT(true);
	};

	const closeModalUpdateDetailsT = () => {
		setModalIsOpenUpdateT(false);
	};
	return (
		<div>
			{getsingleuserdetails ? (
				<div className="courierBorder">
					<Alert />
					<div className="row m-3">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							{visible ? (
								<div className="align-centre">
									<h5 className="text-center">Creating Invoice(s)...</h5>
									<Spinner />
								</div>
							) : (
								<div></div>
							)}
						</div>
						<div className="col-lg-2"></div>
						<div className="col-lg-2">
							<button
								className="btn btn-primary col-12 mx-auto"
								onClick={openPdfModal}
							>
								Current Per Diem Rates Guide
							</button>

							{/* modal */}
							<Modal
								isOpen={modalIsOpen}
								onRequestClose={closePdfModal}
								style={{
									overlay: {
										backgroundColor: "rgba(0, 0, 0, 0.5)",
										zIndex: 1000,
									},
									content: {
										width: "50%",
										height: "80%",
										margin: "auto",
									},
								}}
							>
								<div className="iframe-container">
									<iframe
										src={perdiempdf}
										title="Perdiem PDF"
										className="responsive-iframe"
									/>
								</div>
							</Modal>
							{/* end modal */}
						</div>
					</div>
					{getsingleuserdetails.manageremail === null ||
					getsingleuserdetails.managername === null ||
					getsingleuserdetails.departmentname === null ||
					getsingleuserdetails.phone === null ? (
						<div className="row p-5">
							<div className="col-lg-3"></div>
							<div className="col-lg-6">
								<UpdateUserApproverDetails />
							</div>
							<div className="col-lg-3"></div>
						</div>
					) : (
						<div className="p-1">
							<h2 className="text-primary text-center">
								{" "}
								<u> Raise Per Diem </u>{" "}
							</h2>
							<hr />
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-6">
									<h4 className="text-primary text-center">Current Approver Details</h4>
									<table className="table table-striped table-bordered table-responsive">
										<thead></thead>
										<tbody>
											<tr>
												<th className="table-primary text-primary text-lg-start">
													Approver
												</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.managername}
												</td>
											</tr>
											<tr>
												<th className="table-primary text-primary text-lg-start">
													Approver Email
												</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.manageremail}
												</td>
											</tr>
											<tr>
												<th className="table-primary text-primary text-lg-start">
													Your department
												</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.departmentname}
												</td>
											</tr>
											<tr>
												<th className="table-primary text-primary text-lg-start">Phone</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.phone}
												</td>
											</tr>
										</tbody>
									</table>
									<div className="p-2 bg-gradient bg-info border border-info">
										<p>
											If the above details are correct, proceed and raise a claim, else,
											click the button below to update them.
										</p>
										<div className="row">
											<div className="col-lg-3"></div>
											<div className="col-lg-6  bg-white p-3">
												<button
													className="btn btn-outline-primary col-12 mx-auto"
													onClick={modalIsOpenUpdateDetailsT}
												>
													Update Details
												</button>
											</div>
											<div className="col-lg-3"></div>
										</div>
										{/* modal update details */}

										<ResponsiveModal
											open={modalIsOpenUpdateT}
											onClose={closeModalUpdateDetailsT}
											center
										>
											<div className="courierBorder">
												<div className="p-5">
													<h2 className="text-primary text-center">Update Details</h2>
													<Alert />

													<UpdateUserApproverDetails />
												</div>
											</div>
										</ResponsiveModal>
									</div>
								</div>
							</div>
							<form onSubmit={onSubmit}>
								<div className="inputborder m-4">
									<div className="row">
										{/* <div className="col-lg-2"></div> */}
										<div className="col-lg-12">
											<div className="form-group p-3">
												<label htmlFor="">Type of Travel:</label>
												<div className="row">
													<div className="col-lg-2"></div>
													<div className="col-lg-4">
														<p>
															<input
																name="perdiemtype"
																type="radio"
																value="local"
																checked={perdiemState === "local"}
																onChange={handleOptionChange}
																className="m-3 custom-radio"
															/>{" "}
															Local Travel
														</p>
													</div>
													<div className="col-lg-4">
														<p>
															<input
																name="perdiemtype"
																type="radio"
																value="international"
																checked={perdiemState === "international"}
																onChange={handleOptionChange}
																className="m-3 custom-radio"
															/>{" "}
															International Travel
														</p>
													</div>
													<div className="col-lg-2"></div>
												</div>
											</div>

											{perdiemState === "local" && (
												<div>
													<div className="row">
														<div className="col-lg-12">
															<div className="form-group p-3">
																<label htmlFor="">Job Grade:</label>
																{loadedjobgrades.length !== 0 && (
																	<Typeahead
																		id="basic-typeahead-single"
																		labelKey="jobgradedescription"
																		onChange={setJobgradeState}
																		options={loadedjobgrades}
																		placeholder="Select your job grade"
																		selected={jobgradeState}
																		// required
																		size="lg"
																		inputProps={{ required: true }}
																	/>
																)}
															</div>
														</div>
													</div>
												</div>
											)}
											{perdiemState === "international" && (
												<div>
													{/* {jobgradeState.length > 0 && ( */}
													<div className="row">
														<div className="col-lg-6">
															<div className="form-group p-3">
																<label htmlFor="">Job Grade:</label>
																{loadedjobgrades.length !== 0 && (
																	<Typeahead
																		id="basic-typeahead-single"
																		labelKey="jobgradedescription"
																		onChange={setJobgradeState}
																		options={loadedjobgrades}
																		placeholder="Select your job grade"
																		selected={jobgradeState}
																		// required
																		size="lg"
																		inputProps={{ required: true }}
																	/>
																)}
															</div>
														</div>
														<div className="col-lg-6">
															<div className="form-group p-3">
																<label htmlFor="">Country Travelling To:</label>
																<Select
																	options={countryOptions}
																	value={selectedCountry}
																	onChange={handleSelectChange as any}
																	isClearable
																	placeholder="Select a country..."
																/>
																{selectedCountry && (
																	<div>
																		<h5>{selectedCountry.label}</h5>
																		<p>
																			Continent: {getCountryData(selectedCountry.value)!.continent}{" "}
																		</p>
																		<p
																			className={`fi fi-${getCountryData(
																				selectedCountry.value
																			)!.flag.toLowerCase()}`}
																			style={{ fontSize: "2.5rem" }}
																		></p>
																	</div>
																)}
															</div>
														</div>
													</div>
													{/* )} */}
												</div>
											)}
										</div>
										<div className="col-lg-3"></div>
									</div>
								</div>
								{jobgradeState.length > 0 && perdiemState === "local" && (
									<div>
										{rows.length !== 0 && (
											<Table className="table table-primary table-striped table-bordered table-responsive">
												<Thead className="">
													<Tr>
														<Th>#</Th>
														<Th>City</Th>
														<Th>Type Of Per Diem</Th>
														<Th>No.Of Days/Meals</Th>
														<Th>Amount Per Day(KES)</Th>
														<Th>Full Amount(KES)</Th>
														<Th>Action</Th>
													</Tr>
												</Thead>
												<Tbody>
													{rows.map((item: any, index: number) => (
														<Tr id="addr" key={index}>
															<Td>{item.id}</Td>
															<Td>
																<Select
																	menuPortalTarget={document.body}
																	styles={{
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}),
																	}}
																	// option={item.city}
																	selected-option={item.cityid}
																	name="city"
																	onChange={handleChange(item)}
																	options={mappedcities}
																/>
															</Td>
															<Td>
																<Select
																	menuPortalTarget={document.body}
																	styles={{
																		menuPortal: (base) => ({
																			...base,
																			zIndex: 9999,
																		}),
																	}}
																	// option={item.perdiemtype}
																	selected-option={item.perdiemtypeid}
																	name="typeofpayment"
																	onChange={handleChange(item)}
																	options={mappedperdiemtypes}
																/>
															</Td>
															<Td>
																<input
																	className="form-control"
																	type="number"
																	placeholder="e.g. 1"
																	name="numberofnights"
																	value={item.numberofnights}
																	onChange={handleChange(item) as any}
																	required
																/>
															</Td>
															<Td>
																<input
																	className="form-control text-right"
																	name="amount"
																	type="number"
																	onChange={handleChange(item) as any}
																	value={item.amount}
																	// disabled
																/>
															</Td>
															<Td>
																<input
																	className="form-control text-right"
																	name="fullamount"
																	type="number"
																	onChange={handleChange(item) as any}
																	value={item.fullamount}
																	disabled
																/>
															</Td>
															<Td>
																<button
																	type="button"
																	className="btn btn-danger btn-sm"
																	onClick={handleRemoveSpecificRow(item)}
																>
																	Remove Invoice
																</button>
															</Td>
														</Tr>
													))}
													<Tr>
														<Td colSpan="5" className="text-right">
															<label>Total Amount</label>
														</Td>
														<Td>
															<input
																className="form-control total-amount text-right"
																type="text"
																value={totalAmount}
															/>
														</Td>
													</Tr>
												</Tbody>
											</Table>
										)}
										<div className="row">
											<div className="col-lg-11"></div>
											<div className="col-lg-1">
												<button
													type="button"
													className="btn btn-primary"
													onClick={handleAddRow}
												>
													Add Row
												</button>
											</div>
										</div>
										<div className="row">
											<div className="col-lg-3"></div>
											<div className="col-lg-6">
												<div className="form-group p-3">
													<label htmlFor="description">Description:</label>
													<textarea
														// type="text"
														id="description"
														maxLength={50}
														value={description}
														className="form-control inputborder"
														rows={3}
														onChange={(e) => setDescription(e.target.value)}
													/>
												</div>
											</div>
											<div className="col-lg-3"></div>
										</div>

										<div className="form-group p-3">
											<label htmlFor="file">Files:</label>
											{files.length > 0 && (
												<div className="dropzone">
													<div className="row">
														{files.map((file, index) => (
															<div className="col-lg-6">
																<div className="dropzoneimage">
																	{imageExtensions.includes(getFileExtension(file.name)) && (
																		<img
																			src={URL.createObjectURL(file)}
																			alt="Uploaded file"
																			className="img-fluid"
																			// style={{ maxWidth: "20em" }}
																		/>
																	)}
																	{fileExtensions.includes(getFileExtension(file.name)) && (
																		// eslint-disable-next-line jsx-a11y/iframe-has-title
																		<div className="iframe-container">
																			<iframe
																				key={index}
																				src={URL.createObjectURL(file)}
																				// width={800}
																				// height={500}
																				className=" responsive-iframe"
																			/>
																		</div>
																	)}
																	<div>
																		<button
																			type="button"
																			className="btn btn-outline-danger remove-button"
																			onClick={() => handleFileDrop(index)}
																		>
																			Remove File
																		</button>
																	</div>
																</div>
															</div>
														))}
													</div>
												</div>
											)}
											<div {...getRootProps()} className="dropzone">
												Click to upload a file
												<input {...getInputProps()} className="form-control" multiple />
											</div>
										</div>
										<div className="row">
											<div className="col-lg-4"></div>
											<div className="col-lg-4">
												<button className="btn btn-primary col-12 mx-auto" type="submit">
													Submit
												</button>
											</div>
											<div className="col-lg-4"></div>
										</div>
									</div>
								)}
								{jobgradeState.length > 0 &&
									selectedCountry &&
									perdiemState === "international" && (
										<div>
											{rows.length !== 0 && (
												<Table className="table table-primary table-striped table-bordered table-responsive">
													<Thead className="">
														<Tr>
															<Th>#</Th>
															<Th>Type Of Per Diem</Th>
															<Th>No.Of Days/Meals</Th>
															<Th>Full Per Day(USD)</Th>
															<Th>Full Amount(USD)</Th>
															<Th>Action</Th>
														</Tr>
													</Thead>
													<Tbody>
														{rows.map((item, index) => (
															<Tr id="addr" key={index}>
																<Td>{item.id}</Td>
																<Td>
																	<Select
																		menuPortalTarget={document.body}
																		styles={{
																			menuPortal: (base) => ({
																				...base,
																				zIndex: 9999,
																			}),
																		}}
																		// option={item.perdiemtype}
																		selected-option={item.perdiemtypeid}
																		name="typeofpayment"
																		onChange={handleChange(item)}
																		options={mappedperdiemtypes}
																	/>
																</Td>
																<Td>
																	<input
																		className="form-control"
																		type="number"
																		name="numberofnights"
																		placeholder="e.g. 1"
																		value={item.numberofnights}
																		onChange={handleChange(item) as any}
																		required
																	/>
																</Td>
																<Td>
																	<input
																		className="form-control text-right"
																		name="amount"
																		type="text"
																		onChange={handleChange(item) as any}
																		value={item.amount}
																		// disabled
																	/>
																</Td>
																<Td>
																	<input
																		className="form-control text-right"
																		name="fullamount"
																		type="text"
																		onChange={handleChange(item) as any}
																		value={item.fullamount}
																		disabled
																	/>
																</Td>
																<Td>
																	<button
																		type="button"
																		className="btn btn-danger btn-sm"
																		onClick={handleRemoveSpecificRow(item)}
																	>
																		Remove Invoice
																	</button>
																</Td>
															</Tr>
														))}
														<Tr>
															<Td colSpan="5" className="text-right">
																<label>Total Amount</label>
															</Td>
															<Td>
																<input
																	className="form-control total-amount text-right"
																	type="text"
																	value={totalAmount}
																/>
															</Td>
														</Tr>
													</Tbody>
												</Table>
											)}
											<div className="row">
												<div className="col-lg-11"></div>
												<div className="col-lg-1">
													<button
														type="button"
														className="btn btn-primary"
														onClick={handleAddRow}
													>
														Add Row
													</button>
												</div>
											</div>
											<div className="row">
												<div className="col-lg-3"></div>
												<div className="col-lg-6">
													<div className="form-group p-3">
														<label htmlFor="description">Description:</label>
														<textarea
															// type="text"
															id="description"
															maxLength={50}
															value={description}
															className="form-control inputborder"
															rows={3}
															onChange={(e) => setDescription(e.target.value)}
														/>
													</div>
												</div>
												<div className="col-lg-3"></div>
											</div>

											<div className="form-group p-3">
												<label htmlFor="file">Files:</label>
												{files.length > 0 && (
													<div className="dropzone">
														<div className="row">
															{files.map((file, index) => (
																<div className="col-lg-6">
																	<div className="dropzoneimage">
																		{imageExtensions.includes(getFileExtension(file.name)) && (
																			<img
																				src={URL.createObjectURL(file)}
																				alt="Uploaded file"
																				className="img-fluid p-3"
																			/>
																		)}
																		{fileExtensions.includes(getFileExtension(file.name)) && (
																			// eslint-disable-next-line jsx-a11y/iframe-has-title
																			<div className="iframe-container">
																				<iframe
																					key={index}
																					src={URL.createObjectURL(file)}
																					// width={800}
																					// height={500}
																					className="responsive-iframe p-3"
																				/>
																			</div>
																		)}
																		<div>
																			<button
																				type="button"
																				className="btn btn-outline-danger remove-button"
																				onClick={() => handleFileDrop(index)}
																			>
																				Remove File
																			</button>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												)}
												<div {...getRootProps()} className="dropzone">
													Click to upload a file
													<input {...getInputProps()} className="form-control" multiple />
												</div>
											</div>
											<div className="row">
												<div className="col-lg-4"></div>
												<div className="col-lg-4">
													<button className="btn btn-primary col-12 mx-auto" type="submit">
														Submit
													</button>
												</div>
												<div className="col-lg-4"></div>
											</div>
										</div>
									)}
							</form>
						</div>
					)}
				</div>
			) : (
				<div>
					<div className="align-centre">
						<h5 className="text-center">Fetching user details</h5>
						<Spinner />
					</div>
				</div>
			)}
		</div>
	);
};

export default TablePerDiem;
