import { msalConfig } from "@/authConfig";
import {
	PublicClientApplication,
	EventType,
	AuthenticationResult,
} from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const msalInstance = new PublicClientApplication(msalConfig);

	if (
		msalInstance.getActiveAccount() &&
		msalInstance.getAllAccounts().length > 0
	) {
		msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
	}

	msalInstance.addEventCallback((event) => {
		const authenticationResult = event.payload as AuthenticationResult;
		const account = authenticationResult?.account;
		if (event.eventType === EventType.LOGIN_SUCCESS && account) {
			msalInstance.setActiveAccount(account);
		}
	});

	return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};

export default AuthProvider;
