import {
	CREATE_INVOICE,
	CREATE_INVOICE_ERROR,
	GET_INVOICE_TYPES,
	GET_INVOICE_TYPES_ERROR,
	INVOICE_TRACKING,
	INVOICE_TRACKING_ERROR,
	CREATE_PER_DIEM,
	CREATE_PER_DIEM_ERROR,
	INVOICE_TRACKING_DETAIL,
	INVOICE_TRACKING_DETAIL_ERROR,
	GET_MANAGER_APPROVE_LIST,
	GET_FINANCE_APPROVE_LIST_ERROR,
	GET_MANAGER_APPROVE_LIST_ERROR,
	GET_FINANCE_APPROVE_LIST,
	MANAGER_APPROVE_ERROR,
	MANAGER_APPROVE,
	CLEAR_INVOICE_ERRORS,
	FINANCE_APPROVE_ERROR,
	FINANCE_APPROVE,
	GET_PAYMENT_METHODS,
	GET_PAYMENT_METHODS_ERROR,
	MANAGER_REJECT_ERROR,
	FINANCE_REJECT_ERROR,
	MANAGER_REJECT,
	FINANCE_REJECT,
	GET_GL_ACCOUNTS,
	GET_GL_ACCOUNTS_ERROR,
	GET_COST_CENTRES_ERROR,
	GET_COST_CENTRES,
	INVOICE_MASTER_TRACKING,
	INVOICE_MASTER_TRACKING_ERROR,
	INVOICE_MASTER_FINANCE_TRACKING,
	INVOICE_MASTER_FINANCE_TRACKING_ERROR,
	CREATE_LOCAL_PER_DIEM_ERROR,
	CREATE_LOCAL_PER_DIEM,
	GET_TYPES_OF_PER_DIEMS_ERROR,
	GET_TYPES_OF_PER_DIEMS,
	GET_INVOICE_MASTER,
	GET_INVOICE_MASTER_ERROR,
	GET_INVOICE_MASTER_SINGLE_ERROR,
	GET_INVOICE_MASTER_SINGLE,
	GET_ALL_INVOICES_APPROVED,
	GET_ALL_INVOICES_PROGRESS,
	GET_ALL_INVOICES_REJECTED,
	GET_ALL_INVOICES_APPROVED_ERROR,
	GET_ALL_INVOICES_PROGRESS_ERROR,
	GET_ALL_INVOICES_REJECTED_ERROR,
	UPDATE_AWS_FILES_ERROR,
	UPDATE_AWS_FILES,
	CASH_OFFICE_COLLECTION,
	CASH_OFFICE_COLLECTION_ERROR,
	GET_ALL_INVOICES_APPROVED_NOT_PAID,
	GET_ALL_INVOICES_APPROVED_NOT_PAID_ERROR,
	GET_ALL_INVOICES_PAID_ERROR,
	GET_ALL_INVOICES_PAID,
} from "../types";

const InvoiceReducer = (state: any, action: any) => {
	switch (action.type) {
		case CLEAR_INVOICE_ERRORS:
			return {
				...state,
				invoicecreate: null,
				createperdiem: null,
				managerapprove: null,
				financeapprove: null,
				financereject: null,
				managerreject: null,
				createlocalperdiem: null,
				updateawsfiles: null,
				invoiceerror: null,
				cashofficecollection: null,
			};

		case GET_ALL_INVOICES_PAID:
			return {
				...state,
				loading: false,
				getallpaid: action.payload,
			};

		case GET_ALL_INVOICES_APPROVED_NOT_PAID:
			return {
				...state,
				loading: false,
				getallapprovednotpaid: action.payload,
			};

		case GET_GL_ACCOUNTS:
			return {
				...state,
				loading: false,
				getglaccounts: action.payload,
			};

		case CASH_OFFICE_COLLECTION:
			return {
				...state,
				loading: false,
				cashofficecollection: action.payload,
			};

		case GET_INVOICE_TYPES:
			return {
				...state,
				loading: false,
				invoicetypes: action.payload,
			};
		case CREATE_INVOICE:
			return {
				...state,
				loading: false,
				invoicecreate: action.payload,
			};

		case INVOICE_TRACKING:
			return {
				...state,
				loading: false,
				getinvoicetracking: action.payload,
			};

		case CREATE_PER_DIEM:
			return {
				...state,
				loading: false,
				createperdiem: action.payload,
			};
		case CREATE_LOCAL_PER_DIEM:
			return {
				...state,
				loading: false,
				createlocalperdiem: action.payload,
			};
		case INVOICE_TRACKING_DETAIL:
			return {
				...state,
				loading: false,
				getinvoicetrackingdetail: action.payload,
			};

		case GET_MANAGER_APPROVE_LIST:
			return {
				...state,
				loading: false,
				managerlistinvoices: action.payload,
			};
		case GET_FINANCE_APPROVE_LIST:
			return {
				...state,
				loading: false,
				financelistinvoices: action.payload,
			};
		case MANAGER_APPROVE:
			return {
				...state,
				loading: false,
				managerapprove: action.payload,
			};
		case FINANCE_APPROVE:
			return {
				...state,
				loading: false,
				financeapprove: action.payload,
			};

		case GET_PAYMENT_METHODS:
			return {
				...state,
				loading: false,
				getpaymentmethods: action.payload,
			};

		case MANAGER_REJECT:
			return {
				...state,
				loading: false,
				managerreject: action.payload,
			};

		case FINANCE_REJECT:
			return {
				...state,
				loading: false,
				financereject: action.payload,
			};

		case GET_COST_CENTRES:
			return {
				...state,
				loading: false,
				getcostcentres: action.payload,
			};

		case INVOICE_MASTER_TRACKING:
			return {
				...state,
				loading: false,
				getinvoicemastertrackingdetail: action.payload,
			};

		case INVOICE_MASTER_FINANCE_TRACKING:
			return {
				...state,
				loading: false,
				getinvoicemastertrackingfdetail: action.payload,
			};

		case GET_TYPES_OF_PER_DIEMS:
			return {
				...state,
				loading: false,
				getperdiemtypes: action.payload,
			};

		case GET_INVOICE_MASTER:
			return {
				...state,
				loading: false,
				getinvoicemaster: action.payload,
			};

		case GET_INVOICE_MASTER_SINGLE:
			return {
				...state,
				loading: false,
				getinvoicemastersingle: action.payload,
			};

		case GET_ALL_INVOICES_APPROVED:
			return {
				...state,
				loading: false,
				invoicemasterapproved: action.payload,
			};

		case GET_ALL_INVOICES_PROGRESS:
			return {
				...state,
				loading: false,
				invoicemasterprogress: action.payload,
			};

		case GET_ALL_INVOICES_REJECTED:
			return {
				...state,
				loading: false,
				invoicemasterrejected: action.payload,
			};

		case UPDATE_AWS_FILES:
			return {
				...state,
				loading: false,
				updateawsfiles: action.payload,
			};

		case GET_INVOICE_TYPES_ERROR:
		case CREATE_INVOICE_ERROR:
		case INVOICE_TRACKING_ERROR:
		case CREATE_PER_DIEM_ERROR:
		case INVOICE_TRACKING_DETAIL_ERROR:
		case GET_MANAGER_APPROVE_LIST_ERROR:
		case GET_FINANCE_APPROVE_LIST_ERROR:
		case MANAGER_APPROVE_ERROR:
		case FINANCE_APPROVE_ERROR:
		case GET_PAYMENT_METHODS_ERROR:
		case MANAGER_REJECT_ERROR:
		case FINANCE_REJECT_ERROR:
		case GET_GL_ACCOUNTS_ERROR:
		case GET_COST_CENTRES_ERROR:
		case INVOICE_MASTER_TRACKING_ERROR:
		case INVOICE_MASTER_FINANCE_TRACKING_ERROR:
		case CREATE_LOCAL_PER_DIEM_ERROR:
		case GET_TYPES_OF_PER_DIEMS_ERROR:
		case GET_INVOICE_MASTER_ERROR:
		case GET_INVOICE_MASTER_SINGLE_ERROR:
		case GET_ALL_INVOICES_APPROVED_ERROR:
		case GET_ALL_INVOICES_PROGRESS_ERROR:
		case GET_ALL_INVOICES_REJECTED_ERROR:
		case UPDATE_AWS_FILES_ERROR:
		case CASH_OFFICE_COLLECTION_ERROR:
		case GET_ALL_INVOICES_APPROVED_NOT_PAID_ERROR:
		case GET_ALL_INVOICES_PAID_ERROR:
			return {
				...state,
				loading: false,
				invoiceerror: action.payload,
			};

		default:
			return state;
	}
};

export default InvoiceReducer;
