import React, { useState } from "react"; //useContext
import { ISODateFormat } from "../../../constants";
import { FieldValues, useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
// import AlertContext from "../../../context/alert/AlertContext";
// import Alert from "../../layout/Alert";
import { useNavigate } from "react-router-dom";

const DateSelection = () => {
	// const alertContext = useContext(AlertContext);
	// const { setAlert } = alertContext;
	const { handleSubmit } = useForm(); //register,
	// const [reportState, setReportState] = useState("");
	const [startDate, setStartDate] = useState(new Date());
	const [endDate, setEndDate] = useState(new Date());
	let navigate_ = useNavigate();

	// const handleOptionReports = (e) => {
	//   setReportState(e.target.value);
	// };

	const onSubmitForm = () => {
		// e.preventDefault();
		//   if (reportState === "") {
		//     setAlert("Select the report you want to run", "danger");
		//   } else {
		//     if (data.report === "approved") {
		//       localStorage.setItem("startdate", ISODateFormat(startDate));
		//       localStorage.setItem("enddate", ISODateFormat(endDate));
		//         navigate_("/approvedinvoices");
		//     }

		//     if (data.report === "rejected") {
		//       localStorage.setItem("startdate", ISODateFormat(startDate));
		//       localStorage.setItem("enddate", ISODateFormat(endDate));
		//         navigate_("/rejectedinvoices");
		//     }
		//     if (data.report === "inprogress") {
		//       localStorage.setItem("startdate", ISODateFormat(startDate));
		//       localStorage.setItem("enddate", ISODateFormat(endDate));
		//       navigate_("/invoicesinprogress");
		//     }

		//     if (data.report === "paid") {
		//       localStorage.setItem("startdate", ISODateFormat(startDate));
		//       localStorage.setItem("enddate", ISODateFormat(endDate));
		//       navigate_("/paidinvoices");
		//     }
		//   }
		localStorage.setItem("startdate", ISODateFormat(startDate));
		localStorage.setItem("enddate", ISODateFormat(endDate));
		navigate_("/paidinvoices");
	};
	return (
		<div>
			<div className="courierBorder">
				<div className="p-5">
					<h1 className="text-center text-primary">Paid Invoice Reports</h1>
					<p className="text-center text-danger text-sm">
						Disclaimer: Select the date when payment was done.
					</p>
					{/* <Alert /> */}
					<form onSubmit={handleSubmit(onSubmitForm)}>
						{/* <fieldset className="form-group inputborder">
                <div className="row">
                  <h5>Select Report Type</h5>
                  <div className="col-lg-3">
                    <p>
                      <input
                        {...register("report", { required: true })}
                        type="radio"
                        value="approved"
                        checked={reportState === "approved"}
                        onChange={handleOptionReports}
                        className="m-3"
                      />{" "}
                      Approved Invoices But Not Paid Report
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      <input
                        {...register("report", { required: true })}
                        type="radio"
                        value="rejected"
                        checked={reportState === "rejected"}
                        onChange={handleOptionReports}
                        className="m-3"
                      />{" "}
                      Invoices with Changes Requested Report
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      <input
                        {...register("report", { required: true })}
                        type="radio"
                        value="inprogress"
                        checked={reportState === "inprogress"}
                        onChange={handleOptionReports}
                        className="m-3"
                      />{" "}
                      Invoices In Progress Report
                    </p>
                  </div>
                  <div className="col-lg-3">
                    <p>
                      <input
                        {...register("report", { required: true })}
                        type="radio"
                        value="paid"
                        checked={reportState === "paid"}
                        onChange={handleOptionReports}
                        className="m-3"
                      />{" "}
                      Paid Invoices Report
                    </p>
                  </div>
                </div>
              </fieldset> */}

						<div className="row p-5">
							<div className="col-lg-2"></div>
							<div className="col-lg-4">
								<div className="form-group">
									<label>Start Date</label>
									<DatePicker
										className="form-control"
										selected={startDate}
										onChange={(date) => {
											if (!date) return;
											setStartDate(date);
										}}
										selectsStart
										startDate={startDate}
										endDate={endDate}
										inline
									/>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="form-group">
									<label>End Date</label>
									<DatePicker
										className="form-control"
										selected={endDate}
										onChange={(date) => {
											if (!date) return;
											setEndDate(date);
										}}
										selectsEnd
										startDate={startDate}
										endDate={endDate}
										minDate={startDate}
										inline
									/>
								</div>
							</div>
							<div className="col-lg-2"></div>
						</div>
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<input
									type="submit"
									value="Pull Report"
									className="btn btn-primary col-12 mx-auto"
								/>
							</div>
							<div className="col-lg-4"></div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DateSelection;
