import React, { useRef, useState } from "react";
import { Button } from "../ui/button";
import { useMutation, useQueryClient } from "react-query";
import {
	CashCollectionParams,
	CashOfficeCollection,
	Invoice,
	makeBatchMpesaPayment,
	MpesaBatchPayment,
} from "@/services/invoice-functions";
import { toast } from "sonner";
import { Row } from "@tanstack/react-table";
import { formatAmount } from "@/constants";
import {
	Sheet,
	SheetContent,
	SheetDescription,
	SheetHeader,
	SheetTitle,
	SheetTrigger,
} from "../ui/sheet";
import { paymentMethodTag } from "@/pages/ReleasePayments";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../ui/table";

const BatchMpesa: React.FC<{
	invoices: Row<Invoice>[];
	accessToken: string;
	handleDeselectAll: () => void;
}> = ({ invoices, accessToken, handleDeselectAll }) => {
	const [open, setOpen] = useState(false);
	const toasterRef = useRef<string | number | undefined>(undefined);

	const queryClient = useQueryClient();

	const makeBatchCollection = useMutation({
		mutationKey: ["batch-mpesa"],
		mutationFn: (batch: MpesaBatchPayment) =>
			makeBatchMpesaPayment(accessToken, batch),
		onSuccess: () => {
			queryClient.invalidateQueries(["invoice"]);
			toast.success("Payment Release Successful", {
				description: "Batch payments have been released.",
				id: toasterRef.current,
			});
			setOpen(false);
			makeBatchCollection.reset();
			handleDeselectAll();
		},
		onError: () => {
			toast.error("Error", {
				description: "Error occured creating batch Mpesa payment",
				id: toasterRef.current,
			});
		},
	});

	const makeCollection = useMutation({
		mutationKey: ["cashcollection"],
		mutationFn: (collection: CashCollectionParams) =>
			CashOfficeCollection(accessToken, collection),
		onSuccess: () => {
			toast.success("Cash Collection Successful", { position: "bottom-right" });
			makeCollection.reset();
		},
		onError: () => {
			toast.error("Error submitting cash collection");
		},
	});

	const getIds = (rows: Row<Invoice>[]) => {
		let ids: number[] = [];
		rows.forEach((row) => {
			ids.push(row.original.invoicemasterid);
		});
		return ids;
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const setFilename = () => {
		const date = new Date();
		const day = date.toLocaleDateString("en-US", {
			weekday: "short",
		});
		const month = date.toLocaleDateString("en-US", {
			month: "long",
		});
		const year = date.toLocaleDateString("en-US", {
			year: "numeric",
		});
		const hour = date.getHours();
		const minute = date.getMinutes();
		const second = date.getSeconds();
		return `batch-mpesa-${day}${month}${year}${hour}${minute}${second}`;
	};

	const submitIds = () => {
		if (invoices.some((invoice) => !invoice.original.country)) {
			makeBatchCollection.mutate({
				invoicemasterid: getIds(
					invoices.filter((invoice) => !invoice.original.country)
				),
				paymentmethod: 2,
			});
			toasterRef.current = toast.loading("Processing export..", {
				description: "You will receive a file shortly.",
			});
		}

		invoices
			.filter((invoice) => invoice.original.country)
			.forEach((invoice) => {
				makeCollection.mutate({
					amountcollected: invoice.original.amount,
					collectedby: invoice.original.collectedby,
					invoicemasterid: invoice.original.invoicemasterid.toString(),
					paymentmethod: invoice.original.paymentmethod,
				});
			});
	};

	const getTotal = (invoices: Row<Invoice>[]) => {
		return formatAmount(
			invoices.reduce(
				(total, invoice) => total + parseFloat(invoice.original.amount),
				0
			),
			"Ksh"
		);
	};

	const disabled = () => {
		if (makeBatchCollection.isLoading) return true;
		if (makeBatchCollection.isSuccess) return true;
		return (
			invoices.reduce(
				(acc, invoice) => acc + parseFloat(invoice.original.amount),
				0
			) === 0
		);
	};

	return (
		<Sheet open={open} onOpenChange={(open) => setOpen(open)}>
			<SheetTrigger asChild disabled={disabled()}>
				<Button>Process Payment</Button>
			</SheetTrigger>
			<SheetContent className="w-full sm:max-w-4xl sm:w-2/3 max-w overflow-y-auto flex flex-col">
				<SheetHeader>
					<SheetTitle>Process Payment Summary</SheetTitle>
					<SheetDescription>
						This action cannot be undone. This will permanently confirm the claims
						below as paid.
					</SheetDescription>
				</SheetHeader>
				<div className="flex gap-4">
					<div className="flex flex-col">
						<p className="text-sm font-light">Mpesa Total</p>
						<p className="text-2xl font-bold text-green-600">
							{getTotal(invoices.filter((invoice) => !invoice.original.country))}
						</p>
					</div>
					<div className="flex flex-col">
						<p className="text-sm font-light">USD Total</p>
						<p className="text-2xl font-bold text-orange-600">
							{getTotal(invoices.filter((invoice) => invoice.original.country))}
						</p>
					</div>
				</div>
				<Table className="border">
					<TableHeader className="">
						<TableRow>
							<TableHead>Claim No.</TableHead>
							<TableHead>Amount</TableHead>
							<TableHead>Payment Method</TableHead>
							<TableHead>Raised By</TableHead>
						</TableRow>
					</TableHeader>
					<TableBody>
						{invoices.map((invoice) => (
							<TableRow key={invoice.original.invoicemasterid}>
								<TableCell style={{ verticalAlign: "middle" }}>
									<span className="hover:text-primary hover:border-b border-primary">
										{invoice.original.invoicemasterid}
									</span>
								</TableCell>
								<TableCell>
									{paymentMethodTag(invoice.original.paymentmethoddes)}
								</TableCell>
								<TableCell className="font-medium">
									{formatAmount(parseInt(invoice.original.amount), "Ksh")}
								</TableCell>
								<TableCell>{invoice.original.raisedby}</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<Button className="w-full" onClick={submitIds}>
					Confirm Payment
				</Button>
			</SheetContent>
		</Sheet>
	);
};

export default BatchMpesa;
