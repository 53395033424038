import ColumnHeader from "@/components/data-table/ColumnHeader";
import DataTable from "@/components/data-table/DataTable";
import InvoiceTag from "@/components/data-table/InvoiceTag";
import { DateConverter, STALE_TIME_INVOICE } from "@/constants";
import { UserContext } from "@/context/User/UserContext";
import { FinanceListInvoices, Invoice } from "@/services/invoice-functions";
import { ColumnDef } from "@tanstack/react-table";
import { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const FinanceMainApprovals = () => {
	const user = useContext(UserContext);
	const financeinvoices = useQuery({
		queryKey: ["invoice", "finance"],
		queryFn: () => FinanceListInvoices(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
	});

	const navigate = useNavigate();

	const handleRowClick = (id: number) => {
		navigate(`/financeapproval/${id}`);
	};

	return (
		<>
			{financeinvoices.data ? (
				<DataTable
					columns={Columns}
					data={financeinvoices.data}
					rowClick={handleRowClick}
					rowId="invoicemasterid"
					searchColumn="raisedby"
				/>
			) : null}
			{financeinvoices.isLoading ? (
				<div className="flex justify-center">
					<span className="loader" />
				</div>
			) : financeinvoices.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error showing claims.</h5>
				</div>
			) : (
				financeinvoices.data &&
				financeinvoices.data.length === 0 && (
					<div className="align-centre">
						<h5 className="text-center">No claims found</h5>
					</div>
				)
			)}
		</>
	);
};

export default FinanceMainApprovals;

const Columns: ColumnDef<Invoice>[] = [
	{
		accessorKey: "invoicemasterid",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Claim ID" />,
		// size: 10,
		maxSize: 200,
	},
	{
		accessorKey: "description",
		header: ({ column }) => <ColumnHeader column={column} title="Description" />,
		cell: ({ row }) => {
			const invoice = row.original;
			return (
				<div className="text-sm flex gap-1 items-center">
					<InvoiceTag id={invoice.invoicetypeid} type={invoice.invoicetype} />
					<p>
						{invoice.description.length > 100
							? invoice.description.slice(0, 100) + "..."
							: invoice.description}
					</p>
				</div>
			);
		},
	},
	{
		accessorKey: "raisedby",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Raised By" />,
	},
	{
		accessorKey: "amount",
		header: ({ column }) => <ColumnHeader column={column} title="Amount" />,
		cell: ({ row }) => {
			const country = row.original.country;

			return (
				<div className="font-medium">
					{country ? "USD " : "KSH "} {row.getValue("amount")}
				</div>
			);
		},
	},
	{
		accessorKey: "raisedon",
		header: ({ column }) => <ColumnHeader column={column} title="Raised On" />,
		cell: ({ row }) => {
			const date = new Date(row.getValue("raisedon"));
			return DateConverter(date);
		},
	},
	{
		accessorKey: "managerapprovedbyname",
		header: ({ column }) => <ColumnHeader column={column} title="Approved By" />,
		enableHiding: false,
	},
];
