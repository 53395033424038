import { Invoice } from "@/services/invoice-functions";
import React from "react";

type InvoiceStatusProps = {
	data: Invoice;
};

const InvoiceStatus: React.FC<InvoiceStatusProps> = ({ data }) => {
	if (data.collected) {
		return (
			<div className="py-1 px-3 bg-green-200 rounded-lg">
				<p className="text-green-600 text-sm">Cash Collected</p>
			</div>
		);
	}

	if (data.secondaryfinanceapproved === true) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Awaiting Cash Collection</p>
			</div>
		);
	}

	if (data.secondaryfinanceapproved === false) {
		return (
			<div className="py-1 px-3 bg-red-200 rounded-lg">
				<p className="text-red-600 text-sm">Finance Disapproved</p>
			</div>
		);
	}

	if (data.secondaryfinancetobeapprovedby) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Awaiting Finance Approval</p>
			</div>
		);
	}

	if (data.financeapproved) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Awaiting Cash Collection</p>
			</div>
		);
	}

	if (data.financerejected) {
		return (
			<div className="py-1 px-3 bg-red-200 rounded-lg">
				<p className="text-red-600 text-sm">Finance Disapproved</p>
			</div>
		);
	}

	if (data.financeamend) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Correction Needed</p>
			</div>
		);
	}

	if (data.secondarymanagerapproved === true) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Awaiting Finance Approval</p>
			</div>
		);
	}

	if (data.secondarymanagerapproved === false) {
		return (
			<div className="py-1 px-3 bg-red-200 rounded-lg">
				<p className="text-red-600 text-sm">Manager Disapproved</p>
			</div>
		);
	}

	if (data.secondarymanagertobeapprovedby) {
		return (
			<div className="py-1 px-3 bg-purple-200 rounded-lg">
				<p className="text-purple-600 text-sm">Awaiting Manager Approval</p>
			</div>
		);
	}

	if (data.managerapproved) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Awaiting Finance Approval</p>
			</div>
		);
	}

	if (data.managerrejected) {
		return (
			<div className="py-1 px-3 bg-red-200 rounded-lg">
				<p className="text-red-600 text-sm">Manager Disapproved</p>
			</div>
		);
	}

	if (data.manageramend) {
		return (
			<div className="py-1 px-3 bg-orange-200 rounded-lg">
				<p className="text-orange-600 text-sm">Correction Needed</p>
			</div>
		);
	}

	return (
		<div className="py-1 px-3 bg-purple-200 rounded-lg">
			<p className="text-purple-600 text-sm">Awaiting Manager Approval</p>
		</div>
	);
};

export default InvoiceStatus;
