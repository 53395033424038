import React, { useEffect, useState } from "react";
import ShowImages from "./ShowImages";
import { getFileExtension, getS3FileUrl } from "@/constants";
import { FaFile, FaFilePdf, FaFileWord } from "react-icons/fa";

type InvoiceAttachmentsProps = {
	images: string[];
	docs: string[];
};

const InvoiceAttachments: React.FC<InvoiceAttachmentsProps> = ({ images, docs }) => {
	const [docUrls, setDocUrls] = useState<string[]>([]);

	useEffect(() => {
		const fetchDocUrls = async () => {
			const urls = await Promise.all(
				docs.map(async (doc) => {
					return await getS3FileUrl(doc); // Fetch the URL asynchronously
				})
			);
			setDocUrls(urls);
		};

		if (docs.length > 0) {
			fetchDocUrls();
		}
	}, [docs]);

	return (
		<>
			{images.length > 0 && <ShowImages images={images} />}
			{docs.length > 0 && (
				<div className="flex flex-col divide-y">
					{docUrls.map((docUrl, index) => (
						<a
							href={docUrl}
							target="_blank"
							rel="noreferrer"
							key={index}
							className="py-3 px-2 hover:rounded-md flex gap-2 hover:bg-blue-50 items-center"
						>
							{getFileExtension(docs[index]) === "pdf" ? (
								<FaFilePdf className="text-2xl text-red-700" />
							) : getFileExtension(docs[index]) === "docx" || getFileExtension(docs[index]) === "doc" ? (
								<FaFileWord className="text-2xl text-blue-700" />
							) : (
								<FaFile className="text-2xl text-red-700" />
							)}
							<span className="font-medium text-sm">{docs[index].split("/")[2]}</span>
						</a>
					))}
				</div>
			)}
		</>
	);
};

export default InvoiceAttachments;
