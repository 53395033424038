import ColumnHeader from "@/components/data-table/ColumnHeader";
import InvoiceTag from "@/components/data-table/InvoiceTag";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
	Command,
	CommandGroup,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { DateConverter, formatAmount, STALE_TIME_INVOICE } from "@/constants";
import { UserContext } from "@/context/User/UserContext";
import { cashOfficeInvoices, Invoice } from "@/services/invoice-functions";
import {
	ColumnDef,
	ColumnFiltersState,
	flexRender,
	getCoreRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	Row,
	SortingState,
	useReactTable,
	VisibilityState,
} from "@tanstack/react-table";
import { useContext, useEffect, useState } from "react";
import { FiPlusCircle } from "react-icons/fi";
import { useQuery } from "react-query";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../components/ui/table";
import DataTablePagination from "../components/data-table/DataTablePagination";
import BatchMpesa from "../components/dialog/BatchMpesa";
import { NavLink } from "react-router-dom";

const ReleasePayments = () => {
	const user = useContext(UserContext);
	const [sorting, setSorting] = useState<SortingState>([]);
	const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
	const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
	const [rowSelection, setRowSelection] = useState({});

	const methods = ["mpesa", "cash", "cheque"];

	const approvedinvoices = useQuery({
		queryKey: ["invoice", "cashOffice"],
		queryFn: () => cashOfficeInvoices(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
	});

	const isChecked = (method: string) => {
		const methods = columnFilters.find((f) => f.id === "paymentmethoddes")
			?.value as Array<string> | undefined;
		return methods ? methods.includes(method) : false;
	};

	const paySelect = (method: string) => {
		setColumnFilters((old) => {
			const pm = old.find((f) => f.id === "paymentmethoddes");
			if (!pm) {
				return [...old, { id: "paymentmethoddes", value: [method] }];
			}
			const value = pm.value as Array<string>;
			return [
				...old.filter((f) => f.id !== "paymentmethoddes"),
				{
					id: "paymentmethoddes",
					value: value.includes(method)
						? value.filter((m) => m !== method)
						: [...value, method],
				},
			];
		});
	};

	const existing = () => {
		const pm = columnFilters.find((f) => f.id === "paymentmethoddes");

		if (!pm) {
			return null;
		}

		const value = pm.value as Array<string>;

		return (
			<>
				{value.length > 0 && (
					<div className="border-l border-zinc-400">
						{value.map((method) => (
							<span
								key={method}
								className="text-sm rounded-sm px-2 py-1 font-light bg-primary/10 text-primary ml-2"
							>
								{method}
							</span>
						))}
					</div>
				)}
			</>
		);
	};

	const handleDeselectAll = () => {
		setRowSelection({});
	};

	const table = useReactTable({
		data: approvedinvoices.data,
		columns: Columns,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		onSortingChange: setSorting,
		getSortedRowModel: getSortedRowModel(),
		onColumnFiltersChange: setColumnFilters,
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
		},
	});

	return (
		<div>
			<h1 className="text-4xl font-bold mb-2">Release Payments</h1>
			<h3 className="text-zinc-600 font-light mb-4">
				Here are a list of approved claims that await payment release.
			</h3>

			{approvedinvoices.data ? (
				<>
					<TotalPayable
						rowSelection={table.getSelectedRowModel().flatRows as Row<Invoice>[]}
						accessToken={user?.accessToken!}
						handleDeselectAll={handleDeselectAll}
					/>
					<div className="flex items-end pb-2 justify-start space-x-2">
						<Input
							placeholder="Search by Name..."
							value={(table.getColumn("raisedby")?.getFilterValue() as string) ?? ""}
							onChange={(event) =>
								table.getColumn("raisedby")?.setFilterValue(event.target.value)
							}
							className="max-w-sm"
						/>
						<div className="flex">
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant="outline"
										className="px-2 py-1 border-dotted border-zinc-400"
									>
										<div className="flex items-center space-x-2">
											<FiPlusCircle />
											<span className="text-sm">Payment Method</span>
											{existing()}
										</div>
									</Button>
								</PopoverTrigger>
								<PopoverContent className="w-40 p-0" align="start">
									<Command>
										<CommandList>
											<CommandGroup>
												{methods.map((method) => (
													<CommandItem key={method} onSelect={() => paySelect(method)}>
														<Checkbox id={method} checked={isChecked(method)} />
														<p className="text-sm font-light ml-2">{method}</p>
													</CommandItem>
												))}
											</CommandGroup>
										</CommandList>
									</Command>
								</PopoverContent>
							</Popover>
							{columnFilters.length > 0 &&
							columnFilters.find((f) => f.id === "paymentmethoddes") &&
							(
								columnFilters.find((f) => f.id === "paymentmethoddes")
									?.value as Array<string>
							).length > 0 ? (
								<Button
									variant="ghost"
									onClick={() =>
										setColumnFilters((f) => f.filter((f) => f.id !== "paymentmethoddes"))
									}
								>
									Reset
								</Button>
							) : null}
						</div>
					</div>
					<div className="rounded-md border">
						<Table>
							<TableHeader>
								{table.getHeaderGroups().map((headerGroup) => (
									<TableRow key={headerGroup.id}>
										{headerGroup.headers.map((header) => {
											return (
												<TableHead
													key={header.id}
													// className="w-12"
													// style={{
													// 	maxWidth: header.column.columnDef.maxSize,
													// 	overflow: "hidden",
													// 	textOverflow: "ellipsis",
													// 	whiteSpace: "nowrap",
													// }}

													style={{ maxWidth: header.column.columnDef.maxSize }}
													// style={{ width: header.getSize() }}
												>
													{header.isPlaceholder
														? null
														: flexRender(header.column.columnDef.header, header.getContext())}
												</TableHead>
											);
										})}
									</TableRow>
								))}
							</TableHeader>
							<TableBody>
								{table.getRowModel().rows?.length ? (
									table.getRowModel().rows.map((row) => (
										<TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
											{row.getVisibleCells().map((cell) => (
												<TableCell
													key={cell.id}
													style={{ maxWidth: cell.column.columnDef.maxSize }}
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</TableCell>
											))}
										</TableRow>
									))
								) : (
									<TableRow>
										<TableCell colSpan={Columns.length} className="h-24 text-center">
											No results.
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</div>
					<div className="pt-4 pb-2">
						<DataTablePagination table={table} />
					</div>
				</>
			) : null}
			{approvedinvoices.isLoading ? (
				<div className="flex justify-center">
					<span className="loader" />
				</div>
			) : approvedinvoices.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error showing claims.</h5>
				</div>
			) : (
				approvedinvoices.data &&
				approvedinvoices.data.length === 0 && (
					<div className="align-centre">
						<h5 className="text-center">No claims found</h5>
					</div>
				)
			)}
		</div>
	);
};

export default ReleasePayments;

const Columns: ColumnDef<Invoice>[] = [
	{
		id: "select",
		header: ({ table }) => (
			<div className="flex items-center">
				<Checkbox
					checked={
						table.getIsAllPageRowsSelected() ||
						(table.getIsSomePageRowsSelected() && "indeterminate")
					}
					onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
					aria-label="Select all"
				/>
			</div>
		),
		cell: ({ row }) => (
			<div className="flex items-center" onClick={(e) => e.stopPropagation()}>
				<Checkbox
					onClick={(e) => e.stopPropagation()}
					checked={row.getIsSelected()}
					onCheckedChange={(value) => {
						row.toggleSelected(!!value);
					}}
					aria-label="Select row"
				/>
			</div>
		),
	},
	{
		accessorKey: "invoicemasterid",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Claim ID" />,
		size: 80,
		maxSize: 100,
		cell: ({ row }) => (
			<div className="flex items-center">
				<NavLink to={`/cashcollection/${row.original.invoicemasterid}`}>
					<Button variant="link" className="px-2 py-1 font-light">
						{row.getValue("invoicemasterid")}
					</Button>
				</NavLink>
			</div>
		),
	},
	{
		accessorKey: "description",
		header: ({ column }) => <ColumnHeader column={column} title="Description" />,
		cell: ({ row }) => {
			const invoice = row.original;
			return (
				<div className="text-sm flex gap-1 items-center">
					<InvoiceTag id={invoice.invoicetypeid} type={invoice.invoicetype} />
					<p>
						{invoice.description.length > 100
							? invoice.description.slice(0, 100) + "..."
							: invoice.description}
					</p>
				</div>
			);
		},
	},
	{
		accessorKey: "amount",
		header: ({ column }) => <ColumnHeader column={column} title="Amount" />,
		cell: ({ row }) => {
			const country = row.original.country;

			return (
				<div className="font-medium">
					{country ? "USD " : "KSH "} {row.getValue("amount")}
				</div>
			);
		},
	},
	{
		accessorKey: "paymentmethoddes",
		header: ({ column }) => (
			<ColumnHeader column={column} title="Payment Method" />
		),
		cell: ({ row }) => {
			return (
				// <span className="font-light text-sm rounded-md p-1 bg-green-100 text-green-600 px-2">
				// 	{row.getValue("paymentmethoddes")}
				// </span>
				paymentMethodTag(row.getValue("paymentmethoddes"))
			);
		},
		filterFn: (row, columnId, filterValue) => {
			const paymentMethod = row.getValue(columnId) as string | null;
			return filterValue.length === 0
				? true
				: paymentMethod
				? filterValue.includes(paymentMethod.toLowerCase())
				: false;
		},
	},
	{
		accessorKey: "raisedby",
		header: ({ column }) => <ColumnHeader column={column} title="Raised By" />,
	},
	{
		accessorKey: "financeapprovedon",
		header: ({ column }) => <ColumnHeader column={column} title="Approved On" />,
		cell: ({ row }) => {
			const date = new Date(row.getValue("financeapprovedon"));
			return DateConverter(date);
		},
	},
];

export const paymentMethodTag = (paymentMethod: string) => {
	const styles: { [key: string]: string } = {
		Cash: "bg-orange-100 text-orange-600",
		Cheque: "bg-blue-100 text-blue-600",
		Mpesa: "bg-green-100 text-green-600",
	};

	return (
		<span
			className={`text-sm rounded-sm px-2 py-1 font-medium ${
				styles[paymentMethod] ? styles[paymentMethod] : ""
			}`}
		>
			{paymentMethod}
		</span>
	);
};

const TotalPayable: React.FC<{
	rowSelection: Row<Invoice>[];
	accessToken: string;
	handleDeselectAll: () => void;
}> = ({ rowSelection, accessToken, handleDeselectAll }) => {
	const [totalMpesaAmount, setTotalMpesaAmount] = useState<number>(0);
	const [totalUSDAmount, setTotalUSDAmount] = useState<number>(0);

	const getTotal = (invoices: Row<Invoice>[]) => {
		let mpesaTotal = 0;
		let usdTotal = 0;
		invoices.forEach((invoice: any) => {
			if (!invoice.original.country)
				mpesaTotal += parseFloat(invoice.original.amount);
			else usdTotal += parseFloat(invoice.original.amount);
		});
		setTotalMpesaAmount(mpesaTotal);
		setTotalUSDAmount(usdTotal);
	};

	useEffect(() => {
		getTotal(rowSelection);
	}, [rowSelection]);

	return (
		<div
			className={`mb-4 animate-in fade-in overflow-hidden transition-all duration-300 ease-in-out rounded-md ${
				totalMpesaAmount === 0 && totalUSDAmount === 0
					? "border-none max-h-0"
					: "border max-h-36"
			} `}
		>
			<div className="flex p-4 justify-between items-center">
				<div className="flex flex-col">
					<p className="text-sm font-light">Total Mpesa Amount Selected</p>
					<p className="text-2xl font-bold text-green-600">
						{formatAmount(totalMpesaAmount, "Ksh")}
					</p>
				</div>
				<div className="flex flex-col">
					<p className="text-sm font-light">Total USD Amount Selected</p>
					<p className="text-2xl font-bold text-orange-600">
						{formatAmount(totalUSDAmount, "Ksh")}
					</p>
				</div>
				<BatchMpesa
					invoices={rowSelection}
					accessToken={accessToken}
					handleDeselectAll={handleDeselectAll}
				/>
			</div>
		</div>
	);
};
