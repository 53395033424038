/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useContext, useEffect } from "react";
import InvoiceContext from "../../context/Invoice/InvoiceContext";
import AlertContext from "../../context/alert/AlertContext";
import AuthContext from "../../context/Auth/AuthContext";
import { Modal } from "react-responsive-modal";
import { useDropzone } from "react-dropzone";
import { Typeahead } from "react-bootstrap-typeahead";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";

import {
	getFileExtension,
	imageExtensions,
	fileExtensions,
	acceptedFiles,
} from "../../constants";
import UpdateUserApproverDetails from "../common/UpdateUserApproverDetails";

const CreateClaim = () => {
	const invoiceContext = useContext(InvoiceContext);
	const alertContext = useContext(AlertContext);
	const authContext = useContext(AuthContext);

	const {
		invoicetypes,
		getInvoiceTypes,
		invoicecreate,
		InvoiceCreate,
		clearInvoiceErrors,
		invoiceerror,
	} = invoiceContext;
	// const { setAlert } = alertContext;

	const { getsingleuserdetails, getSingleUserDetails } = authContext;

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [modalIsOpenEdit, setModalIsOpenEdit] = useState(false);
	const [modalIsOpenUpdate, setModalIsOpenUpdate] = useState(false);
	const [editingRowIndex, setEditingRowIndex] = useState<number | null>(null);
	const [amount, setAmount] = useState("");
	const [InvoiceType, SetInvoiceType] = useState<any[]>([]);
	const [description, setDescription] = useState("");
	const [kms, setKms] = useState("");
	// const [file, setFile] = useState(null);
	const [files, setFiles] = useState<any[]>([]);
	const [tableData, setTableData] = useState<any[]>([]);
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		getInvoiceTypes();
		getSingleUserDetails();
		if (invoicecreate) {
			if (alertContext) alertContext.setAlert(invoicecreate.message, "success");
			setVisible(false);
			setTableData([]);
			clearInvoiceErrors();
		}

		if (invoiceerror) {
			setVisible(false);
			if (alertContext) alertContext.setAlert(invoiceerror.message, "danger");
			clearInvoiceErrors();
		}
		// eslint-disable-next-line
	}, [invoicecreate, invoiceerror]);

	// const MAX_FILE_SIZE = 2097152;
	// Handle modal open
	const openModal = () => {
		setModalIsOpen(true);
	};

	let loadedinvoices = [];

	if (invoicetypes) {
		loadedinvoices = invoicetypes;
	} else {
		loadedinvoices = [];
	}

	const handleRemoveRow = (index: number) => {
		setTableData((prevTableData) => {
			const updatedTableData = [...prevTableData];
			updatedTableData.splice(index, 1);
			return updatedTableData;
		});
	};
	// Handle modal close
	const closeModal = () => {
		setModalIsOpen(false);
		setAmount("");
		setDescription("");
		// setFile(null);
		SetInvoiceType([]);
		setFiles([]);
		setKms("");
	};

	const modalIsOpenUpdateDetails = () => {
		console.log("open");
		setModalIsOpenUpdate(true);
	};

	const closeModalUpdateDetails = () => {
		console.log("closed");
		setModalIsOpenUpdate(false);
	};

	const closeModalEdit = () => {
		setModalIsOpenEdit(false);
		setAmount("");
		setDescription("");
		// setFile(null);
		SetInvoiceType([]);
		setFiles([]);
		setKms("");
	};
	// Edit row
	const handleEditRow = (index: number) => {
		// Retrieve the row data based on the index
		const rowData: any = tableData[index];
		setEditingRowIndex(index);
		setAmount(rowData.amount);
		setDescription(rowData.description);
		setFiles(rowData.files);
		setKms(rowData.kms);
		SetInvoiceType([
			{
				description: rowData.invoicetype,
				invoicetypeid: rowData.invoicetypeid,
			},
		]);
		setModalIsOpenEdit(true);
	};

	// Handle file drop
	const handleFileChange = (acceptedFiles: any, rejectedFiles: any) => {
		if (rejectedFiles.length > 0) {
			// Handle rejected files (size or type restrictions)
			rejectedFiles.forEach((file: any) => {
				if (alertContext) alertContext.setAlert(file.errors[0].message, "danger");
			});
		} else {
			// Handle acceptedFiles (upload, store, etc.)
			setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
		}
	};

	// Handle form submit
	const handleSubmitInvoice = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let invoicetypeid = InvoiceType[0].invoicetypeid;
		let invoicetype = InvoiceType[0].description;
		const newData = {
			invoicetype,
			kms,
			invoicetypeid,
			amount,
			description,
			files,
		};

		setTableData((prevData) => [...prevData, newData]);
		closeModal();
	};

	const handleSubmitEdit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let invoicetypeid = InvoiceType[0].invoicetypeid;
		let invoicetype = InvoiceType[0].description;
		// Update the row data with the edited data
		const updatedRow = {
			invoicetype,
			kms,
			invoicetypeid,
			amount,
			description,
			files,
		};

		// Update the table data
		const updatedTableData = [...tableData];
		if (editingRowIndex) {
			updatedTableData[editingRowIndex] = updatedRow;
			setTableData(updatedTableData);
		}

		// Close the modal
		closeModalEdit();
	};

	// Handle table save
	const handleSave = () => {
		InvoiceCreate(tableData);
		setVisible(true);
	};

	// React Dropzone configuration
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: handleFileChange,
		accept: acceptedFiles as any,
		multiple: true,
		// maxSize: MAX_FILE_SIZE,
	});

	const handleFileDrop = (index: number) => {
		setFiles((prevTableData) => {
			const updatedTableData = [...prevTableData];
			updatedTableData.splice(index, 1);
			return updatedTableData;
		});
	};

	// Open or add invoice button text
	const buttonLabel =
		tableData.length > 0 ? "Add Claim Invoice" : "Create Claim Invoice";

	return (
		<div className="courierBorder">
			{getsingleuserdetails ? (
				<div className="p-1">
					<Alert />
					<div className="row">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							{visible ? (
								<div className="align-centre">
									<h5 className="text-center">Creating Invoice(s)...</h5>
									<Spinner />
								</div>
							) : (
								<div></div>
							)}
						</div>
						<div className="col-lg-4"></div>
					</div>
					{getsingleuserdetails.manageremail === null ||
					getsingleuserdetails.managername === null ||
					getsingleuserdetails.departmentname === null ||
					getsingleuserdetails.phone === null ? (
						<div className="row p-5">
							<div className="col-lg-3"></div>
							<div className="col-lg-6">
								<UpdateUserApproverDetails />
							</div>
							<div className="col-lg-3"></div>
						</div>
					) : (
						<div>
							<div className="row">
								<div className="col-lg-3"></div>
								<div className="col-lg-6">
									<h4 className="text-primary text-center">Current Approver Details</h4>
									<table className="table table-striped table-bordered table-responsive">
										<thead></thead>
										<tbody>
											<tr>
												<th className="table-primary text-primary text-lg-start">
													Approver
												</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.managername}
												</td>
											</tr>
											<tr>
												<th className="table-primary text-primary text-lg-start">
													Approver Email
												</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.manageremail}
												</td>
											</tr>
											<tr>
												<th className="table-primary text-primary text-lg-start">
													Your department
												</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.departmentname}
												</td>
											</tr>
											<tr>
												<th className="table-primary text-primary text-lg-start">Phone</th>
												<td className="table-primary align-left">
													{getsingleuserdetails.phone}
												</td>
											</tr>
										</tbody>
									</table>
									<div className="p-2 bg-gradient bg-info border border-info">
										<p>
											If the above details are correct, proceed and raise a claim, else,
											click the button below to update them.
										</p>
										<div className="row">
											<div className="col-lg-3"></div>
											<div className="col-lg-6  bg-white p-3">
												<button
													className="btn btn-outline-primary col-12 mx-auto"
													onClick={modalIsOpenUpdateDetails}
												>
													Update Details
												</button>
											</div>
											<div className="col-lg-3"></div>
										</div>
									</div>
								</div>
							</div>
							<div className="row button-align m-2">
								<div className="col-lg-4"></div>
								<div className="col-lg-4">
									<button
										className="btn btn-outline-primary col-12 mx-auto"
										onClick={openModal}
									>
										{buttonLabel}
									</button>
								</div>
								<div className="col-lg-4"></div>
							</div>

							{/* Modal */}
							<div>
								<Modal open={modalIsOpen} onClose={closeModal} center>
									<div className="courierBorder">
										<div className="p-5">
											<h2 className="text-primary text-center">Raise Claim</h2>
											<Alert />
											<div className="p-2 bg-gradient bg-info border border-info">
												<p>
													Raise one claim at a time then click interim save. You can add
													another claim of a different Invoice Type. To raise Per Diem,
													kindly visit the per diem Link on the sidebar.
												</p>
											</div>
											<form onSubmit={handleSubmitInvoice}>
												<div className="form-group p-3">
													<label htmlFor="">Invoice Type</label>
													{loadedinvoices.length !== 0 && (
														<Typeahead
															id="basic-typeahead-single"
															labelKey="description"
															onChange={SetInvoiceType as any}
															options={loadedinvoices}
															placeholder="Select Invoices"
															selected={InvoiceType}
															// required
															inputProps={{ required: true }}
														/>
													)}
												</div>
												{InvoiceType.length > 0 && InvoiceType[0].invoicetypeid === 1 && (
													<div className="form-group p-3">
														<label htmlFor="kms">Number of Kms:</label>
														<input
															type="number"
															id="kms"
															value={kms}
															className="form-control custom-input"
															onChange={(e) => setKms(e.target.value)}
															required
														/>
													</div>
												)}
												<div className="form-group p-3">
													<label htmlFor="amount">Amount in KES:</label>
													<input
														type="number"
														id="amount"
														value={amount}
														className="form-control custom-input"
														onChange={(e) => setAmount(e.target.value)}
														required
													/>
												</div>
												<div className="form-group p-3">
													<label htmlFor="description">Description:</label>
													<textarea
														// type="text"
														id="description"
														value={description}
														className="form-control"
														maxLength={50}
														rows={3}
														onChange={(e) => setDescription(e.target.value)}
														required
													/>
												</div>
												<div className="form-group p-3">
													<label htmlFor="file">Files:</label>
													{files.length > 0 && (
														<div className="dropzone">
															<div className="row">
																{files.map((file: any, index) => (
																	<div className="col-lg-6">
																		<div className="dropzoneimage">
																			{imageExtensions.includes(getFileExtension(file.name)) && (
																				<img
																					src={URL.createObjectURL(file)}
																					alt="Uploaded file"
																					className="img-fluid"
																				/>
																			)}
																			{fileExtensions.includes(getFileExtension(file.name)) && (
																				// eslint-disable-next-line jsx-a11y/iframe-has-title
																				<div className="iframe-container">
																					<iframe
																						key={index}
																						src={URL.createObjectURL(file)}
																						className=" responsive-iframe"
																					/>
																				</div>
																			)}
																			<div>
																				<button
																					type="button"
																					className="btn btn-outline-danger remove-button"
																					onClick={() => handleFileDrop(index)}
																				>
																					Remove File
																				</button>
																			</div>
																		</div>
																	</div>
																))}
															</div>
														</div>
													)}
													<div {...getRootProps()} className="dropzone">
														Click to upload a file
														<input {...getInputProps()} className="form-control" multiple />
													</div>
												</div>
												<div className="row">
													<div className="col-lg-4"></div>
													<div className="col-lg-4">
														<button className="btn btn-primary col-12 mx-auto" type="submit">
															Interim Save
														</button>
													</div>
													<div className="col-lg-4"></div>
												</div>
											</form>
										</div>
									</div>
								</Modal>
							</div>

							<div>
								<Modal
									open={modalIsOpenUpdate}
									onClose={closeModalUpdateDetails}
									center
								>
									<div className="courierBorder">
										<div className="p-5">
											<h2 className="text-primary text-center">Update Details</h2>
											<Alert />

											<UpdateUserApproverDetails />
										</div>
									</div>
								</Modal>
							</div>

							{/* Modal */}
							<Modal open={modalIsOpenEdit} onClose={closeModalEdit} center>
								<div className="courierBorder">
									<div className="p-5">
										<h2>Edit Row</h2>
										<div className="p-2 bg-gradient bg-info border border-info">
											<p>
												Raise one claim at a time then click interim save. You can add
												another claim of a different Invoice Type. To raise Per Diem, kindly
												visit the per diem Link on the sidebar.
											</p>
										</div>
										<form onSubmit={handleSubmitEdit}>
											<div className="form-group p-3">
												<label htmlFor="">Invoice Type</label>
												{loadedinvoices.length !== 0 && (
													<Typeahead
														id="basic-typeahead-single"
														labelKey="description"
														onChange={SetInvoiceType as any}
														options={loadedinvoices}
														placeholder="Select Invoices"
														selected={InvoiceType}
														// required
														inputProps={{ required: true }}
													/>
												)}
											</div>
											{InvoiceType.length > 0 && InvoiceType[0].invoicetypeid === 1 && (
												<div className="form-group p-3">
													<label htmlFor="kms">Number of Kms:</label>
													<input
														type="number"
														id="kms"
														value={kms}
														className="form-control custom-input"
														onChange={(e) => setKms(e.target.value)}
													/>
												</div>
											)}
											<div className="form-group p-3">
												<label htmlFor="amount">Amount in KES:</label>
												<input
													type="number"
													id="amount"
													value={amount}
													className="form-control custom-input"
													onChange={(e) => setAmount(e.target.value)}
												/>
											</div>
											<div className="form-group p-3">
												<label htmlFor="description">Description:</label>
												<textarea
													// type="text"
													id="description"
													value={description}
													className="form-control"
													maxLength={50}
													rows={3}
													onChange={(e) => setDescription(e.target.value)}
												/>
											</div>
											<div className="form-group p-3">
												<label htmlFor="file">Files:</label>
												{files.length > 0 && (
													<div className="dropzone">
														<div className="row">
															{files.map((file: any, index) => (
																<div className="col-lg-6">
																	<div className="dropzoneimage">
																		{imageExtensions.includes(getFileExtension(file.name)) && (
																			<img
																				src={URL.createObjectURL(file)}
																				alt="Uploaded file"
																				className="img-fluid"
																			/>
																		)}
																		{fileExtensions.includes(getFileExtension(file.name)) && (
																			// eslint-disable-next-line jsx-a11y/iframe-has-title
																			<div className="iframe-container">
																				<iframe
																					key={index}
																					src={URL.createObjectURL(file)}
																					className=" responsive-iframe"
																				/>
																			</div>
																		)}
																		<div>
																			<button
																				type="button"
																				className="btn btn-outline-danger remove-button"
																				onClick={() => handleFileDrop(index)}
																			>
																				Remove File
																			</button>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												)}
												<div {...getRootProps()} className="dropzone">
													Click to upload a file
													<input {...getInputProps()} className="form-control" multiple />
												</div>
											</div>
											<div className="row">
												<div className="col-lg-4"></div>
												<div className="col-lg-4">
													<button className="btn btn-primary col-12 mx-auto" type="submit">
														Interim Save
													</button>
												</div>
												<div className="col-lg-4"></div>
											</div>
										</form>
									</div>
								</div>
							</Modal>

							{/* Table */}
							{tableData.length > 0 && (
								<div>
									<Table className="mt-5 table table-striped table-bordered table-hover">
										<Thead>
											<Tr>
												<Th>Invoice Type</Th>
												<Th>No. of Kms</Th>
												<Th>Amount</Th>
												<Th>Description</Th>
												<Th>Attachment</Th>
												<Th>Actions</Th>
											</Tr>
										</Thead>
										<Tbody>
											{tableData.map((data: any, index) => (
												<Tr key={index}>
													<Td>{data.invoicetype}</Td>
													<Td>
														{/* {data.invoicetypeid === 1 ? ():()} */}
														{data.invoicetypeid === 1 ? <p>{data.kms}</p> : <p>N/A</p>}
													</Td>
													<Td>{data.amount}</Td>
													<Td>{data.description}</Td>
													<Td style={{ verticalAlign: "middle" }}>
														<Td className="row">
															{data.files.length > 0 &&
																data.files.map((file: any, index: number) => (
																	<div className="col-lg-4">
																		<div className="dropzoneimage">
																			{imageExtensions.includes(getFileExtension(file.name)) && (
																				<img
																					src={URL.createObjectURL(file)}
																					alt="Uploaded file"
																					className="img-fluid"
																					// style={{ maxWidth: "20em" }}
																				/>
																			)}
																			{fileExtensions.includes(getFileExtension(file.name)) && (
																				// eslint-disable-next-line jsx-a11y/iframe-has-title
																				<div className="iframe-container">
																					<iframe
																						key={index}
																						src={URL.createObjectURL(file)}
																						// width={800}
																						// height={500}
																						className=" responsive-iframe"
																					/>
																				</div>
																			)}
																		</div>
																	</div>
																))}
														</Td>
													</Td>
													<Td>
														<button
															className="btn btn-success col-12 mx-auto m-2"
															onClick={() => handleEditRow(index)}
														>
															Edit
														</button>
														<button
															className="btn btn-danger col-12 mx-auto m-2"
															onClick={() => handleRemoveRow(index)}
														>
															Remove
														</button>
													</Td>
												</Tr>
											))}
										</Tbody>
									</Table>

									<div className="row">
										<div className="col-lg-4"></div>
										<div className="col-lg-4">
											<button
												className="btn btn-primary btn-block col-12 mx-auto"
												onClick={handleSave}
											>
												Submit Claim
											</button>
										</div>
										<div className="col-lg-4"></div>
									</div>
								</div>
							)}
						</div>
					)}
				</div>
			) : (
				<div>
					<div className="align-centre">
						<h5 className="text-center">Fetching user details</h5>
						<Spinner />
					</div>
				</div>
			)}
		</div>
	);
};

export default CreateClaim;
