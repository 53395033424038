/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import InvoiceContext from "../../context/Invoice/InvoiceContext";
import AlertContext from "../../context/alert/AlertContext";
import { useLocation } from "react-router-dom";
import FileComponent from "../layout/FileComponent";
import Spinner from "../layout/Spinner";
import {
	getFileExtension,
	imageExtensions,
	fileExtensions,
	TimeConverter,
	getCountryData,
	DateConverter,
} from "../../constants";
// import ModalImage from "react-modal-image";
import { useForm } from "react-hook-form";
import Alert from "../layout/Alert";
import { Link } from "react-router-dom";
import { Invoice } from "../../context/Invoice/InvoiceState";
import FilePdfComponent from "../layout/FilePdfComponent";

const FinanceApproval = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const invoicemasterid = searchParams.get("invoicemasterid");
	const invoicecontext = useContext(InvoiceContext);
	const alertContext = useContext(AlertContext);
	const [approveState, setApproveState] = useState("");
	const [visible, setVisible] = useState(false);
	const [visibler, setVisibler] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<any[]>([]);
	const [glAccount, setGlAccount] = useState<any[]>([]);
	const [costCentre, setCostCentre] = useState<any[]>([]);

	const {
		getinvoicemastertrackingdetail,
		getInvoiceMasterTrackingDetail,
		getinvoicemastersingle,
		getInvoiceMasterSingle,
		financeapprove,
		FinanceApprove,
		getPaymentMethods,
		getpaymentmethods,
		clearInvoiceErrors,
		FinanceReject,
		financereject,
		getGlAccounts,
		getglaccounts,
		getcostcentres,
		getCostCentres,
	} = invoicecontext;
	// const { setAlert } = alertContext;
	const { register, handleSubmit, reset } = useForm();

	useEffect(() => {
		getPaymentMethods();
		getGlAccounts();
		getCostCentres();
		getInvoiceMasterTrackingDetail(invoicemasterid);
		getInvoiceMasterSingle(invoicemasterid);
		if (financeapprove) {
			if (alertContext) alertContext.setAlert(financeapprove.message, "success");
			setVisible(false);
			reset();
			setPaymentMethod([]);
			clearInvoiceErrors();
		}

		if (financereject) {
			if (alertContext) alertContext.setAlert(financereject.message, "success");
			setVisibler(false);
			reset();
			// setPaymentMethod([])
			clearInvoiceErrors();
		}

		// eslint-disable-next-line
	}, [financeapprove, reset, financereject]);

	let loadedpaymentmethods = [];
	let loadedglaccounts = [];
	let loadedcostcentres = [];

	if (getpaymentmethods) {
		loadedpaymentmethods = getpaymentmethods;
	} else {
		loadedpaymentmethods = [];
	}

	if (getglaccounts) {
		loadedglaccounts = getglaccounts;
	} else {
		loadedglaccounts = [];
	}

	if (getcostcentres) {
		loadedcostcentres = getcostcentres;
	} else {
		loadedcostcentres = [];
	}
	const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setApproveState(e.target.value);
	};

	let loadedinvoices = [];
	if (getinvoicemastertrackingdetail) {
		loadedinvoices = getinvoicemastertrackingdetail;
	} else {
		loadedinvoices = [];
	}

	let loadedmasterinvoice: Invoice | null = null;
	if (getinvoicemastersingle) {
		loadedmasterinvoice = getinvoicemastersingle;
	} else {
		loadedmasterinvoice = null;
	}

	const onSubmit = (data: any) => {
		// e.preventDefault();
		if (data.approval === "approve") {
			let financeapprovedremark = data.financeapprovedremark;
			let paymentmethod = paymentMethod[0].paymentmethodid;
			let glaccountid = glAccount[0].glaccountid;
			let costcentreid = costCentre[0].costcentreid;
			FinanceApprove({
				financeapprovedremark,
				invoicemasterid,
				paymentmethod,
				glaccountid,
				costcentreid,
			});
			setVisible(true);
		} else if (data.approval === "reject") {
			let financerejectedremark = data.financerejectedremark;
			FinanceReject({ financerejectedremark, invoicemasterid });
			setVisibler(true);
		}
	};
	return (
		<div>
			{getinvoicemastertrackingdetail &&
			getinvoicemastersingle &&
			loadedmasterinvoice ? (
				<form onSubmit={handleSubmit(onSubmit)}>
					<section>
						<h3>sasa</h3>
						<h1 className="text-center text-primary">
							Invoice No. {getinvoicemastersingle.invoicemasterid} Finance Approval
						</h1>
						<Alert />
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								{visible ? (
									<div className="align-centre">
										<h5 className="text-center">
											Approving invoice no.{getinvoicemastersingle.invoicemasterid}
											...
										</h5>
										<Spinner />
									</div>
								) : (
									<div></div>
								)}
								{visibler ? (
									<div className="align-centre">
										<h5 className="text-center">
											Rejecting invoice no.{getinvoicemastersingle.invoicemasterid}
											...
										</h5>
										<Spinner />
									</div>
								) : (
									<div></div>
								)}
							</div>
							<div className="col-lg-4"></div>
						</div>
						{loadedmasterinvoice.invoicetypeid === 6 ? (
							<div>
								<table className="table table-striped table-bordered table-responsive">
									{/* <Thead></Thead> */}
									<tbody>
										{/* <tr className="text-center">General Information</tr> */}
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Raised By</th>
											<td className="align-left">{loadedmasterinvoice.raisedby}</td>
										</tr>
										<tr className="table-primary">
											<th className="text-primary">Job Grade</th>
											<td>{loadedmasterinvoice.jobgradedescription}</td>
										</tr>
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Raised On</th>
											<td className="align-left">
												{DateConverter(loadedmasterinvoice.raisedon)}
											</td>
										</tr>
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Raised At</th>
											<td className="align-left">
												{TimeConverter(loadedmasterinvoice.raisedat)}
											</td>
										</tr>

										{loadedmasterinvoice.traveltypeid === 1 && (
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Country Of Visit</th>
												<td className="align-left">
													<div className="row">
														<div className="col-lg-2">
															<p>Country Name: {loadedmasterinvoice.country}</p>
															<p>Continent Name: {loadedmasterinvoice.continent}</p>
														</div>
														<div className="col-lg">
															<p
																className={` fi fi-${getCountryData(
																	loadedmasterinvoice.country
																)!.flag.toLowerCase()}`}
																style={{ fontSize: "4rem" }}
															></p>
														</div>
													</div>
												</td>
											</tr>
										)}
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Status</th>
											<td className="align-left">
												{loadedmasterinvoice.managerapproved && (
													<div className="inputborder bg-white m-1">
														<p className="text-success">
															Manager Approved by {loadedmasterinvoice.managerapprovedbyname}
														</p>
														<p className="text-success">
															Manager Approval remark:{" "}
															{loadedmasterinvoice.managerapprovedremark}
														</p>
													</div>
												)}
												{loadedmasterinvoice.managerrejected && (
													<div className="inputborder bg-white m-1">
														<p className="text-info">
															Manager Rejected by {loadedmasterinvoice.managerrejectedbyname}
														</p>
														<p className="text-info">
															Manager Request Change remark:{" "}
															{loadedmasterinvoice.managerrejectedremark}
														</p>
													</div>
												)}
												{loadedmasterinvoice.financeapproved && (
													<div className="inputborder bg-white m-1">
														<p className="text-success">
															Finance Approved by {loadedmasterinvoice.financeapprovedbyname}
														</p>
														<p className="text-success">
															Manager Approval remark:{" "}
															{loadedmasterinvoice.financeapprovedremark}
														</p>
													</div>
												)}
												{loadedmasterinvoice.financerejected && (
													<div className="inputborder bg-white m-1">
														<p className="text-info">
															Finance Rejected by {loadedmasterinvoice.financerejectedbyname}
														</p>
														<p className="text-info">
															Finance Request Change remark:{" "}
															{loadedmasterinvoice.financerejectedremark}
														</p>
													</div>
												)}
											</td>
										</tr>
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Currency</th>
											<td className="align-left">
												{loadedmasterinvoice.traveltypeid === 1 && <p>USD</p>}
												{loadedmasterinvoice.traveltypeid === 2 && <p>KES</p>}
											</td>
										</tr>
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Gross Amount</th>
											<td className="align-left">{loadedmasterinvoice.amount}</td>
										</tr>

										<tr className="table-primary">
											<th className="text-primary text-lg-start">Invoice Description</th>
											<td className="align-left">{loadedmasterinvoice.description}</td>
										</tr>
										<tr className="table-primary">
											<th className="text-primary text-lg-start">Attachments</th>
											<td className="align-left">
												<div className="row">
													{loadedmasterinvoice.files !== null ? (
														<div>
															{loadedmasterinvoice.files.split(",").map((fileName, index) => {
																return (
																	<div className="col-lg-6">
																		{imageExtensions.includes(getFileExtension(fileName)) && (
																			<FileComponent fileName={fileName}/>
																		)}
																		{fileExtensions.includes(getFileExtension(fileName)) && (
																			// eslint-disable-next-line jsx-a11y/iframe-has-title

																			<FilePdfComponent fileName={fileName}/>
																		)}
																	</div>
																);
															})}
														</div>
													) : (
														<div>No Attachments</div>
													)}
												</div>
											</td>
										</tr>
									</tbody>
								</table>
								<h3 className="text-center text-primary">Entries</h3>
								<table className="table table-striped table-bordered table-responsive">
									<thead>
										<tr className="table-primary">
											<th>Payment For:</th>
											<th>No. of days/lunch/dinner</th>
											{loadedmasterinvoice.traveltypeid === 2 && <th>City Visited</th>}
											<th>Gross Amount</th>
										</tr>
									</thead>
									<tbody>
										{loadedinvoices.map((option: any) => (
											<tr key={option.invoiceid} className="table-primary">
												<td>{option.perdiemtype}</td>
												<td>{option.days}</td>
												{loadedmasterinvoice && loadedmasterinvoice.traveltypeid === 2 && (
													<td>{option.city}</td>
												)}
												<td>
													{option.currency}:{option.grossamount}
												</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>
						) : (
							<table className="table table-striped table-bordered table-responsive">
								<thead></thead>
								<tbody>
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Raised By</th>
										<td className="align-left">{loadedmasterinvoice.raisedby}</td>
									</tr>
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Raised On</th>
										<td className="align-left">
											{DateConverter(loadedmasterinvoice.raisedon)}
										</td>
									</tr>
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Raised At</th>
										<td className="align-left">
											{TimeConverter(loadedmasterinvoice.raisedat)}
										</td>
									</tr>
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Status</th>
										<td className="align-left">
											{loadedmasterinvoice.managerapproved && (
												<div className="inputborder bg-white m-1">
													<p className="text-success">
														Manager Approved by {loadedmasterinvoice.managerapprovedbyname}
													</p>
													<p className="text-success">
														Manager Approval remark:{" "}
														{loadedmasterinvoice.managerapprovedremark}
													</p>
												</div>
											)}
											{loadedmasterinvoice.managerrejected && (
												<div className="inputborder bg-white m-1">
													<p className="text-info">
														Manager Rejected by {loadedmasterinvoice.managerrejectedbyname}
													</p>
													<p className="text-info">
														Manager Request Change remark:{" "}
														{loadedmasterinvoice.managerrejectedremark}
													</p>
												</div>
											)}
											{loadedmasterinvoice.financeapproved && (
												<div className="inputborder bg-white m-1">
													<p className="text-success">
														Finance Approved by {loadedmasterinvoice.financeapprovedbyname}
													</p>
													<p className="text-success">
														Manager Approval remark:{" "}
														{loadedmasterinvoice.financeapprovedremark}
													</p>
												</div>
											)}
											{loadedmasterinvoice.financerejected && (
												<div className="inputborder bg-white m-1">
													<p className="text-info">
														Finance Rejected by {loadedmasterinvoice.financerejectedbyname}
													</p>
													<p className="text-info">
														Finance Request Change remark:{" "}
														{loadedmasterinvoice.financerejectedremark}
													</p>
												</div>
											)}
										</td>
									</tr>
									{loadedmasterinvoice.invoicetypeid === 1 && (
										<tr className="table-primary">
											<th className="text-primary text-lg-start">No. Of KMS</th>
											<td className="align-left">{loadedinvoices[0].kms}</td>
										</tr>
									)}
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Currency</th>
										<td className="align-left">KES</td>
									</tr>
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Gross Amount</th>
										<td className="align-left">{loadedmasterinvoice.amount}</td>
									</tr>

									<tr className="table-primary">
										<th className="text-primary text-lg-start">Invoice Description</th>
										<td className="align-left">{loadedmasterinvoice.description}</td>
									</tr>
									<tr className="table-primary">
										<th className="text-primary text-lg-start">Attachments</th>
										<td className="align-left">
											<div className="row">
												{loadedmasterinvoice.files !== null ? (
													<div>
														{loadedmasterinvoice.files.split(",").map((fileName, index) => {
															return (
																<div className="col-lg-6">
																	{imageExtensions.includes(getFileExtension(fileName)) && (
																		<FileComponent fileName={fileName}/>
																	)}
																	{fileExtensions.includes(getFileExtension(fileName)) && (
																		// eslint-disable-next-line jsx-a11y/iframe-has-title

																		<FilePdfComponent fileName={fileName}/>
																	)}
																</div>
															);
														})}
													</div>
												) : (
													<div>No Attachments</div>
												)}
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						)}
						{loadedmasterinvoice.financeapproved ||
						loadedmasterinvoice.financerejected ? (
							<div className="align-centre">
								<Link className="btn btn-success" to={"/financeapprovelist"}>
									Back to unapproved invoices
								</Link>
							</div>
						) : (
							<div className="courierBorder p-5">
								<div className="row">
									<div className="col-lg-4"></div>
									<div className="col-lg-2">
										<p>
											<input
												{...register("approval", { required: true })}
												type="radio"
												value="approve"
												checked={approveState === "approve"}
												onChange={handleOptionChange}
												className="m-3 custom-radio"
											/>{" "}
											Approve Invoice
										</p>
									</div>
									<div className="col-lg-2">
										<p>
											<input
												{...register("approval", { required: true })}
												type="radio"
												value="reject"
												checked={approveState === "reject"}
												onChange={handleOptionChange}
												className="m-3 custom-radio"
											/>{" "}
											Request For Changes
										</p>
									</div>
									<div className="col-lg-4"></div>
								</div>
								{approveState === "approve" && (
									<div className="row">
										<div className="col-lg-3"></div>
										<div className="col-lg-6">
											<div className="form-group p-2">
												<label htmlFor="">GL Account</label>
												{loadedglaccounts.length !== 0 && (
													<Typeahead
														id="basic-typeahead-single"
														// labelKey="glaccountdescription"
														labelKey={(option: any) =>
															`${option.glaccount} ${option.glaccountdescription}`
														}
														onChange={setGlAccount as any}
														options={loadedglaccounts}
														placeholder="Select GL Account"
														selected={glAccount}
														// required
														size="lg"
														filterBy={["glaccountdescription", "glaccount"]}
														inputProps={{ required: true, style: {} }}
													/>
												)}
											</div>
											<div className="form-group p-2">
												<label htmlFor="">Cost Centres</label>
												{loadedcostcentres.length !== 0 && (
													<Typeahead
														id="basic-typeahead-single"
														// labelKey="product"
														labelKey={(option: any) =>
															`${option.costcentre} ${option.product}`
														}
														onChange={setCostCentre as any}
														options={loadedcostcentres}
														placeholder="Select Cost Centre"
														selected={costCentre}
														// required
														size="lg"
														filterBy={["costcentre", "product"]}
														inputProps={{ required: true, style: {} }}
													/>
												)}
											</div>
											<div className="form-group p-2">
												<label htmlFor="">Invoice Type</label>
												{loadedpaymentmethods.length !== 0 && (
													<Typeahead
														id="basic-typeahead-single"
														labelKey="paymentmethoddes"
														onChange={setPaymentMethod as any}
														options={loadedpaymentmethods}
														placeholder="Select payment method"
														selected={paymentMethod}
														// required
														size="lg"
														inputProps={{ required: true, style: {} }}
													/>
												)}
											</div>
											<div className="form-group p-2">
												<label htmlFor="">Approval Remark</label>
												<textarea
													className="form-control inputborder"
													maxLength={50}
													// name="financeapprovedremark"
													{...register("financeapprovedremark", {
														required: true,
													})}
													rows={4}
												></textarea>
											</div>
										</div>
										<div className="col-lg-3"></div>
									</div>
								)}
								{approveState === "reject" && (
									<div className="row">
										<div className="col-lg-3"></div>
										<div className="col-lg-6">
											<div className="form-group p-2">
												<label htmlFor="">Changes Remark</label>
												<textarea
													className="form-control inputborder"
													maxLength={500}
													// name="financerejectedremark"
													{...register("financerejectedremark", {
														required: true,
													})}
													rows={4}
												></textarea>
											</div>
										</div>
										<div className="col-lg-3"></div>
									</div>
								)}
								<div className="row mt-5">
									<div className="col-lg-4"></div>
									<div className="col-lg-4">
										<button type="submit" className="btn btn-primary col-12 mx-auto">
											Submit
										</button>
									</div>
									<div className="col-lg-4"></div>
								</div>
							</div>
						)}
					</section>
				</form>
			) : (
				<div className="align-centre">
					<h5 className="text-center">Loading invoices, please wait...</h5>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default FinanceApproval;
