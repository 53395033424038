/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import InvoiceContext from "../../context/Invoice/InvoiceContext";
import AlertContext from "../../context/alert/AlertContext";
import { useLocation } from "react-router-dom";
import Spinner from "../layout/Spinner";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Alert from "../layout/Alert";
// import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import {
	DateConverter,
	getFileExtension,
	imageExtensions,
	fileExtensions,
	TimeConverter,
	getCountryData,
} from "../../constants";
import { Invoice } from "../../context/Invoice/InvoiceState";
import FileComponent from "../layout/FileComponent";
import FilePdfComponent from "../layout/FilePdfComponent";

const InvoiceMasterTracking = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const invoicemasterid = searchParams.get("invoicemasterid");
	const invoicecontext = useContext(InvoiceContext);
	const alertContext = useContext(AlertContext);

	const {
		getinvoicemastertrackingdetail,
		getInvoiceMasterTrackingDetail,
		getinvoicemastersingle,
		getInvoiceMasterSingle,
		updateawsfiles,
		UpdateAwsFiles,
		clearInvoiceErrors,
	} = invoicecontext;

	// const { setAlert } = alertContext;

	useEffect(() => {
		// loadToken()
		getInvoiceMasterTrackingDetail(invoicemasterid);
		getInvoiceMasterSingle(invoicemasterid);

		if (updateawsfiles) {
			if (alertContext) alertContext.setAlert(updateawsfiles.message, "success");
			closeModal();
			getInvoiceMasterTrackingDetail(invoicemasterid);
			getInvoiceMasterSingle(invoicemasterid);
			clearInvoiceErrors();
		}

		// eslint-disable-next-line
	}, [updateawsfiles]);

	let loadedinvoices = [];
	if (getinvoicemastertrackingdetail) {
		loadedinvoices = getinvoicemastertrackingdetail;
	} else {
		loadedinvoices = [];
	}

	let loadedmasterinvoice: Invoice | null = null;
	if (getinvoicemastersingle) {
		loadedmasterinvoice = getinvoicemastersingle;
	} else {
		loadedmasterinvoice = null;
	}

	let watermarkcondition = "";
	if (loadedmasterinvoice) {
		if (
			loadedmasterinvoice.financeapproved &&
			loadedmasterinvoice.managerapproved
		) {
			watermarkcondition = "approved";
		} else if (
			loadedmasterinvoice.financerejected ||
			loadedmasterinvoice.managerrejected
		) {
			watermarkcondition = "rejected";
		} else if (
			loadedmasterinvoice.financerejected &&
			loadedmasterinvoice.managerapproved
		) {
			watermarkcondition = "rejected";
		}
	}

	const renderWatermark = () => {
		if (watermarkcondition === "approved") {
			return <div className="watermarkapproved">Approved</div>;
		} else if (watermarkcondition === "rejected") {
			return <div className="watermarkrejected">Change Request</div>;
		} else {
			return <div className="watermark">In Progress</div>;
		}
	};

	// modal logic
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [files, setFiles] = useState<any[]>([]);
	const [removedFiles, setRemovedFiles] = useState<any[]>([]);

	const handleFileChange = (acceptedFiles: any) => {
		setFiles((prevFiles: any) => [...prevFiles, ...acceptedFiles]);
	};

	const { getRootProps, getInputProps } = useDropzone({
		accept: "image/*" as any,
		multiple: true,
		onDrop: handleFileChange,
	});

	const handleFileDrop = (index: number) => {
		setFiles((prevTableData) => {
			const updatedTableData = [...prevTableData];
			updatedTableData.splice(index, 1);
			return updatedTableData;
		});
	};

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setFiles([]);
		setRemovedFiles([]);
		setModalIsOpen(false);
	};

	// remove file
	const EditFileStringRemove = (fileToRemove: any) => {
		if (!loadedmasterinvoice) return;
		if (!loadedmasterinvoice.files) return;
		const currentFilesArray = loadedmasterinvoice.files.split(",");

		// Use the filter method to create a new array without the file to remove
		const updatedFilesArray = currentFilesArray.filter(
			(fileName) => fileName.trim() !== fileToRemove.trim()
		);

		// Join the updated array back into a string with a comma separator
		const updatedFilesString = updatedFilesArray.join(",");

		// Now, you can update loadedmasterinvoice.files with the updated string
		loadedmasterinvoice.files = updatedFilesString;

		setRemovedFiles((prevData) => [...prevData, fileToRemove]);
	};

	const UpdateFilesAws = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (!loadedmasterinvoice) return;
		let invoiceid = loadedmasterinvoice.invoicemasterid;
		UpdateAwsFiles({ files, removedFiles, invoiceid });
	};

	// end modal logic

	return (
		<div>
			<Alert />
			{getinvoicemastertrackingdetail &&
			getinvoicemastersingle &&
			loadedmasterinvoice ? (
				<div>
					{/* {keys.map((option) => ( */}
					<section>
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<h1 className="text-center">
									Document No. {loadedmasterinvoice.invoicemasterid}
								</h1>

								<h2 className="text-center text-primary">
									{" "}
									<u>{loadedmasterinvoice.invoicetype}</u>
								</h2>
							</div>
							<div className="col-lg-4"></div>
						</div>
						<div>
							{loadedmasterinvoice.invoicetypeid === 6 ? (
								<div>
									<table className="table table-striped table-bordered table-responsive">
										{/* <Thead></Thead> */}
										<tbody>
											{/* <tr className="text-center">General Information</tr> */}
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised By</th>
												<td className="align-left">{loadedmasterinvoice.raisedby}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary">Job Grade</th>
												<td>{loadedmasterinvoice.jobgradedescription}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised On</th>
												<td className="align-left">
													{DateConverter(loadedmasterinvoice.raisedon)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised At</th>
												<td className="align-left">
													{TimeConverter(loadedmasterinvoice.raisedat)}
												</td>
											</tr>

											{loadedmasterinvoice.traveltypeid === 1 && (
												<tr className="table-primary">
													<th className="text-primary text-lg-start">Country Of Visit</th>
													<td className="align-left">
														<div className="row">
															<div className="col-lg-2">
																<p>Country Name: {loadedmasterinvoice.country}</p>
																<p>Continent Name: {loadedmasterinvoice.continent}</p>
															</div>
															<div className="col-lg">
																<p
																	className={` fi fi-${getCountryData(
																		loadedmasterinvoice.country
																	)!.flag.toLowerCase()}`}
																	style={{ fontSize: "4rem" }}
																></p>
															</div>
														</div>
													</td>
												</tr>
											)}
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Status</th>
												<td className="align-left">
													{loadedmasterinvoice.managerapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Manager Approved by {loadedmasterinvoice.managerapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.managerapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.managerrejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-primary">
																Manager Rejected by {loadedmasterinvoice.managerrejectedbyname}
															</p>
															<p className="text-info">
																Manager Request Change remark:{" "}
																{loadedmasterinvoice.managerrejectedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financeapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Finance Approved by {loadedmasterinvoice.financeapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.financeapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financerejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Finance Rejected by {loadedmasterinvoice.financerejectedbyname}
															</p>
															<p className="text-info">
																Finance Request Change remark:{" "}
																{loadedmasterinvoice.financerejectedremark}
															</p>
														</div>
													)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Currency</th>
												<td className="align-left">
													{loadedmasterinvoice.traveltypeid === 1 && <p>USD</p>}
													{loadedmasterinvoice.traveltypeid === 2 && <p>KES</p>}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Gross Amount</th>
												<td className="align-left">{loadedmasterinvoice.amount}</td>
											</tr>

											<tr className="table-primary">
												<th className="text-primary text-lg-start">Invoice Description</th>
												<td className="align-left">{loadedmasterinvoice.description}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Attachments</th>
												<td className="align-left">
													<div className="row">
														{loadedmasterinvoice.files !== null ? (
															<div>
																{loadedmasterinvoice.files.split(",").map((fileName, index) => {
																	return (
																		<div className="col-lg-6">
																			{imageExtensions.includes(getFileExtension(fileName)) && (
																				<FileComponent fileName={fileName}/>
																			)}
																			{fileExtensions.includes(getFileExtension(fileName)) && (
																				// eslint-disable-next-line jsx-a11y/iframe-has-title

																				<FilePdfComponent fileName={fileName}/>
																			)}
																		</div>
																	);
																})}
															</div>
														) : (
															<div>No Attachments</div>
														)}
													</div>
													{watermarkcondition === "rejected" && (
														<div className="inputborder bg-white m-3">
															<div className="row">
																<div className="col-lg-4"></div>
																<div className="col-lg-4">
																	<button
																		className="btn btn-primary m-1 col-12 mx-auto"
																		onClick={openModal}
																	>
																		Edit Files
																	</button>
																</div>
																<div className="col-lg-4"></div>
															</div>
														</div>
													)}
												</td>
											</tr>
										</tbody>
									</table>

									<h3 className="text-center text-primary">Entries</h3>
									<table className="table table-striped table-bordered table-responsive">
										<thead>
											<tr className="table-primary">
												<th>Payment For:</th>
												<th>No. of days/lunch/dinner</th>
												{loadedmasterinvoice.traveltypeid === 2 && <th>City Visited</th>}
												<th>Gross Amount</th>
											</tr>
										</thead>
										<tbody>
											{loadedinvoices.map((option: any) => (
												<tr key={option.invoiceid} className="table-primary">
													<td>{option.perdiemtype}</td>
													<td>{option.days}</td>
													{loadedmasterinvoice && loadedmasterinvoice.traveltypeid === 2 && (
														<td>{option.city}</td>
													)}
													<td>
														{option.currency}:{option.grossamount}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{renderWatermark()}
								</div>
							) : (
								<div>
									<table className="table table-striped table-bordered table-responsive">
										<thead></thead>
										<tbody>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised By</th>
												<td className="align-left">{loadedmasterinvoice.raisedby}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised On</th>
												<td className="align-left">
													{DateConverter(loadedmasterinvoice.raisedon)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised At</th>
												<td className="align-left">
													{TimeConverter(loadedmasterinvoice.raisedat)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Status</th>
												<td className="align-left ">
													{loadedmasterinvoice.managerapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Manager Approved by {loadedmasterinvoice.managerapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.managerapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.managerrejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Manager Manager Requesting Changes:{" "}
																{loadedmasterinvoice.managerrejectedbyname}
															</p>
															<p className="text-info">
																Manager Request Change remark:{" "}
																{loadedmasterinvoice.managerrejectedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financeapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Finance Approved by {loadedmasterinvoice.financeapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.financeapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financerejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Finance Rejected by {loadedmasterinvoice.financerejectedbyname}
															</p>
															<p className="text-info">
																Finance Request Change remark:{" "}
																{loadedmasterinvoice.financerejectedremark}
															</p>
														</div>
													)}
												</td>
											</tr>
											{loadedmasterinvoice.invoicetypeid === 1 && (
												<tr className="table-primary">
													<th className="text-primary text-lg-start">No. Of KMS</th>
													<td className="align-left">{loadedinvoices[0].kms}</td>
												</tr>
											)}
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Currency</th>
												<td className="align-left">KES</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Gross Amount</th>
												<td className="align-left">{loadedmasterinvoice.amount}</td>
											</tr>

											<tr className="table-primary">
												<th className="text-primary text-lg-start">Invoice Description</th>
												<td className="align-left">{loadedmasterinvoice.description}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Attachments</th>
												<td className="align-left">
													<div className="row">
														{loadedmasterinvoice.files !== null ? (
															<div>
																{loadedmasterinvoice.files.split(",").map((fileName, index) => {
																	return (
																		<div className="col-lg-6">
																			{imageExtensions.includes(getFileExtension(fileName)) && (
																				<FileComponent fileName={fileName}/>
																			)}
																			{fileExtensions.includes(getFileExtension(fileName)) && (
																				// eslint-disable-next-line jsx-a11y/iframe-has-title

																				<FilePdfComponent fileName={fileName}/>
																			)}
																		</div>
																	);
																})}
															</div>
														) : (
															<div>No Attachments</div>
														)}
													</div>
													<div className="inputborder bg-white m-3">
														<div className="row">
															<div className="col-lg-4"></div>
															<div className="col-lg-4">
																<button
																	className="btn btn-primary m-1 col-12 mx-auto"
																	onClick={openModal}
																>
																	Edit Files
																</button>
															</div>
															<div className="col-lg-4"></div>
														</div>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
									{renderWatermark()}
								</div>
							)}
						</div>
					</section>
					<div className="align-centre">
						<Link className="btn btn-success" to={"/invoicetracking"}>
							Back To Invoices
						</Link>
					</div>

					{/* edit files */}
					<div>
						<Modal open={modalIsOpen} onClose={closeModal} center>
							<div className="courierBorder">
								<div className="p-5">
									<h2 className="text-primary text-center">Raise Claim</h2>
									<p className="text-success">
										----------------------------------------------------Files
										----------------------------------------------------
									</p>
									<form onSubmit={UpdateFilesAws}>
										<div className="form-group p-3">
											<label htmlFor="file">Files:</label>
											<div className="row align-centre">
												<p className="text-success text-center">
													------------------Current Files------------------
												</p>
												{loadedmasterinvoice.files !== null && (
													<div>
														{loadedmasterinvoice.files.split(",").map((fileName, index) => {
															return (
																<div className="col-lg-6 align-center">
																	{imageExtensions.includes(getFileExtension(fileName)) && (
																		<FileComponent fileName={fileName}/>
																	)}
																	{fileExtensions.includes(getFileExtension(fileName)) && (
																		// eslint-disable-next-line jsx-a11y/iframe-has-title

																		<FilePdfComponent fileName={fileName}/>
																	)}
																	<button
																		type="button"
																		className="btn btn-outline-danger remove-button"
																		onClick={() => EditFileStringRemove(fileName)}
																	>
																		Remove File
																	</button>
																</div>
															);
														})}
													</div>
												)}
											</div>
											{files.length > 0 && (
												<div>
													<p className="text-danger text-center">
														---------------new files--------------------
													</p>
													<div className="dropzone">
														<div className="row">
															{files.map((file, index) => (
																<div className="col-lg-6">
																	<div className="dropzoneimage">
																		{imageExtensions.includes(getFileExtension(file.name)) && (
																			<img
																				src={URL.createObjectURL(file)}
																				alt="Uploaded file"
																				className="img-fluid"
																			/>
																		)}
																		{fileExtensions.includes(getFileExtension(file.name)) && (
																			// eslint-disable-next-line jsx-a11y/iframe-has-title
																			<div className="iframe-container">
																				<iframe
																					key={index}
																					src={URL.createObjectURL(file)}
																					className=" responsive-iframe"
																				/>
																			</div>
																		)}
																		<div>
																			<button
																				type="button"
																				className="btn btn-outline-danger remove-button"
																				onClick={() => handleFileDrop(index)}
																			>
																				Remove File
																			</button>
																		</div>
																	</div>
																</div>
															))}
														</div>
													</div>
												</div>
											)}
											<div {...getRootProps()} className="dropzone">
												Click to upload a file
												<input {...getInputProps()} className="form-control" multiple />
											</div>
										</div>
										<div className="row">
											<div className="col-lg-4"></div>
											<div className="col-lg-4">
												<button className="btn btn-primary col-12 mx-auto" type="submit">
													Save Changes
												</button>
											</div>
											<div className="col-lg-4"></div>
										</div>
									</form>
								</div>
							</div>
						</Modal>
					</div>
					{/* end of edit files */}
				</div>
			) : (
				<div className="align-centre">
					<h5 className="text-center">Loading invoices, please wait...</h5>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default InvoiceMasterTracking;
