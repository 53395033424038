import React from "react";
import {
	getFileExtension,
	imageExtensions,
	invoiceFileExtensions,
} from "@/constants";
import { FaFile, FaFileExcel, FaFilePdf, FaFileWord } from "react-icons/fa";
import { Button } from "../ui/button";
import { IoCloseSharp } from "react-icons/io5";

export type FilePreview = {
	filename: string;
	preview: string;
};

type AttachmentPreviewProps = {
	files: FilePreview[];
	deleteFile: (index: number) => void;
	disabled?: boolean;
};

const AttachmentPreview: React.FC<AttachmentPreviewProps> = ({
	files,
	deleteFile,
	disabled,
}) => {
	return (
		<div className="flex flex-col gap-4 py-4">
			{files.map((file, index) => (
				<div key={index} className="flex items-center gap-2">
					{imageExtensions.includes(getFileExtension(file.filename)) ? (
						<img
							src={file.preview}
							alt={file.filename}
							className="w-16 h-16 object-cover rounded-md"
						/>
					// eslint-disable-next-line eqeqeq
					) : getFileExtension(file.filename) == "pdf" ? (
						<FaFilePdf className="text-2xl text-red-700" />
					) : invoiceFileExtensions.includes(getFileExtension(file.filename)) ? (
						<FaFileExcel className="text-2xl text-green-700" />
					// eslint-disable-next-line eqeqeq
					) : getFileExtension(file.filename) == "docx" || "doc" ? (
						<FaFileWord className="text-2xl text-blue-700" />
					) : (
						<FaFile className="text-2xl text-red-700" />
					)}
					<p className="font-medium">{file.filename}</p>
					<Button
						onClick={() => deleteFile(index)}
						className="ml-auto shrink-0"
						variant="outline"
						size="icon"
						disabled={disabled}
						type="button"
					>
						<IoCloseSharp className="text-lg text-red-500" />
					</Button>
				</div>
			))}
		</div>
	);
};

export default AttachmentPreview;
