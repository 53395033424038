/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useEffect, useState } from "react";
import InvoiceContext from "../../context/Invoice/InvoiceContext";
import { useLocation } from "react-router-dom";
import Spinner from "../layout/Spinner";
import AlertContext from "../../context/alert/AlertContext";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import Alert from "../layout/Alert";
import { Typeahead } from "react-bootstrap-typeahead";
import {
	DateConverter,
	getFileExtension,
	imageExtensions,
	fileExtensions,
	TimeConverter,
	getCountryData,
} from "../../constants";
import { Invoice, PaymentMethod } from "../../context/Invoice/InvoiceState";
import FileComponent from "../layout/FileComponent";
import FilePdfComponent from "../layout/FilePdfComponent";

const CashAllocation = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const invoicemasterid = searchParams.get("invoicemasterid");
	let invoicecontext = useContext(InvoiceContext);
	const alertContext = useContext(AlertContext);
	const [visible, setVisible] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod[]>([]);
	const { register, handleSubmit, reset } = useForm();

	const {
		getinvoicemastertrackingdetail,
		getInvoiceMasterTrackingDetail,
		getinvoicemastersingle,
		getInvoiceMasterSingle,
		CashOfficeCollection,
		cashofficecollection,
		invoiceerror,
		clearInvoiceErrors,
		getPaymentMethods,
		getpaymentmethods,
	} = invoicecontext;
	// const { setAlert } = alertContext;

	useEffect(() => {
		// loadToken()
		getPaymentMethods();
		getInvoiceMasterTrackingDetail(invoicemasterid);
		getInvoiceMasterSingle(invoicemasterid);

		if (cashofficecollection) {
			if (alertContext)
				alertContext.setAlert("Invoice has been collected", "success");
			setVisible(false);
			reset();
			clearInvoiceErrors();
		}

		if (invoiceerror) {
			if (alertContext) alertContext.setAlert("Error,contact admin", "danger");
			// setAlert("error,contact admin", "success");
			setVisible(false);
		}

		// eslint-disable-next-line
	}, [cashofficecollection, reset, invoiceerror]);

	let loadedinvoices = [];
	if (getinvoicemastertrackingdetail) {
		loadedinvoices = getinvoicemastertrackingdetail;
	} else {
		loadedinvoices = [];
	}

	let loadedmasterinvoice: Invoice | null = null;
	if (getinvoicemastersingle) {
		loadedmasterinvoice = getinvoicemastersingle;
	} else {
		loadedmasterinvoice = null;
	}

	let loadedpaymentmethods = [];
	if (getpaymentmethods) {
		loadedpaymentmethods = getpaymentmethods;
	} else {
		loadedpaymentmethods = [];
	}

	let watermarkcondition = "";
	if (loadedmasterinvoice) {
		if (
			loadedmasterinvoice.financeapproved &&
			loadedmasterinvoice.managerapproved
		) {
			watermarkcondition = "approved";
		} else if (
			loadedmasterinvoice.financerejected ||
			loadedmasterinvoice.managerrejected
		) {
			watermarkcondition = "rejected";
		} else if (
			loadedmasterinvoice.financerejected &&
			loadedmasterinvoice.managerapproved
		) {
			watermarkcondition = "rejected";
		}
	}

	const renderWatermark = () => {
		if (watermarkcondition === "approved") {
			return <div className="watermarkapproved">Approved</div>;
		} else if (watermarkcondition === "rejected") {
			return <div className="watermarkrejected">Change Request</div>;
		} else {
			return <div className="watermark">In Progress</div>;
		}
	};

	const finishCollection = (data: any) => {
		// e.preventDefault()
		let collectedby = data.collectedby;
		let amountcollected = data.amountcollected;
		let paymentmethod = paymentMethod[0].paymentmethodid;
		CashOfficeCollection({
			collectedby,
			amountcollected,
			invoicemasterid,
			paymentmethod,
		});
		setVisible(true);
	};

	return (
		<div>
			{getinvoicemastertrackingdetail &&
			getinvoicemastersingle &&
			loadedmasterinvoice ? (
				<div>
					{/* {keys.map((option) => ( */}
					<section>
						<div className="row">
							<div className="col-lg-4"></div>
							<div className="col-lg-4">
								<h1 className="text-center">
									Document No. {loadedmasterinvoice.invoicemasterid}
								</h1>

								<h2 className="text-center text-primary">
									{" "}
									<u>{loadedmasterinvoice.invoicetype}</u>
								</h2>
							</div>
							<div className="col-lg-4"></div>
						</div>
						<div>
							{loadedmasterinvoice.invoicetypeid === 6 ? (
								<div>
									<table className="table table-striped table-bordered table-responsive">
										{/* <Thead></Thead> */}
										<tbody>
											{/* <tr className="text-center">General Information</tr> */}
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised By</th>
												<td className="align-left">{loadedmasterinvoice.raisedby}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary">Job Grade</th>
												<td>{loadedmasterinvoice.jobgradedescription}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised On</th>
												<td className="align-left">
													{DateConverter(loadedmasterinvoice.raisedon)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised At</th>
												<td className="align-left">
													{TimeConverter(loadedmasterinvoice.raisedat)}
												</td>
											</tr>

											{loadedmasterinvoice.traveltypeid === 1 && (
												<tr className="table-primary">
													<th className="text-primary text-lg-start">Country Of Visit</th>
													<td className="align-left">
														<div className="row">
															<div className="col-lg-2">
																<p>Country Name: {loadedmasterinvoice.country}</p>
																<p>Continent Name: {loadedmasterinvoice.continent}</p>
															</div>
															<div className="col-lg">
																<p
																	className={` fi fi-${getCountryData(
																		loadedmasterinvoice.country
																	)!.flag.toLowerCase()}`}
																	style={{ fontSize: "4rem" }}
																></p>
															</div>
														</div>
													</td>
												</tr>
											)}
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Status</th>
												<td className="align-left">
													{loadedmasterinvoice.managerapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Manager Approved by {loadedmasterinvoice.managerapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.managerapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.managerrejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Manager Rejected by {loadedmasterinvoice.managerrejectedbyname}
															</p>
															<p className="text-info">
																Manager Request Change remark:{" "}
																{loadedmasterinvoice.managerrejectedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financeapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Finance Approved by {loadedmasterinvoice.financeapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.financeapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financerejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Finance Rejected by {loadedmasterinvoice.financerejectedbyname}
															</p>
															<p className="text-info">
																Finance Request Change remark:{" "}
																{loadedmasterinvoice.financerejectedremark}
															</p>
														</div>
													)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Currency</th>
												<td className="align-left">
													{loadedmasterinvoice.traveltypeid === 1 && <p>USD</p>}
													{loadedmasterinvoice.traveltypeid === 2 && <p>KES</p>}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Gross Amount</th>
												<td className="align-left">{loadedmasterinvoice.amount}</td>
											</tr>

											<tr className="table-primary">
												<th className="text-primary text-lg-start">Invoice Description</th>
												<td className="align-left">{loadedmasterinvoice.description}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Attachments</th>
												<td className="align-left">
													<div className="row">
														{loadedmasterinvoice.files !== null ? (
															<div>
																{loadedmasterinvoice.files.split(",").map((fileName, index) => {
																	return (
																		<div className="col-lg-6">
																			{imageExtensions.includes(getFileExtension(fileName)) && (
																				<FileComponent fileName={fileName}/>
																			)}
																			{fileExtensions.includes(getFileExtension(fileName)) && (
																				// eslint-disable-next-line jsx-a11y/iframe-has-title

																				<FilePdfComponent fileName={fileName}/>
																			)}
																		</div>
																	);
																})}
															</div>
														) : (
															<div>No Attachments</div>
														)}
													</div>
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Payment Method</th>
												<td className="align-left">
													{loadedmasterinvoice.paymentmethoddes}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Mpesa Number</th>
												<td className="align-left">{loadedmasterinvoice.phone}</td>
											</tr>
										</tbody>
									</table>
									<h3 className="text-center text-primary">Entries</h3>
									<table className="table table-striped table-bordered table-responsive">
										<thead>
											<tr className="table-primary">
												<th>Payment For:</th>
												<th>No. of days/lunch/dinner</th>
												{loadedmasterinvoice.traveltypeid === 2 && <th>City Visited</th>}
												<th>Gross Amount</th>
											</tr>
										</thead>
										<tbody>
											{loadedinvoices.map((option: any) => (
												<tr key={option.invoiceid} className="table-primary">
													<td>{option.perdiemtype}</td>
													<td>{option.days}</td>
													{loadedmasterinvoice && loadedmasterinvoice.traveltypeid === 2 && (
														<td>{option.city}</td>
													)}
													<td>
														{option.currency}:{option.grossamount}
													</td>
												</tr>
											))}
										</tbody>
									</table>
									{renderWatermark()}
								</div>
							) : (
								<div>
									<table className="table table-striped table-bordered table-responsive">
										<thead></thead>
										<tbody>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised By</th>
												<td className="align-left">{loadedmasterinvoice.raisedby}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised On</th>
												<td className="align-left">
													{DateConverter(loadedmasterinvoice.raisedon)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Raised At</th>
												<td className="align-left">
													{TimeConverter(loadedmasterinvoice.raisedat)}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Status</th>
												<td className="align-left">
													{loadedmasterinvoice.managerapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Manager Approved by {loadedmasterinvoice.managerapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.managerapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.managerrejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Manager Rejected by {loadedmasterinvoice.managerrejectedbyname}
															</p>
															<p className="text-info">
																Manager Request Change remark:{" "}
																{loadedmasterinvoice.managerrejectedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financeapproved && (
														<div className="inputborder bg-white m-1">
															<p className="text-success">
																Finance Approved by {loadedmasterinvoice.financeapprovedbyname}
															</p>
															<p className="text-success">
																Manager Approval remark:{" "}
																{loadedmasterinvoice.financeapprovedremark}
															</p>
														</div>
													)}
													{loadedmasterinvoice.financerejected && (
														<div className="inputborder bg-white m-1">
															<p className="text-info">
																Finance Rejected by {loadedmasterinvoice.financerejectedbyname}
															</p>
															<p className="text-info">
																Finance Request Change remark:{" "}
																{loadedmasterinvoice.financerejectedremark}
															</p>
														</div>
													)}
												</td>
											</tr>
											{loadedmasterinvoice.invoicetypeid === 1 && (
												<tr className="table-primary">
													<th className="text-primary text-lg-start">No. Of KMS</th>
													<td className="align-left">{loadedinvoices[0].kms}</td>
												</tr>
											)}
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Currency</th>
												<td className="align-left">KES</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Gross Amount</th>
												<td className="align-left">{loadedmasterinvoice.amount}</td>
											</tr>

											<tr className="table-primary">
												<th className="text-primary text-lg-start">Invoice Description</th>
												<td className="align-left">{loadedmasterinvoice.description}</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Attachments</th>
												<td className="align-left">
													<div className="row">
														{loadedmasterinvoice.files !== null ? (
															<div>
																{loadedmasterinvoice.files.split(",").map((fileName, index) => {
																	return (
																		<div className="col-lg-6">
																			{imageExtensions.includes(getFileExtension(fileName)) && (
																				<FileComponent fileName={fileName}/>
																			)}
																			{fileExtensions.includes(getFileExtension(fileName)) && (
																				// eslint-disable-next-line jsx-a11y/iframe-has-title

																				<div className="iframe-container">
																					<FilePdfComponent fileName={fileName}/>
																				</div>
																			)}
																		</div>
																	);
																})}
															</div>
														) : (
															<div>No Attachments</div>
														)}
													</div>
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Payment Method</th>
												<td className="align-left">
													{loadedmasterinvoice.paymentmethoddes}
												</td>
											</tr>
											<tr className="table-primary">
												<th className="text-primary text-lg-start">Mpesa Number</th>
												<td className="align-left">{loadedmasterinvoice.phone}</td>
											</tr>
										</tbody>
									</table>
									{renderWatermark()}
								</div>
							)}
						</div>
					</section>

					{loadedmasterinvoice.collected === true ? (
						<div className="row mb-3">
							<div className="col-lg-3"></div>
							<div className="col-lg-6">
								<div className="p-2 bg-gradient bg-info border border-info text-white">
									The invoice has been collected by {loadedmasterinvoice.collectedby}.
								</div>
							</div>
							<div className="col-lg-3"></div>
						</div>
					) : (
						<div>
							<hr />
							<h2 className="text-primary text-center">Finish Collection</h2>
							<Alert />
							<div className="row">
								<div className="col-lg-4"></div>
								<div className="col-lg-4">
									{visible ? (
										<div className="align-centre">
											<h5 className="text-center">
												Completing invoice no.{getinvoicemastersingle.invoicemasterid}
												...
											</h5>
											<Spinner />
										</div>
									) : (
										<div></div>
									)}
								</div>
								<div className="col-lg-4"></div>
							</div>
							<form onSubmit={handleSubmit(finishCollection)}>
								<div className="row">
									<div className="col-lg-3"></div>
									<div className="col-lg-6">
										<div className="form-group p-2">
											<label htmlFor="">Amount Collected</label>
											<input
												className="form-control inputborder"
												type="number"
												// name="amountcollected"
												{...register("amountcollected", {
													required: true,
												})}
											/>
										</div>
										<div className="form-group p-2">
											<label htmlFor="">Payment Type</label>
											{loadedpaymentmethods.length !== 0 && (
												<Typeahead
													id="basic-typeahead-single"
													labelKey="paymentmethoddes"
													onChange={setPaymentMethod as any}
													options={loadedpaymentmethods}
													placeholder="Select payment method"
													selected={paymentMethod}
													className="form-control"
													// required
													size="lg"
													inputProps={{ required: true, style: {} }}
												/>
											)}
										</div>
										<div className="form-group p-2">
											<label htmlFor="">Collected By(Name)</label>
											<input
												className="form-control inputborder"
												type="text"
												// name="collectedby"
												{...register("collectedby", {
													required: true,
												})}
											/>
										</div>
										<div className="form-group p-2">
											<button className="btn btn-primary col-12 mx-auto">Submit</button>
										</div>
									</div>
									<div className="col-lg-3"></div>
								</div>
							</form>
						</div>
					)}

					<div className="align-centre">
						<Link className="btn btn-success" to={"/cashoffice"}>
							Back To Invoices
						</Link>
					</div>
				</div>
			) : (
				<div className="align-centre">
					<h5 className="text-center">Loading invoices, please wait...</h5>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default CashAllocation;
