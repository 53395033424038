import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaCheck, FaCheckDouble, FaXmark } from "react-icons/fa6";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	ApprovalStatus,
	FinanceRemark,
	FinanceRemarkInvoice,
	getCostCentres,
	getGlAccounts,
	getPaymentMethods,
} from "@/services/invoice-functions";
import { toast } from "sonner";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useNavigate } from "react-router-dom";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { Check, ChevronsUpDown } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import { cn } from "@/lib/utils";
import { STALE_TIME } from "@/constants";
import { getSystemUsers } from "@/services/auth-functions";
import { IoSend } from "react-icons/io5";

const formSchema = z.object({
	remarks: z.string().min(1, {
		message: "Remarks are required",
	}),
});

const acceptFormSchema = z.object({
	glaccountid: z.number(),
	costcentreid: z.number(),
	paymentmethodid: z.number(),
	remarks: z.string().min(1, {
		message: "Remarks are required",
	}),
	managerid: z.number().optional(),
});

const departmentalFormSchema = z.object({
	managerid: z.number(),
});

const FinanceApproveOrReject: React.FC<{
	invoicemasterid: number;
	country: string | null;
	accessToken: string;
}> = ({ invoicemasterid, accessToken, country }) => {
	const [open, setOpen] = useState(false);
	const [glOpen, setGlOpen] = useState(false);
	const [suOpen, setSuOpen] = useState(false);
	const [costCentreOpen, setCostCentreOpen] = useState(false);
	const [approvalStatus, setApprovalStatus] =
		useState<ApprovalStatus>("Approved");

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const glAccounts = useQuery({
		queryKey: ["glAccounts"],
		queryFn: () => getGlAccounts(accessToken),
		enabled: approvalStatus === "Approved",
		staleTime: STALE_TIME,
	});

	const costCentres = useQuery({
		queryKey: ["costCentres"],
		queryFn: () => getCostCentres(accessToken),
		enabled: approvalStatus === "Approved",
		staleTime: STALE_TIME,
	});

	const paymentMethods = useQuery({
		queryKey: ["paymentMethods"],
		queryFn: () => getPaymentMethods(accessToken),
		enabled: approvalStatus === "Approved",
		staleTime: STALE_TIME,
	});

	const systemUsers = useQuery({
		queryKey: ["systemusers"],
		queryFn: () => getSystemUsers(accessToken),
		enabled: approvalStatus === "Secondary" || approvalStatus === "Departmental",
		staleTime: STALE_TIME,
	});

	const approveInvoice = useMutation({
		mutationKey: ["approveInvoiceManager", invoicemasterid],
		mutationFn: (remark: FinanceRemark) =>
			FinanceRemarkInvoice(accessToken, remark, approvalStatus),
		onSuccess: (response: { message: string }) => {
			queryClient.invalidateQueries(["invoice"]);
			toast.success("Claim Updated", {
				description: response.message,
			});
			setOpen(false);
			form.reset();
			acceptForm.reset();
			approveInvoice.reset();
			navigate("/financeapprovelist");
		},
		onError: () => {
			toast.error("Error submitting claim");
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			remarks: "",
		},
	});

	const acceptForm = useForm<z.infer<typeof acceptFormSchema>>({
		resolver: zodResolver(acceptFormSchema),
		defaultValues: {
			remarks: "",
		},
	});

	const departmentalForm = useForm<z.infer<typeof departmentalFormSchema>>({
		resolver: zodResolver(departmentalFormSchema),
		defaultValues: {
			managerid: undefined,
		},
	});

	const onSubmitReject = (data: z.infer<typeof formSchema>) => {
		if (approvalStatus === "Amend") {
			approveInvoice.mutate({
				financeamendnote: data.remarks,
				invoicemasterid,
			});
			return;
		}
		approveInvoice.mutate({
			financerejectedremark: data.remarks,
			invoicemasterid,
		});
	};

	const onSubmitAccept = (data: z.infer<typeof acceptFormSchema>) => {
		if (approvalStatus === "Secondary") {
			if (data.managerid === undefined) {
				acceptForm.setError("managerid", { message: "Manager is required" });
				return;
			}
			approveInvoice.mutate({
				financeapprovedremark: data.remarks,
				glaccountid: data.glaccountid,
				costcentreid: data.costcentreid,
				paymentmethod: data.paymentmethodid,
				invoicemasterid,
				secondaryfinancetobeapprovedby: data.managerid,
			});
			return;
		}
		approveInvoice.mutate({
			financeapprovedremark: data.remarks,
			glaccountid: data.glaccountid,
			costcentreid: data.costcentreid,
			paymentmethod: data.paymentmethodid,
			invoicemasterid,
		});
	};

	const onSubmitDepartmental = (
		data: z.infer<typeof departmentalFormSchema>
	) => {
		approveInvoice.mutate({
			invoicemasterid,
			secondarymanagertobeapprovedby: data.managerid,
		});
	};

	const disabled = () => {
		if (approveInvoice.isLoading) return true;
		if (approveInvoice.isSuccess) return true;
		return false;
	};

	useEffect(() => {
		if (!open && acceptForm.getValues("managerid")) {
			acceptForm.setValue("managerid", undefined);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<div className="flex gap-2">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button>Approve</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="w-88">
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Approved")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start  text-green-600 hover:text-green-600"
								>
									<FaCheck className="mr-2 h-4 w-4" />
									Approve Claim
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Secondary")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start"
								>
									<FaCheckDouble className="mr-2 h-4 w-4" />
									Request for Secondary Finance Approval
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Departmental")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start"
								>
									<IoSend className="mr-2 h-4 w-4" />
									Request for Secondary Departmental Approval
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="secondary">Review/Reject</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="w-56">
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Amend")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start"
								>
									<HiOutlinePencilAlt className="mr-2 h-4 w-4" />
									Request for Review
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Rejected")}
									variant="ghost"
									className="h-auto py-1 text-red-500 hover:text-red-500 w-full justify-start"
								>
									<FaXmark className="mr-2 h-4 w-4" />
									Reject Claim
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					{approvalStatus === "Approved" ? (
						<>
							<DialogTitle className="text-green-500 flex gap-2 items-center mb-1">
								<div className="bg-green-200 p-1 rounded-full">
									<FaCheck className="text-xl" />
								</div>
								Approving Claim
							</DialogTitle>
							<DialogDescription> Add your remarks here.</DialogDescription>
						</>
					) : approvalStatus === "Secondary" ? (
						<>
							<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
								<div className="bg-purple-200 p-1 rounded-full">
									<FaCheckDouble className="text-xl" />
								</div>
								Request for Secondary Finance Approval
							</DialogTitle>
							<DialogDescription>
								By submitting this request, you are approving the claim and forwarding
								it to the next approver set below for final approval before it proceeds
								to cash collection.
							</DialogDescription>
						</>
					) : approvalStatus === "Departmental" ? (
						<>
							<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
								<div className="bg-purple-200 p-2 rounded-full">
									<IoSend className="text-lg" />
								</div>
								Request for Secondary Departmental Approval
							</DialogTitle>
							<DialogDescription>
								By submitting this request, the claim will be sent to the approver below
								for approval before it returns back to you.
							</DialogDescription>
						</>
					) : approvalStatus === "Rejected" ? (
						<>
							<DialogTitle className="text-red-500 flex gap-2 items-center mb-1">
								<div className="bg-red-200 p-1 rounded-full">
									<FaXmark className="text-xl" />
								</div>
								Rejecting Claim
							</DialogTitle>
							<DialogDescription>Add your remarks here.</DialogDescription>
						</>
					) : (
						<>
							<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
								<div className="bg-purple-200 p-1 rounded-full">
									<HiOutlinePencilAlt className="text-xl" />
								</div>
								Request for Changes
							</DialogTitle>
							<DialogDescription>Add your remarks here.</DialogDescription>
						</>
					)}
				</DialogHeader>
				{approvalStatus === "Departmental" ? (
					<Form {...departmentalForm}>
						<form onSubmit={departmentalForm.handleSubmit(onSubmitDepartmental)}>
							<div className="flex items-center space-x-2 mb-4">
								<div className="grid flex-1 gap-2">
									{systemUsers.isLoading ? (
										<span>Loading Users</span>
									) : systemUsers.data ? (
										<FormField
											name="managerid"
											render={({ field }) => (
												<FormItem className="flex flex-col">
													<FormLabel>To Be Approved By:</FormLabel>
													<Popover open={suOpen} onOpenChange={(open) => setSuOpen(open)}>
														<PopoverTrigger asChild>
															<FormControl>
																<Button
																	variant="outline"
																	role="combobox"
																	className={cn(
																		"justify-between font-normal",
																		!field.value && "text-muted-foreground"
																	)}
																	disabled={disabled()}
																>
																	{field.value
																		? systemUsers.data.find((user) => user.id === field.value)
																				?.username
																		: "Select approver"}
																	<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
																</Button>
															</FormControl>
														</PopoverTrigger>
														<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
															<Command>
																<CommandInput placeholder="Search account..." />
																<CommandList>
																	<CommandEmpty>No account found.</CommandEmpty>
																	<CommandGroup>
																		{systemUsers.data.map((item) => (
																			<CommandItem
																				value={item.username}
																				key={item.id}
																				onSelect={() => {
																					departmentalForm.setValue("managerid", item.id);
																					setSuOpen(false);
																				}}
																			>
																				<Check
																					className={cn(
																						"mr-2 h-4 w-4",
																						item.id.toString() === field.value
																							? "opacity-100"
																							: "opacity-0"
																					)}
																				/>
																				{item.username}
																			</CommandItem>
																		))}
																	</CommandGroup>
																</CommandList>
															</Command>
														</PopoverContent>
													</Popover>
													<FormMessage />
												</FormItem>
											)}
										/>
									) : null}
								</div>
							</div>

							<DialogFooter className="sm:justify-end">
								<DialogClose asChild>
									<Button type="button" variant="ghost">
										Cancel
									</Button>
								</DialogClose>
								<Button type="submit" disabled={disabled()}>
									{disabled() ? "Submitting..." : "Submit"}
								</Button>
							</DialogFooter>
						</form>
					</Form>
				) : approvalStatus === "Rejected" || approvalStatus === "Amend" ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmitReject)}>
							<div className="flex items-center space-x-2 mb-4">
								<div className="grid flex-1 gap-2">
									<FormField
										name="remarks"
										render={({ field }) => (
											<FormItem>
												<FormControl>
													<Textarea {...field} disabled={disabled()} />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
							</div>

							<DialogFooter className="sm:justify-end">
								<DialogClose asChild>
									<Button type="button" variant="ghost">
										Cancel
									</Button>
								</DialogClose>
								<Button type="submit" disabled={disabled()}>
									{disabled() ? "Submitting..." : "Submit"}
								</Button>
							</DialogFooter>
						</form>
					</Form>
				) : (
					<Form {...acceptForm}>
						<form onSubmit={acceptForm.handleSubmit(onSubmitAccept)}>
							<div className="flex items-center space-x-2 mb-4">
								<div className="grid flex-1 gap-2">
									{approvalStatus === "Secondary" ? (
										systemUsers.isLoading ? (
											<span>Loading Users</span>
										) : systemUsers.data ? (
											<FormField
												name="managerid"
												render={({ field }) => (
													<FormItem className="flex flex-col">
														<FormLabel>To Be Approved By:</FormLabel>
														<Popover open={suOpen} onOpenChange={(open) => setSuOpen(open)}>
															<PopoverTrigger asChild>
																<FormControl>
																	<Button
																		variant="outline"
																		role="combobox"
																		className={cn(
																			"justify-between font-normal",
																			!field.value && "text-muted-foreground"
																		)}
																		disabled={disabled()}
																	>
																		{field.value
																			? systemUsers.data.find((user) => user.id === field.value)
																					?.username
																			: "Select approver"}
																		<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
																	</Button>
																</FormControl>
															</PopoverTrigger>
															<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
																<Command>
																	<CommandInput placeholder="Search account..." />
																	<CommandList>
																		<CommandEmpty>No account found.</CommandEmpty>
																		<CommandGroup>
																			{systemUsers.data.map((item) => (
																				<CommandItem
																					value={item.username}
																					key={item.id}
																					onSelect={() => {
																						acceptForm.setValue("managerid", item.id);
																						setSuOpen(false);
																					}}
																				>
																					<Check
																						className={cn(
																							"mr-2 h-4 w-4",
																							item.id.toString() === field.value
																								? "opacity-100"
																								: "opacity-0"
																						)}
																					/>
																					{item.username}
																				</CommandItem>
																			))}
																		</CommandGroup>
																	</CommandList>
																</Command>
															</PopoverContent>
														</Popover>
														<FormMessage />
													</FormItem>
												)}
											/>
										) : null
									) : null}
									{glAccounts.isLoading ? (
										<span>Loading GL Accounts</span>
									) : glAccounts.data ? (
										<FormField
											name="glaccountid"
											render={({ field }) => (
												<FormItem className="flex flex-col">
													<FormLabel>GL Account</FormLabel>
													<Popover open={glOpen} onOpenChange={(open) => setGlOpen(open)}>
														<PopoverTrigger asChild>
															<FormControl>
																<Button
																	variant="outline"
																	role="combobox"
																	className={cn(
																		"justify-between font-normal",
																		!field.value && "text-muted-foreground"
																	)}
																	disabled={disabled()}
																>
																	{field.value
																		? glAccounts.data.find(
																				(account) => account.glaccountid === field.value
																		  )?.glaccountdescription
																		: "Select account"}
																	<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
																</Button>
															</FormControl>
														</PopoverTrigger>
														<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
															<Command>
																<CommandInput placeholder="Search account..." />
																<CommandList>
																	<CommandEmpty>No account found.</CommandEmpty>
																	<CommandGroup>
																		{glAccounts.data.map((item) => (
																			<CommandItem
																				value={item.glaccountdescription + item.glaccount}
																				key={item.glaccountid}
																				onSelect={() => {
																					acceptForm.setValue("glaccountid", item.glaccountid);
																					setGlOpen(false);
																				}}
																			>
																				<Check
																					className={cn(
																						"mr-2 h-4 w-4",
																						item.glaccountid.toString() === field.value
																							? "opacity-100"
																							: "opacity-0"
																					)}
																				/>
																				{item.glaccountdescription} - {item.glaccount}
																			</CommandItem>
																		))}
																	</CommandGroup>
																</CommandList>
															</Command>
														</PopoverContent>
													</Popover>
													<FormMessage />
												</FormItem>
											)}
										/>
									) : null}
									{costCentres.isLoading ? (
										<span>Loading Cost Centres</span>
									) : costCentres.data ? (
										<FormField
											name="costcentreid"
											render={({ field }) => (
												<FormItem className="flex flex-col">
													<FormLabel>Cost Centre</FormLabel>
													<Popover
														open={costCentreOpen}
														onOpenChange={(open) => setCostCentreOpen(open)}
													>
														<PopoverTrigger asChild>
															<FormControl>
																<Button
																	variant="outline"
																	role="combobox"
																	className={cn(
																		"justify-between font-normal",
																		!field.value && "text-muted-foreground"
																	)}
																	disabled={disabled()}
																>
																	{field.value
																		? costCentres.data.find(
																				(center) => center.costcentreid === field.value
																		  )?.product
																		: "Select center"}
																	<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
																</Button>
															</FormControl>
														</PopoverTrigger>
														<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
															<Command>
																<CommandInput placeholder="Search account..." />
																<CommandList>
																	<CommandEmpty>No account found.</CommandEmpty>
																	<CommandGroup>
																		{costCentres.data.map((item) => (
																			<CommandItem
																				value={item.product + item.costcentre}
																				key={item.costcentreid}
																				onSelect={() => {
																					acceptForm.setValue("costcentreid", item.costcentreid);
																					setCostCentreOpen(false);
																				}}
																			>
																				<Check
																					className={cn(
																						"mr-2 h-4 w-4",
																						item.costcentreid.toString() === field.value
																							? "opacity-100"
																							: "opacity-0"
																					)}
																				/>
																				{item.product} - {item.costcentre}
																			</CommandItem>
																		))}
																	</CommandGroup>
																</CommandList>
															</Command>
														</PopoverContent>
													</Popover>
													<FormMessage />
												</FormItem>
											)}
										/>
									) : null}
									{paymentMethods.isLoading ? (
										<span>Loading Cost Centres</span>
									) : paymentMethods.data ? (
										<FormField
											name="paymentmethodid"
											render={({ field }) => (
												<FormItem className="mb-2">
													<FormLabel>Payment Method</FormLabel>
													<FormControl>
														<Select
															onValueChange={(e) => {
																field.onChange(parseFloat(e));
															}}
															disabled={disabled()}
														>
															<SelectTrigger>
																<SelectValue placeholder="Select an option" />
															</SelectTrigger>
															<SelectContent>
																{paymentMethods.data.map((item) => (
																	<>
																		{country ? (
																			item.paymentmethodid === 2 ? (
																				""
																			) : (
																				<SelectItem value={item.paymentmethodid.toString()}>
																					{item.paymentmethoddes}
																				</SelectItem>
																			)
																		) : item.paymentmethodid !== 2 ? (
																			""
																		) : (
																			<SelectItem value={item.paymentmethodid.toString()}>
																				{item.paymentmethoddes}
																			</SelectItem>
																		)}
																	</>
																))}
															</SelectContent>
														</Select>
													</FormControl>
													<FormMessage />
												</FormItem>
											)}
										/>
									) : null}
									<FormField
										name="remarks"
										render={({ field }) => (
											<FormItem>
												<FormLabel>Remarks</FormLabel>
												<FormControl>
													<Textarea {...field} disabled={disabled()} />
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
								</div>
							</div>

							<DialogFooter className="sm:justify-end">
								<DialogClose asChild>
									<Button type="button" variant="ghost">
										Cancel
									</Button>
								</DialogClose>
								<Button type="submit" disabled={disabled()}>
									{disabled() ? "Submitting..." : "Submit"}
								</Button>
							</DialogFooter>
						</form>
					</Form>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default FinanceApproveOrReject;
