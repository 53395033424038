import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { HiMenu } from "react-icons/hi";
import MobileSidebar from "./layout/MobileSidebar";
import { useState } from "react";

export function TrialSheet() {
	const [open, setOpen] = useState(false);

	return (
		<Sheet open={open} onOpenChange={setOpen}>
			<SheetTrigger asChild className="lg:hidden">
				<button>
					<HiMenu className="text-2xl" />
				</button>
			</SheetTrigger>
			<SheetContent className="p-0" side="left">
				<MobileSidebar closeSidebar={() => setOpen(false)} />
			</SheetContent>
		</Sheet>
	);
}
