import {
	GET_MANAGER,
	GET_PROFILE,
	GET_PROFILE_PHOTO,
	GET_USER_CHECK,
	GET_USER_TOKEN,
	MANAGER_DIRECT_REPORTS,
} from "../types";
import {
	UserCheck,
	UserColleague,
	UserDepartment,
	UserManager,
} from "./UserContext";

export type UserState = {
	check: UserCheck | null;
	department: UserDepartment | null;
	manager: UserManager | null;
	directReports: UserColleague[];
	accessToken: string | null;
	profilePhoto: string | null;
};

type UserAction =
	| { type: typeof GET_USER_CHECK; payload: UserCheck }
	| { type: typeof GET_PROFILE; payload: UserDepartment | null }
	| { type: typeof GET_MANAGER; payload: UserManager | null }
	| { type: typeof MANAGER_DIRECT_REPORTS; payload: UserColleague[] }
	| { type: typeof GET_USER_TOKEN; payload: string | null }
	| { type: typeof GET_PROFILE_PHOTO; payload: string | null };

export default (state: UserState, action: UserAction) => {
	switch (action.type) {
		case GET_USER_CHECK:
			return { ...state, check: action.payload };
		case GET_USER_TOKEN:
			return { ...state, accessToken: action.payload };
		case GET_PROFILE:
			return { ...state, department: action.payload };
		case GET_MANAGER:
			return { ...state, manager: action.payload };
		case MANAGER_DIRECT_REPORTS:
			return { ...state, directReports: action.payload };
		case GET_PROFILE_PHOTO:
			return { ...state, profilePhoto: action.payload };
		default:
			return state;
	}
};
