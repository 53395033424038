import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Dashboard from "./pages/Dashboard";
import Navbar from "./layout/Navbar";
// import ProfileComponent from "./pages/ProfileComponent";
import CreateClaim from "./pages/CreateClaim";
import InvoiceTracking from "../pages/InvoiceTracking";
// import PerDiem from "./pages/PerDiem";
// import InvoiceDetail from "./pages/InvoiceDetail";
import ManagerApproveList from "./pages/ManagerApproveList";
import FinanceApproveList from "./pages/FinanceApproveList";
import ManagerApprovalOld from "./pages/ManagerApproval";
import FinanceApprovalOld from "./pages/FinanceApproval";
// import FinanceMasterApproval from "./pages/FinanceMasterApproval";
// import ManagerMasterApproval from "./pages/ManagerMasterApproval";
import InvoiceMasterTracking from "./pages/InvoiceMasterTracking";
import TablePerDiem from "./pages/TablePerDiem";
// import AllInvoices from "./Reports/AllInvoices";
import AllInvoices from "@/pages/AllInvoices";
import Users from "./pages/Users";
import ProgressInvoices from "./Reports/ProgressInvoices";
import ApprovedInvoices from "./Reports/ApprovedInvoices";
import RejectedInvoices from "./Reports/RejectedInvoices";
import InvoiceDetailGeneral from "./Reports/InvoiceDetailGeneral";
import CashOffice from "./pages/CashOffice";
import CashAllocation from "./pages/CashAllocation";
import PaidInvoices from "./Reports/PaidInvoices";
import InvoiceDetailPaid from "./Reports/InvoiceDetailPaid";
import DateSelection from "./Reports/CashOfficeReports/DateSelection";
import EditUserById from "./common/EditUserById";
import Sidebar from "./layout/Sidebar";
import Header from "./layout/Header";
import ManagerApporvals from "@/pages/ManagerApporvals";
import FinanceApprovals from "@/pages/FinanceApprovals";
import SystemUsers from "@/pages/SystemUsers";
import CashOfficeReports from "@/pages/CashOfficeReports";
import FinanceApproval from "@/pages/FinanceApproval";
import ManagerApproval from "@/pages/ManagerApproval";
import CashOfficeCollection from "@/pages/CashOfficeCollection";
import MyInvoice from "@/pages/MyInvoice";
import GeneralReport from "@/pages/GeneralReport";
import Profile from "../pages/Profile";
import ReleasePayments from "../pages/ReleasePayments";
import NotFound from "@/pages/NotFound";
import SystemUser from "@/pages/SystemUser";
import ManagerSecondaryApproval from "@/pages/ManagerSecondaryApproval";
import FinanceSecondaryApproval from "@/pages/FinanceSecondaryApproval";

const RouteComponent = () => {
	return (
		<main className="h-screen w-full">
			<Router>
				<Fragment>
					{/* <Routes>
                    <Route path="/" component={Login} />
                    </Routes>    */}
					{/* <Navbar /> */}
					<Sidebar />
					<div className="lg:ml-[300px] grow">
						<Header />
						<div className="p-4">
							<Routes>
								<Fragment>
									<Route path="/" element={<InvoiceTracking />}></Route>
									{/* <Route path="/profile" element={<ProfileComponent />}></Route> */}
									<Route path="/profile" element={<Profile />}></Route>
									<Route path="/createclaim" element={<CreateClaim />}></Route>
									{/* <Route path="/invoicetracking" element={<InvoiceTracking />}></Route> */}
									{/* <Route path="/perdiem" element={<PerDiem/>}></Route> */}
									{/* <Route path="/invoicedetail" element={<InvoiceDetail/>}></Route> */}
									<Route
										path="/managerapprovelist"
										element={<ManagerApporvals />}
									></Route>
									<Route
										path="/managersecondaryapproval/:id"
										element={<ManagerSecondaryApproval />}
									></Route>
									<Route
										path="/financeapprovelist"
										element={<FinanceApprovals />}
									></Route>
									<Route
										path="/financesecondaryapproval/:id"
										element={<FinanceSecondaryApproval />}
									></Route>
									<Route
										path="/managerapproval"
										element={<ManagerApprovalOld />}
									></Route>
									<Route
										path="/managerapproval/:id"
										element={<ManagerApproval />}
									></Route>
									<Route
										path="/financeapproval"
										element={<FinanceApprovalOld />}
									></Route>
									<Route
										path="/financeapproval/:id"
										element={<FinanceApproval />}
									></Route>
									{/* <Route path="/financeapprovalmaster" element={<FinanceMasterApproval/>}></Route> */}
									{/* <Route path="/managerapprovalmaster" element={<ManagerMasterApproval/>}></Route> */}
									<Route
										path="/invoicemastertracking"
										element={<InvoiceMasterTracking />}
									></Route>
									<Route path="/claim/:id" element={<MyInvoice />}></Route>
									<Route path="/tableperdiem" element={<TablePerDiem />}></Route>
									<Route path="/allclaims" element={<AllInvoices />}></Route>
									<Route path="/allusers" element={<SystemUsers />}></Route>
									<Route path="/allusers/:id" element={<SystemUser />}></Route>
									<Route
										path="/invoicesinprogress"
										element={<ProgressInvoices />}
									></Route>
									<Route path="/approvedinvoices" element={<ApprovedInvoices />}></Route>
									<Route path="/rejectedinvoices" element={<RejectedInvoices />}></Route>
									<Route
										path="/invoicedetailgeneral"
										element={<InvoiceDetailGeneral />}
									></Route>
									<Route path="/cashallocation" element={<CashAllocation />}></Route>
									<Route
										path="/cashcollection/:id"
										element={<CashOfficeCollection />}
									></Route>
									<Route path="/release-payments" element={<ReleasePayments />}></Route>
									<Route path="/paidclaims" element={<CashOfficeReports />}></Route>
									<Route path="/generalreport/:id" element={<GeneralReport />}></Route>
									<Route
										path="/invoicedetailpaid"
										element={<InvoiceDetailPaid />}
									></Route>
									<Route path="/cashofficereports" element={<DateSelection />}></Route>
									<Route path="/edituserbyid" element={<EditUserById />}></Route>
									<Route path="*" element={<NotFound />} />
								</Fragment>
							</Routes>
						</div>
					</div>
				</Fragment>
			</Router>
		</main>
	);
};

export default RouteComponent;
