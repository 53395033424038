import React, { useState } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { useMutation, useQueryClient } from "react-query";
import {
	SecondaryApproval,
	approveSecondaryInvoice,
} from "@/services/invoice-functions";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";

const formSchema = z.object({
	remarks: z.string().min(1, {
		message: "Remarks are required",
	}),
});

const SecondaryApproveOrReject: React.FC<{
	invoicemasterid: number;
	accessToken: string;
	isFinance?: boolean;
}> = ({ invoicemasterid, accessToken, isFinance = false }) => {
	const [open, setOpen] = useState(false);
	const [isApproved, setIsApproved] = useState(false);

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const approveInvoice = useMutation({
		mutationKey: ["approveInvoiceManager", invoicemasterid],
		mutationFn: (remark: SecondaryApproval) =>
			approveSecondaryInvoice(accessToken, remark),
		onSuccess: (response: { message: string }) => {
			queryClient.invalidateQueries(["invoice"]);
			toast.success("Remark submitted", {
				description: response.message,
			});
			setOpen(false);
			form.reset();
			isFinance
				? navigate("/financeapprovelist")
				: navigate("/managerapprovelist");
		},
		onError: () => {
			toast.error("Error submitting remark");
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			remarks: "",
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		if (isFinance) {
			approveInvoice.mutate({
				invoicemasterid,
				secondaryfinanceapprovalnote: data.remarks,
				approvestatus: isApproved ? 0 : 1,
			});
		}
		approveInvoice.mutate({
			invoicemasterid,
			secondarymanagerapprovalnote: data.remarks,
			approvestatus: isApproved ? 0 : 1,
		});
	};

	const disabled = () => {
		if (approveInvoice.isLoading) return true;
		if (approveInvoice.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<div className="flex gap-2">
				<DialogTrigger asChild>
					<Button onClick={() => setIsApproved(true)}>Approve</Button>
				</DialogTrigger>
				<DialogTrigger asChild>
					<Button onClick={() => setIsApproved(false)} variant="secondary">
						Reject
					</Button>
				</DialogTrigger>
			</div>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					{isApproved ? (
						<DialogTitle className="text-green-500 flex gap-2 items-center mb-1">
							<div className="bg-green-200 p-1 rounded-full">
								<FaCheck className="text-xl" />
							</div>
							Approving Claim
						</DialogTitle>
					) : (
						<DialogTitle className="text-red-500 flex gap-2 items-center mb-1">
							<div className="bg-red-200 p-1 rounded-full">
								<FaXmark className="text-xl" />
							</div>
							Rejecting Claim
						</DialogTitle>
					)}
					<DialogDescription>Add your remarks here.</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex items-center space-x-2 mb-4">
							<div className="grid flex-1 gap-2">
								<FormField
									name="remarks"
									render={({ field }) => (
										<FormItem>
											<FormControl>
												<Textarea {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<DialogFooter className="sm:justify-end">
							<DialogClose asChild>
								<Button type="button" variant="ghost">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit" disabled={disabled()}>
								{disabled() ? "Submitting..." : "Submit"}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default SecondaryApproveOrReject;
