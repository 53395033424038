/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaCheck, FaCheckDouble, FaXmark } from "react-icons/fa6";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	ApprovalStatus,
	FinanceRemark,
	FinanceRemarkInvoice,
	getCostCentres,
	getGlAccounts,
	getPaymentMethods,
} from "@/services/invoice-functions";
import { toast } from "sonner";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useNavigate } from "react-router-dom";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { Check, ChevronsUpDown } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import { cn } from "@/lib/utils";
import { STALE_TIME } from "@/constants";
import { getSystemUsers } from "@/services/auth-functions";
import { GoArrowUpRight } from "react-icons/go";
import { IoSend } from "react-icons/io5";

const formSchema = z.object({
	managerid: z.number().optional(),
});

const ChangeFinanceApprover: React.FC<{
	invoicemasterid: number;
	accessToken: string;
}> = ({ invoicemasterid, accessToken }) => {
	const [open, setOpen] = useState(false);
	const [suOpen, setSuOpen] = useState(false);

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const systemUsers = useQuery({
		queryKey: ["systemusers"],
		queryFn: () => getSystemUsers(accessToken),
		staleTime: STALE_TIME,
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		toast.success("Invoice Updated", {
			description: `Claim ${invoicemasterid} has been forwarded to approver`,
		});
		setOpen(false);
		form.reset();
	};

	const disabled = () => {
		// if (approveInvoice.isLoading) return true;
		// if (approveInvoice.isSuccess) return true;
		return false;
	};

	useEffect(() => {
		if (!open && form.getValues("managerid")) {
			form.setValue("managerid", undefined);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<DialogTrigger asChild>
				<Button variant="link" className="h-auto py-1 justify-start">
					Change Finance Approver
					<GoArrowUpRight className="ml-2 h-4 w-4" />
				</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
						<div className="bg-purple-200 p-1 rounded-full">
							<IoSend className="text-xl" />
						</div>
						Change Finance Approver
					</DialogTitle>
					<DialogDescription>
						By submitting this request, you are forwarding it the claim to a different
						finance approver.
					</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex items-center space-x-2 mb-4">
							<div className="grid flex-1 gap-2">
								{systemUsers.isLoading ? (
									<span>Loading Users</span>
								) : systemUsers.data ? (
									<FormField
										name="managerid"
										render={({ field }) => (
											<FormItem className="flex flex-col">
												<FormLabel>To Be Approved By:</FormLabel>
												<Popover open={suOpen} onOpenChange={(open) => setSuOpen(open)}>
													<PopoverTrigger asChild>
														<FormControl>
															<Button
																variant="outline"
																role="combobox"
																className={cn(
																	"justify-between font-normal",
																	!field.value && "text-muted-foreground"
																)}
																disabled={disabled()}
															>
																{field.value
																	? systemUsers.data.find((user) => user.id === field.value)
																			?.username
																	: "Select approver"}
																<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
															</Button>
														</FormControl>
													</PopoverTrigger>
													<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
														<Command>
															<CommandInput placeholder="Search account..." />
															<CommandList>
																<CommandEmpty>No account found.</CommandEmpty>
																<CommandGroup>
																	{systemUsers.data.map((item) => (
																		<CommandItem
																			value={item.username}
																			key={item.id}
																			onSelect={() => {
																				form.setValue("managerid", item.id);
																				setSuOpen(false);
																			}}
																		>
																			<Check
																				className={cn(
																					"mr-2 h-4 w-4",
																					item.id.toString() === field.value
																						? "opacity-100"
																						: "opacity-0"
																				)}
																			/>
																			{item.username}
																		</CommandItem>
																	))}
																</CommandGroup>
															</CommandList>
														</Command>
													</PopoverContent>
												</Popover>
												<FormMessage />
											</FormItem>
										)}
									/>
								) : null}
							</div>
						</div>
						<DialogFooter className="sm:justify-end">
							<DialogClose asChild>
								<Button type="button" variant="ghost">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit" disabled={disabled()}>
								{disabled() ? "Submitting..." : "Submit"}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ChangeFinanceApprover;
