import { useContext } from "react";
import { TrialSheet } from "../TrialSheet";
import { UserContext } from "@/context/User/UserContext";
import { NavLink } from "react-router-dom";

const Header = () => {
	const user = useContext(UserContext);

	return (
		<div className="sticky top-0 z-30 p-2 bg-zinc-100 flex justify-between lg:justify-end">
			<TrialSheet />
			<div className="flex items-center gap-2">
				{user && user.check ? (
					<NavLink to="/profile">
						{user.profilePhoto && (
							// eslint-disable-next-line jsx-a11y/alt-text
							<img src={user.profilePhoto} className="rounded-full h-8 w-8" />
						)}
					</NavLink>
				) : null}
			</div>
		</div>
	);
};

export default Header;
