import React, { useReducer } from "react";
import { v4 } from "uuid";
import AlertContext from "./AlertContext";
import AlertReducer from "./AlertReducer";
import { SET_ALERT, REMOVE_ALERT } from "../types";

const AlertState = ({ children }: { children: React.ReactNode }) => {
	const [state, dispatch] = useReducer(AlertReducer, []);

	const setAlert = (msg: string, type: string, timeout = 7000) => {
		const id = v4();
		dispatch({
			type: SET_ALERT,
			payload: { msg, type, id },
		});

		setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout);
	};

	return (
		<AlertContext.Provider
			value={{
				state,
				setAlert,
			}}
		>
			{children}
		</AlertContext.Provider>
	);
};

export default AlertState;
