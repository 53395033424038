import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { DateConverter, formatAmount, STALE_TIME_INVOICE } from "@/constants";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import CreateClaim from "@/components/dialog/CreateClaim";
import CreatePerDiemClaim from "@/components/dialog/CreatePerDiemClaim";
import InvoiceFileUpload from "@/components/dialog/InvoiceFileUpload";
import { UserContext } from "@/context/User/UserContext";
import { useQuery } from "react-query";
import { getUserInvoices, Invoice } from "@/services/invoice-functions";
import { Skeleton } from "@/components/ui/skeleton";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { BsExclamationCircleFill } from "react-icons/bs";
import UpdateUserInfo from "@/components/dialog/UpdateUserInfo";

const InvoiceTracking = () => {
  const user = useContext(UserContext);

  const userInvoices = useQuery({
    queryKey: ["invoice", "user"],
    queryFn: () => getUserInvoices(user?.accessToken!),
    enabled: !!user?.accessToken!,
    staleTime: STALE_TIME_INVOICE,
    refetchOnMount: "always",
  });

  const navigate = useNavigate();

  return (
    <>
      {user ? (
        user.check?.departmentid === null ||
        user.check?.manageremail === null ||
        user.check?.phone === null ||
		user.check?.phone.toString() === "" ? (
          <>
            <h1 className="text-4xl font-bold">My Claims</h1>
            <div className="flex justify-center">
              <div className="border rounded-md p-6 shadow-sm w-full max-w-sm flex flex-col justify-center items-center gap-2">
                <h2 className="text-2xl font-bold">Missing details</h2>
                <p className="text-sm text-muted-foreground mb-4">
                  Click the button below to fill in any missing details before
                  proceeding to create a claim.
                </p>
                <div className="grid items-center justify-center">
                  <UpdateUserInfo user={user} accessToken={user.accessToken!} />
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col md:flex-row justify-between gap-2 items-center mb-3">
              <h1 className="text-4xl font-bold">My Claims</h1>
              <div className="flex gap-2">
                <CreateClaim />
                <CreatePerDiemClaim />
                {(user?.check?.usertypeid === 5 ||
                  user?.check?.usertypeid === 34 ||
                  user?.check?.usertypeid === 3) && (
                  <InvoiceFileUpload accessToken={user?.accessToken!} />
                )}
              </div>
            </div>
            <Table className="border">
              <TableHeader className="">
                <TableRow>
                  <TableHead>Claim No.</TableHead>
                  <TableHead>Amount</TableHead>
                  <TableHead>Description</TableHead>
                  <TableHead className="hidden xl:table-cell">
                    Claim Type
                  </TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead className="hidden xl:table-cell">
                    Raised On
                  </TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {userInvoices.isLoading ? (
                  Array.from({ length: 6 }).map((_, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                      <TableCell>
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                      <TableCell>
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                      <TableCell>
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                      <TableCell className="hidden xl:table-cell">
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                      <TableCell>
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                      <TableCell className="hidden xl:table-cell">
                        <Skeleton className="w-full h-10" />
                      </TableCell>
                    </TableRow>
                  ))
                ) : userInvoices.isError ? (
                  <div className="align-centre">
                    <h5 className="text-center">Error loading claims.</h5>
                  </div>
                ) : userInvoices.data ? (
                  userInvoices.data.map((invoice: Invoice) => (
                    <TableRow
                      key={invoice.invoicemasterid}
                      onClick={() =>
                        navigate(`/claim/${invoice.invoicemasterid}`)
                      }
                      className={cn(
                        "cursor-pointer",
                        (invoice.manageramend || invoice.financeamend) &&
                          "bg-orange-50"
                      )}
                    >
                      <TableCell style={{ verticalAlign: "middle" }}>
                        {invoice.manageramend || invoice.financeamend ? (
                          <BsExclamationCircleFill className="inline-block mr-2 text-orange-500 h-4 w-4 mb-0.5" />
                        ) : null}
                        <span>{invoice.invoicemasterid}</span>
                      </TableCell>
                      <TableCell className="font-medium">
                        {formatAmount(
                          parseFloat(invoice.amount),
                          invoice.country ? "USD" : "Ksh"
                        )}
                      </TableCell>
                      <TableCell>{invoice.description}</TableCell>
                      <TableCell
                        style={{ verticalAlign: "middle" }}
                        className="hidden xl:table-cell"
                      >
                        {invoice.invoicetype}
                      </TableCell>
                      <TableCell
                        style={{ verticalAlign: "middle" }}
                        className="text-left"
                      >
                        {invoice.collected ? (
                          <span className="text-left text-green-600 bg-green-100 rounded text-xs px-2 py-1">
                            Cash Collected
                          </span>
                        ) : invoice.secondaryfinanceapproved === true ? (
                          <span className="text-left text-orange-600 bg-orange-100 rounded text-xs px-2 py-1">
                            Awaiting Collection
                          </span>
                        ) : invoice.secondaryfinanceapproved === false ? (
                          <span className="text-left text-red-600 bg-red-100 rounded text-xs px-2 py-1">
                            Finance Disapproved
                          </span>
                        ) : invoice.secondaryfinancetobeapprovedby ? (
                          <span className="text-left text-violet-600 bg-violet-100 rounded text-xs px-2 py-1">
                            Awaiting Finance
                          </span>
                        ) : invoice.financeapproved ? (
                          <span className="text-left text-orange-600 bg-orange-100 rounded text-xs px-2 py-1">
                            Awaiting Collection
                          </span>
                        ) : invoice.financerejected ? (
                          <span className="text-left text-red-600 bg-red-100 rounded text-xs px-2 py-1">
                            Finance Disapproved
                          </span>
                        ) : invoice.financeamend ? (
                          <span className="text-left text-orange-600 bg-orange-100 rounded text-xs px-2 py-1">
                            Changes Requested
                          </span>
                        ) : invoice.secondarymanagerapproved === true ? (
                          <span className="text-left text-purple-600 bg-purple-100 rounded text-xs px-2 py-1">
                            Awaiting Finance
                          </span>
                        ) : invoice.secondarymanagerapproved === false ? (
                          <span className="text-left text-red-600 bg-red-100 rounded text-xs px-2 py-1">
                            Manager Disapproved
                          </span>
                        ) : invoice.secondarymanagertobeapprovedby ? (
                          <span className="text-left text-violet-600 bg-violet-100 rounded text-xs px-2 py-1">
                            Awaiting Manager
                          </span>
                        ) : invoice.managerapproved ? (
                          <span className="text-left text-purple-600 bg-purple-100 rounded text-xs px-2 py-1">
                            Awaiting Finance
                          </span>
                        ) : invoice.managerrejected ? (
                          <span className="text-left text-red-600 bg-red-100 rounded text-xs px-2 py-1">
                            Manager Disapproved
                          </span>
                        ) : invoice.manageramend ? (
                          <span className="text-left text-orange-600 bg-orange-100 rounded text-xs px-2 py-1">
                            Changes Requested
                          </span>
                        ) : (
                          <span className="text-left text-violet-600 bg-violet-100 rounded text-xs px-2 py-1">
                            Awaiting Manager
                          </span>
                        )}
                      </TableCell>
                      <TableCell className="hidden xl:table-cell">
                        {DateConverter(invoice.raisedon)}
                      </TableCell>
                      <TableCell>
                        {invoice.manageramend || invoice.financeamend ? (
                          <Button variant="link" className="h-auto p-0">
                            Edit
                          </Button>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  ))
                ) : null}
              </TableBody>
            </Table>
          </>
        )
      ) : null}
    </>
  );
};

export default InvoiceTracking;
