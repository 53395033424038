import React, { useContext, useEffect } from "react";
import InvoiceContext from "../../context/Invoice/InvoiceContext";
import Spinner from "../layout/Spinner";
import { Link } from "react-router-dom";
import { DateConverter } from "../../constants";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import { Invoice } from "../../context/Invoice/InvoiceState";

const ApprovedInvoices = () => {
	let invoicecontext = useContext(InvoiceContext);

	const { getallapprovednotpaid, InvoiceMasterApprovedNotPaid } = invoicecontext;

	useEffect(() => {
		// loadToken()
		InvoiceMasterApprovedNotPaid(localStorage.startdate, localStorage.enddate);

		// eslint-disable-next-line
	}, []);

	let loadedinvoices = [];
	if (getallapprovednotpaid) {
		loadedinvoices = getallapprovednotpaid;
	} else {
		loadedinvoices = [];
	}

	return (
		<div>
			{getallapprovednotpaid !== null ? (
				<div>
					<h1 className="text-center text-primary">All Approved Invoices List</h1>
					<section className="inputborder m-1">
						<div className="">
							<Table className="table table-striped table-bordered table-responsive">
								<Thead className="">
									<Tr>
										<Th className="text-left">Invoice No.</Th>
										<Th className="text-center">
											Invoice <br /> Type
										</Th>
										<Th className="text-left">Description</Th>
										<Th className="text-left">Raised On</Th>
										<Th className="text-left">Raised By</Th>
										<Th>Actions</Th>
									</Tr>
								</Thead>
								<Tbody>
									{
										// eslint-disable-next-line array-callback-return
										loadedinvoices.map((option: Invoice) => (
											<Tr key={option.invoicemasterid}>
												<Td style={{ verticalAlign: "middle" }}>
													{option.invoicemasterid}
												</Td>
												<Td style={{ verticalAlign: "middle" }}>{option.invoicetype}</Td>
												<Td className="align-left" style={{ verticalAlign: "middle" }}>
													{option.description}
												</Td>
												<Td style={{ verticalAlign: "middle" }}>
													{DateConverter(option.raisedon)}
												</Td>
												<Td style={{ verticalAlign: "middle" }}>{option.raisedby}</Td>
												<Td style={{ verticalAlign: "middle" }}>
													<Link
														className="btn btn-primary btn-sm"
														to={{
															pathname: "/invoicedetailgeneral",
															search: `?invoicemasterid=${option.invoicemasterid}`,
														}}
													>
														Detail View
													</Link>
												</Td>
											</Tr>
										))
									}
								</Tbody>
							</Table>
						</div>
					</section>
				</div>
			) : (
				<div className="align-centre">
					<h5 className="text-center">Loading invoices, please wait...</h5>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default ApprovedInvoices;
