import { z } from "zod";

import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { useForm } from "react-hook-form";
import { useContext, useEffect, useState } from "react";
import {
	acceptedFiles,
	getS3FileUrl,
	STALE_TIME,
	getUploadedFile
} from "@/constants"; // Updated import
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	AmendInvoiceParams,
	getInvoiceTypes,
	Invoice,
	InvoiceAmend,
	InvoiceDetail,
} from "@/services/invoice-functions";
import { UserContext } from "@/context/User/UserContext";
import { toast } from "sonner";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import { MdOutlineCloudUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";

const formSchema = z.object({
	invoicetype: z.number(),
	amount: z.number().min(1, {
		message: "Amount cannot be less than 1",
	}),
	kms: z
		.number()
		.min(1, {
			message: "Kms cannot be less than 1",
		})
		.optional(),
	description: z.string().min(1, {
		message: "Description is required",
	}),
	attachment: z.array(z.instanceof(File)).min(0),
});

const allowedKms = [1, 3];

const EditClaim: React.FC<{
	invoice: Invoice;
	invoiceDetail: InvoiceDetail[];
}> = ({ invoice, invoiceDetail }) => {
	const user = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);
	const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
	const [showKms, setShowKms] = useState<boolean>(
		allowedKms.includes(invoice.invoicetypeid)
	);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const invoiceTypes = useQuery({
		queryKey: ["invoiceTypes"],
		queryFn: () => getInvoiceTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const invoiceEdit = useMutation({
		mutationKey: ["invoice_create"],
		mutationFn: (tableData: AmendInvoiceParams) =>
			InvoiceAmend(user?.accessToken!, tableData),
		onSuccess: () => {
			queryClient.invalidateQueries(["invoice"]);
			setOpen(false);
			form.reset();
			toast.success(`Invoice ${invoice.invoicemasterid} Amended`, {
				description: "Your changes have been saved.",
			});
			invoiceEdit.reset();
			setUploadedFiles([]);
			navigate("/");
		},
		onError: (err) => {
			toast.error("Error creating invoice, try again");
			console.log(err);
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			invoicetype: invoice.invoicetypeid,
			amount: parseFloat(invoice.amount),
			description: invoice.description,
			kms:
				invoiceDetail.length > 0 && invoiceDetail[0].kms
					? parseInt(invoiceDetail[0].kms.toString())
					: undefined,
			attachment: [],
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		const tableData: AmendInvoiceParams = {
			invoicemasterid: invoice.invoicemasterid,
			amounts: data.amount,
			descriptions: data.description,
			invoicetypeids: data.invoicetype,
			files: data.attachment,
			kms: data.kms,
		};
		invoiceEdit.mutate(tableData);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawFiles = Array.from(event.target.files || []);
		const files = rawFiles.filter((file) => {
			if (acceptedFiles.includes(file.type)) return true;
			toast.error("File type not supported");
			return false;
		});
		const existingFiles = form.getValues("attachment");
		const allFiles = [...existingFiles, ...files];
		form.setValue("attachment", allFiles);
		const filePreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(filePreviews);
	};

	const disableFields = () => {
		if (invoiceEdit.isLoading) return true;
		if (invoiceEdit.isSuccess) return true;
		return false;
	};

	const handlePreviousAttachments = async (files: string | null) => {
		if (!files) return;
		setIsLoadingAttachments(true);
		const splitFiles = files.split(",");
		const allFiles = form.getValues("attachment");
		const promises = splitFiles.map(async (file) => {
			const fileUrl = await getS3FileUrl(file); // Fetch URL dynamically
			const retrievedFile = await getUploadedFile(fileUrl); // Updated logic
			if (retrievedFile) allFiles.push(retrievedFile);
		});
		await Promise.all(promises);
		form.setValue("attachment", allFiles);
		const updatedPreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
		setIsLoadingAttachments(false);
	};

	const removeFile = (index: number) => {
		const allFiles = form.getValues("attachment");
		const updatedFiles = allFiles.filter((_, i) => i !== index);
		form.setValue("attachment", updatedFiles);

		const updatedPreviews = updatedFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
	};

	useEffect(() => {
		handlePreviousAttachments(invoice.files);
	}, [invoice.files]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Dialog
			open={open}
			onOpenChange={(change) => {
				setOpen(change);
				if (!change) form.reset();
			}}
		>
			<DialogTrigger asChild>
				<Button variant="default">Edit Claim</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-lg">
				<DialogHeader>
					<DialogTitle className="text-left">Edit Claim</DialogTitle>
				</DialogHeader>
				{invoiceTypes.isLoading ? (
					<>Loading Invoices</>
				) : invoiceTypes.data ? (
					<Form {...form}>
						<form onSubmit={form.handleSubmit(onSubmit)}>
							<FormField
								name="invoicetype"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Claim Type</FormLabel>
										<FormControl>
											<Select
												defaultValue={invoice.invoicetypeid.toString()}
												onValueChange={(e) => {
													field.onChange(parseFloat(e));
													if (allowedKms.includes(parseFloat(e))) {
														setShowKms(true);
														return;
													}
													setShowKms(false);
												}}
												disabled={disableFields()}
											>
												<SelectTrigger>
													<SelectValue placeholder="Select an option" />
												</SelectTrigger>
												<SelectContent>
													{invoiceTypes.data.map((item) => (
														<SelectItem value={item.invoicetypeid.toString()}>
															{item.description}
														</SelectItem>
													))}
												</SelectContent>
											</Select>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="amount"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Amount in KES</FormLabel>
										<FormControl>
											<Input
												type="number"
												defaultValue={parseFloat(invoice.amount)}
												onChange={(e) => field.onChange(e.target.valueAsNumber)}
												disabled={disableFields()}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							{showKms ? (
								<FormField
									name="kms"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Kilometres</FormLabel>
											<FormControl>
												<Input
													type="number"
													defaultValue={invoiceDetail[0]?.kms || undefined}
													onChange={(e) => field.onChange(e.target.valueAsNumber)}
													disabled={disableFields()}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							) : null}
							<FormField
								name="description"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Description</FormLabel>
										<FormControl>
											<Textarea
												defaultValue={invoice.description}
												disabled={disableFields()}
												{...field}
											/>
										</FormControl>
										<FormMessage />
									</FormItem>
								)}
							/>
							<FormField
								name="attachment"
								render={({ field }) => (
									<FormItem className="mb-2">
										<FormLabel>Attachment</FormLabel>
										<FormControl>
											<label htmlFor="fileInput">
												<div className="cursor-pointer">
													<MdOutlineCloudUpload
														className="mx-auto mb-3 h-10 w-10 text-sky-500"
														aria-hidden="true"
													/>
													<p className="text-center text-sm text-sky-500">
														Click to upload
													</p>
													<p className="text-center text-xs text-muted-foreground">
														Supports: JPG, PNG, PDF
													</p>
												</div>
											</label>
											<input
												id="fileInput"
												type="file"
												className="sr-only"
												onChange={handleFileChange}
												disabled={disableFields()}
											/>
										</FormControl>
										<FormMessage />
										<div className="mt-2 space-y-2">
											{uploadedFiles.length > 0 ? (
												uploadedFiles.map((file, index) => (
													<AttachmentPreview
														key={index}
														files={uploadedFiles}
														deleteFile={() => removeFile(index)}
													/>
												))
											) : (
												<>
													{isLoadingAttachments ? (
														<Skeleton className="h-24 w-full rounded-md" />
													) : null}
												</>
											)}
										</div>
									</FormItem>
								)}
							/>
							<Button type="submit" disabled={disableFields()}>
								Submit
							</Button>
						</form>
					</Form>
				) : null}
			</DialogContent>
		</Dialog>
	);
};

export default EditClaim;

