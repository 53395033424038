import axios from "axios";

export type Invoice = {
	invoicemasterid: number;
	amount: string;
	raisedbyid: number;
	raisedon: string;
	raisedat: string;
	files: string | null;
	jobgradeid: number | null;
	raisedby: string;
	jobgradedescription: string | null;
	fullyapproved: null;
	invoicetypeid: number;
	invoicetype: string;
	description: string;
	managerapproved: boolean;
	managerapprovedby: number;
	financeapproved: boolean;
	financeapprovedby: number;
	financeapprovedremark: string;
	financerejectedremark: string | null;
	managerapprovedremark: string;
	managerrejectedremark: string | null;
	managersecondapprovalrequired: boolean | null;
	manageramend: boolean | null;
	financeamend: boolean | null;
	manageramendat: string | null;
	financeamendat: string | null;
	manageramendby: string | null;
	financeamendby: string | null;
	manageramendon: string | null;
	financeamendon: string | null;
	manageramendnote: string | null;
	financeamendnote: string | null;
	financerejected: null;
	managerrejected: null;
	financerejectedby: number | null;
	managerrejectedby: number | null;
	managerapprovedon: string | null;
	financeapprovedon: string | null;
	managerapprovedat: string | null;
	financeapprovedat: string | null;
	managerrejectedon: string | null;
	financerejectedon: string | null;
	managerrejectedat: string | null;
	financerejectedat: string | null;
	financesecondapprovalrequired: boolean | null;
	secondarymanagerapprovalnote: string | null;
	secondarymanagerapproved: boolean | null;
	secondarymanagerapprovedat: string | null;
	secondarymanagerapprovedby: number | null;
	secondarymanagerapprovedbyname: string | null;
	secondarymanagerapprovedon: string | null;
	secondarymanagertobeapprovedby: number | null;
	secondaryfinanceapprovalnote: string | null;
	secondaryfinanceapproved: boolean | null;
	secondaryfinanceapprovedat: string | null;
	secondaryfinanceapprovedby: number | null;
	secondaryfinanceapprovedbyname: string | null;
	secondaryfinanceapprovedon: string | null;
	secondaryfinancetobeapprovedby: number | null;
	glaccountid: number;
	costcentreid: number;
	paymentmethod: number;
	managerapprovedbyname: string | null;
	financeapprovedbyname: string | null;
	financerejectedbyname: string | null;
	managerrejectedbyname: string | null;
	glaccountdescription: string;
	glaccount: number;
	costcentre: number;
	product: string;
	paymentmethoddes: string;
	continent: null;
	country: string | null;
	traveltypeid: number | null;
	traveltypedescription: string | null;
	department: string;
	hodemail: string;
	collected: boolean;
	amountcollected: string;
	collectedby: string;
	collectionfacilitator: number;
	datecollected: string;
	timecollected: string;
	phone: string;
};

export type InvoiceDetail = {
	invoiceid: number;
	amount: string;
	createdon: string;
	createdat: string;
	invoicetype: number;
	currency: string;
	days: number | null;
	grossamount: string;
	invoicemasterid: number;
	cityid: number | null;
	amountpernight: string | null;
	typeofperdiem: number | null;
	kms: number | null;
	createdby: number;
	createdbyname: string;
	city: string | null;
	perdiemtype: string | null;
};

export type InvoiceType = {
	invoicetypeid: number;
	description: string;
};

export type CreateInvoiceParams = {
	invoicetypeids: number;
	descriptions: string;
	amounts: number;
	kms?: number;
	files: File[];
};

export type AmendInvoiceParams = CreateInvoiceParams & {
	invoicemasterid: number;
};

export type CreateInvoiceParamsWithManagerApprover = CreateInvoiceParams & {
	managerapprovers: number;
};

type PerDiem = {
	description: string;
	jobgradeid: number;
	totalamount: number;
	files: File[];
	numberofnights: number[];
	typeofpayments: number[];
	amounts: number[];
};

export type InternationalPerDiem = {
	submittedcountry: string;
	submittedcontinent: string;
} & PerDiem;

export type InternationalPerDiemWithManagerApprover = InternationalPerDiem & {
	managerapprover: number;
};

export type AmendInternationalPerDiem = {
	invoicemasterid: number;
} & InternationalPerDiem;

export type LocalPerDiem = {
	cities: number[];
} & PerDiem;

export type LocalPerDiemWithManagerApprover = LocalPerDiem & {
	managerapprover: number;
};

export type AmendLocalPerDiem = {
	invoicemasterid: number;
} & LocalPerDiem;

export type PerdiemType = {
	perdiemtypeid: number;
	perdiemtype: string;
};

type ManagerRejectedRemark = {
	managerrejectedremark: string;
	invoicemasterid: number;
};

type ManagerApprovedRemark = {
	managerapprovedremark: string;
	invoicemasterid: number;
};

type ManagerSecondaryApproval = {
	managerapprovedremark: string;
	invoicemasterid: number;
	secondarymanagertobeapprovedby: number;
};

type ManagerAmendNote = {
	manageramendnote: string;
	invoicemasterid: number;
};

export type ApprovalStatus =
	| "Approved"
	| "Rejected"
	| "Amend"
	| "Secondary"
	| "Departmental";

export type ManagerRemark =
	| ManagerApprovedRemark
	| ManagerSecondaryApproval
	| ManagerRejectedRemark
	| ManagerAmendNote;

type FinanceApprovedRemark = {
	financeapprovedremark: string;
	invoicemasterid: number;
	glaccountid: number;
	costcentreid: number;
	paymentmethod: number;
};

type FinanceSecondaryApproval = {
	secondaryfinancetobeapprovedby: number;
} & FinanceApprovedRemark;

type FinanceDepartmentalApproval = {
	invoicemasterid: number;
	secondarymanagertobeapprovedby: number;
};

type FinanceRejectedRemark = {
	financerejectedremark: string;
	invoicemasterid: number;
};

type FinanceAmendNote = {
	financeamendnote: string;
	invoicemasterid: number;
};

export type FinanceRemark =
	| FinanceApprovedRemark
	| FinanceSecondaryApproval
	| FinanceDepartmentalApproval
	| FinanceRejectedRemark
	| FinanceAmendNote;

type SecondaryApprovalNote = {
	invoicemasterid: number;
	approvestatus: number;
};

type SecondaryManagerApprovalNote = {
	secondarymanagerapprovalnote: string;
} & SecondaryApprovalNote;

type SecondaryFinanceApprovalNote = {
	secondaryfinanceapprovalnote: string;
} & SecondaryApprovalNote;

export type SecondaryApproval =
	| SecondaryManagerApprovalNote
	| SecondaryFinanceApprovalNote;

export type CashCollectionParams = {
	amountcollected: string;
	collectedby: string;
	invoicemasterid: string;
	paymentmethod: number;
};

export type PaymentMethod = {
	paymentmethodid: number;
	paymentmethoddes: string;
};

export type GLAccount = {
	glaccount: number;
	glaccountdescription: string;
	glaccountid: number;
};

export type CostCentre = {
	costcentre: number;
	costcentreid: number;
	hod: string;
	product: string;
};

export type MpesaBatchPayment = {
	paymentmethod: 2;
	invoicemasterid: number[];
};

const server_url = process.env.REACT_APP_SERVER_DOMAIN;

export const getUserInvoices = async (
	accessToken: string
): Promise<Invoice[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/getinvoicemaster`, config);
	return res.data;
};

export const ManagerListInvoices = async (
	accessToken: string
): Promise<Invoice[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/managerapprovelist`, config);
	return res.data;
};

export const ManagerSecondaryListInvoices = async (
	accessToken: string
): Promise<Invoice[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getinvoicesecondarymanagerapprovallist`,
		config
	);
	return res.data;
};

export const FinanceSecondaryListInvoices = async (
	accessToken: string
): Promise<Invoice[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getinvoicesecondaryfinanceapprovallist`,
		config
	);
	return res.data;
};

export const FinanceListInvoices = async (
	accessToken: string
): Promise<Invoice[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/financeapprovelist`, config);
	return res.data;
};

export const cashOfficeInvoices = async (accessToken: string) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/getallapproved`, config);
	return res.data;
};

export const getPaidInvoices = async (
	accessToken: string,
	startdate: string,
	enddate: string
): Promise<Invoice[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getallpaid/${startdate}/${enddate}`,
		config
	);
	return res.data;
};

export const getInProgressInvoices = async (
	accessToken: string,
	startdate: string,
	enddate: string
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getallinprogress/${startdate}/${enddate}`,
		config
	);
	return res.data;
};

export const getRejectedInvoices = async (
	accessToken: string,
	startdate: string,
	enddate: string
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getallrejected/${startdate}/${enddate}`,
		config
	);
	return res.data;
};

export const getApprovedNotPaidInvoices = async (
	accessToken: string,
	startdate: string,
	enddate: string
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getallapprovednotpaid/${startdate}/${enddate}`,
		config
	);
	return res.data;
};

export const getSingleMasterInvoice = async (
	accessToken: string,
	invoicemasterid: string
): Promise<Invoice> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(
		`${server_url}invoice/getinvoicemastersingle/${invoicemasterid}`,
		config
	);
	return res.data;
};

// GET INVOICE MASTER TRACKING
export const getSingleMasterInvoiceDetail = async (
	accessToken: string,
	invoicemasterid: string
): Promise<InvoiceDetail[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(
		`${server_url}invoice/invoicetrackingmasterdetail/${invoicemasterid}`,
		config
	);

	return res.data;
};

// Get invoice types
export const getInvoiceTypes = async (
	accessToken: string
): Promise<InvoiceType[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/invoicetype`, config);
	return res.data;
};

export const getPerdiemTypes = async (
	accessToken: string
): Promise<PerdiemType[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/perdiemtypes`, config);
	return res.data;
};

export const InvoiceCreate = async (
	accessToken: string,
	tableData: CreateInvoiceParamsWithManagerApprover
): Promise<{ message: string }> => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();
	formData.append("descriptions", tableData.descriptions);
	formData.append("invoicetypeids", tableData.invoicetypeids.toString());
	formData.append("amounts", tableData.amounts.toString());
	formData.append("managerapprovers", tableData.managerapprovers.toString());
	tableData.files.forEach((file: File) => {
		formData.append("files[0][]", file);
	});
	formData.append("kms", tableData.kms ? tableData.kms.toString() : "");

	const res = await axios.post(
		`${server_url}invoice/createinvoice`,
		formData,
		config
	);

	return res.data;
};

export const InvoiceAmend = async (
	accessToken: string,
	tableData: AmendInvoiceParams
): Promise<{ message: string }> => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();

	formData.append("descriptions", tableData.descriptions);
	formData.append("invoicetypeids", tableData.invoicetypeids.toString());
	formData.append("amounts", tableData.amounts.toString());
	tableData.files.forEach((file: File) => {
		formData.append("files", file);
	});
	formData.append("kms", tableData.kms ? tableData.kms.toString() : "");

	const res = await axios.put(
		`${server_url}invoice/updateinvoice/${tableData.invoicemasterid}`,
		formData,
		config
	);

	return res.data;
};

export const createLocalPerDiem = async (
	accessToken: string,
	perd: LocalPerDiemWithManagerApprover
) => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();
	formData.append("description", perd.description);
	formData.append("jobgradeids", perd.jobgradeid.toString());
	formData.append("totalamount", perd.totalamount.toString());
	formData.append("managerapprover", perd.managerapprover.toString());
	perd.files.forEach((file) => {
		formData.append("files", file);
	});
	perd.cities.forEach((city) => {
		formData.append("cities", city.toString());
	});
	perd.numberofnights.forEach((night) => {
		formData.append("numberofnights", night.toString());
	});
	perd.typeofpayments.forEach((payment) => {
		formData.append("typeofpayments", payment.toString());
	});

	perd.amounts.forEach((amount) => {
		formData.append("amounts", amount.toString());
	});

	const res = await axios.post(
		`${server_url}invoice/createlocalperdiem`,
		formData,
		config
	);

	return res.data;
};

export const updateLocalPerDiem = async (
	accessToken: string,
	perd: AmendLocalPerDiem
) => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();
	formData.append("description", perd.description);
	formData.append("jobgradeids", perd.jobgradeid.toString());
	formData.append("totalamount", perd.totalamount.toString());
	perd.files.forEach((file) => {
		formData.append("files", file);
	});
	perd.cities.forEach((city) => {
		formData.append("cities", city.toString());
	});
	perd.numberofnights.forEach((night) => {
		formData.append("numberofnights", night.toString());
	});
	perd.typeofpayments.forEach((payment) => {
		formData.append("typeofpayments", payment.toString());
	});

	perd.amounts.forEach((amount) => {
		formData.append("amounts", amount.toString());
	});

	const res = await axios.put(
		`${server_url}invoice/updatelocalperdiem/${perd.invoicemasterid}`,
		formData,
		config
	);

	return res.data;
};

export const createInternationalPerDiem = async (
	accessToken: string,
	perd: InternationalPerDiemWithManagerApprover
) => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();
	formData.append("description", perd.description);
	formData.append("submittedcountry", perd.submittedcountry);
	formData.append("submittedcontinent", perd.submittedcontinent);
	formData.append("jobgradeids", perd.jobgradeid.toString());
	formData.append("totalamount", perd.totalamount.toString());
	formData.append("managerapprover", perd.managerapprover.toString());
	perd.files.forEach((file: any) => {
		formData.append("files", file);
	});
	perd.numberofnights.forEach((file: any) => {
		formData.append("numberofnights", file);
	});
	perd.typeofpayments.forEach((file: any) => {
		formData.append("typeofpayments", file);
	});

	perd.amounts.forEach((file: any) => {
		formData.append("amounts", file);
	});

	const res = await axios.post(
		`${server_url}invoice/createperdiem`,
		formData,
		config
	);
	return res.data;
};

export const updateInternationalPerDiem = async (
	accessToken: string,
	perd: AmendInternationalPerDiem
) => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();
	formData.append("description", perd.description);
	formData.append("submittedcountry", perd.submittedcountry);
	formData.append("submittedcontinent", perd.submittedcontinent);
	formData.append("jobgradeids", perd.jobgradeid.toString());
	formData.append("totalamount", perd.totalamount.toString());
	perd.files.forEach((file: any) => {
		formData.append("files", file);
	});
	perd.numberofnights.forEach((file: any) => {
		formData.append("numberofnights", file);
	});
	perd.typeofpayments.forEach((file: any) => {
		formData.append("typeofpayments", file);
	});

	perd.amounts.forEach((file: any) => {
		formData.append("amounts", file);
	});

	const res = await axios.put(
		`${server_url}invoice/updateinternationalperdiem/${perd.invoicemasterid}`,
		formData,
		config
	);
	return res.data;
};

export const ManagerRemarkInvoice = async (
	accessToken: string,
	remark: ManagerRemark,
	approvalStatus: ApprovalStatus
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const endpoint =
		approvalStatus === "Approved"
			? `${server_url}invoice/ManagerApprove`
			: approvalStatus === "Rejected"
			? `${server_url}invoice/ManagerReject`
			: approvalStatus === "Secondary"
			? `${server_url}invoice/managerspprovesndseeksecondaryapproval`
			: `${server_url}invoice/manageramend`;

	const res = await axios.post(endpoint, remark, config);
	return res.data;
};

export const FinanceRemarkInvoice = async (
	accessToken: string,
	remark: FinanceRemark,
	approvalStatus: ApprovalStatus
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const endpoint =
		approvalStatus === "Approved"
			? `${server_url}invoice/FinanceApprove`
			: approvalStatus === "Secondary"
			? `${server_url}invoice/financeapproveandseeksecondaryapproval`
			: approvalStatus === "Departmental"
			? `${server_url}invoice/financeseeksecondarydepartmentalapproval`
			: approvalStatus === "Rejected"
			? `${server_url}invoice/FinanceReject`
			: `${server_url}invoice/financeamend`;

	const res = await axios.post(endpoint, remark, config);
	return res.data;
};

export const approveSecondaryInvoice = async (
	accessToken: string,
	remark: SecondaryApproval
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const endpoint =
		"secondarymanagerapprovalnote" in remark
			? `${server_url}invoice/secondarymanagerapproval`
			: `${server_url}invoice/secondaryfinanceapproval`;

	const res = await axios.post(endpoint, remark, config);
	return res.data;
};

export const getPaymentMethods = async (
	accessToken: string
): Promise<PaymentMethod[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(`${server_url}invoice/getpaymentmethods`, config);
	return res.data;
};

export const getGlAccounts = async (
	accessToken: string
): Promise<GLAccount[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.get(`${server_url}invoice/getglaccounts`, config);
	return res.data;
};

export const getCostCentres = async (
	accessToken: string
): Promise<CostCentre[]> => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};
	const res = await axios.get(`${server_url}invoice/getcostcentres`, config);
	return res.data;
};

export const CashOfficeCollection = async (
	accessToken: string,
	collection: CashCollectionParams
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.post(
		`${server_url}invoice/cashcollection`,
		collection,
		config
	);

	return res.data;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function sleep(ms: number) {
	return new Promise((resolve) => setTimeout(resolve, ms));
}

export const makeBatchMpesaPayment = async (
	accessToken: string,
	payment: MpesaBatchPayment
) => {
	const headers = {
		Authorization: `Bearer ${accessToken}`,
		"Content-Type": "application/json",
	};

	const config = {
		headers: headers,
	};

	const res = await axios.post(
		`${server_url}invoice/paymentcollection`,
		payment,
		config
	);
	return res.data;
};

export const uploadInvoiceFile = async (accessToken: string, files: File[], desc: string, city: number) => {
	const config = {
		headers: {
			Authorization: `Bearer ${accessToken}`,
			"Content-Type": "multipart/form-data",
		},
	};

	const formData = new FormData();
	formData.append("masterfile", files[0]);
	formData.append("detailedfile", files[1]);
	formData.append("desc", desc);
	formData.append("city", city.toString());
	
	const res = await axios.post(
		`${server_url}invoice/uploadperdiem`,
		formData,
		config
	);
	return res.data;
};
