/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import { getS3FileUrl } from "../../constants"; // Update this to the actual path

const FilePdfComponent: React.FC<{ fileName: string }> = ({ fileName }) => {
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const url = await getS3FileUrl(fileName);
        setFileUrl(url);
      } catch (error) {
        console.error("Error fetching file URL:", error);
      }
    };

    fetchFile();
  }, [fileName]);

  return fileUrl ? (
    <div className="iframe-container">
      <iframe
        // key={index}
        src={fileUrl}
        className="dropzoneimage responsive-iframe"
      />
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default FilePdfComponent;
