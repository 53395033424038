import React, { useContext, useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import AuthContext from "../../context/Auth/AuthContext";
import AlertContext from "../../context/alert/AlertContext";
import { useLocation } from "react-router-dom";
import Spinner from "../layout/Spinner";
import Alert from "../layout/Alert";

import { useForm } from "react-hook-form";
import { DepartmentType, UserType } from "../../context/Auth/AuthState";

const EditUserById = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const userid = searchParams.get("userid");
	//   const department = searchParams.get("department");
	//   const usertype = searchParams.get("usertype");
	//   const departmentid = parseInt(searchParams.get("departmentid"));
	//   const usertypeid = parseInt(searchParams.get("usertypeid"));

	const alertContext = useContext(AlertContext);
	const authContext = useContext(AuthContext);
	const [visible, setVisible] = useState(false);

	// const { setAlert } = alertContext;

	const {handleSubmit, reset } = useForm();
	const [usertypes, setUsertypes] = useState<UserType[]>([]);
	const [departmenttype, setDepartmentType] = useState<DepartmentType[]>([]);

	const {
		getUserDetailById,
		getsingleuserdetailbyid,
		getAllDepartments,
		getalldepartments,
		getUserTypes,
		getusertypes,
		UpdateUserById,
		updateuserbyid,
		autherror,
		clearAuthErrors,
	} = authContext;

	useEffect(() => {
		getUserDetailById(userid);
		getAllDepartments();
		getUserTypes();
		// if(getsingleuserdetailbyid !== null){

		// }
		if (updateuserbyid) {
			setVisible(false);
			getUserDetailById(userid);
			reset();
			if (alertContext) alertContext.setAlert(updateuserbyid.message, "success");
			clearAuthErrors();
		}
		if (autherror) {
			setVisible(false);
			if (alertContext) alertContext.setAlert("Error, contact admin", "danger");
			clearAuthErrors();
		}
		// eslint-disable-next-line
	}, [updateuserbyid, reset, autherror]);

	let loadeddepartments = [];

	if (getalldepartments) {
		loadeddepartments = getalldepartments;
	} else {
		loadeddepartments = [];
	}

	let loadedusertype: UserType[] = [];

	if (getusertypes) {
		loadedusertype = getusertypes;
	} else {
		loadedusertype = [];
	}

	const EditUser = () => {
		if (!userid) return;
		// e.preventDefault();
		let department: number;
		let usertype: number;
		if (departmenttype.length < 1) {
			department = getsingleuserdetailbyid.departmentid;
		} else {
			department = departmenttype[0].departmentid;
		}

		if (usertypes.length < 1) {
			usertype = getsingleuserdetailbyid.usertypeid;
		} else {
			usertype = usertypes[0].usertypeid;
		}

		console.log("====================================");
		console.log(department, usertype);
		console.log("====================================");
		UpdateUserById({
			userid: parseInt(userid),
			department: department,
			usertypeid: usertype,
		});
		setVisible(true);
	};

	return (
		<div>
			{getsingleuserdetailbyid !== null &&
			getalldepartments !== null &&
			getusertypes !== null ? (
				<div>
					{/* <div>
            {(getsingleuserdetailbyid !== null && getalldepartments !== null &&  getusertypes!== null) && (
                setUsertypes([{usertype:getsingleuserdetailbyid.usertype,usertypeid:parseInt(getsingleuserdetailbyid.usertypeid)}]),
            setDepartmentType([{departmentname:getsingleuserdetailbyid.department,departmentid:getsingleuserdetailbyid.departmentid}])
            )}</div> */}

					<Alert />
					<div className="row">
						<div className="col-lg-4"></div>
						<div className="col-lg-4">
							{visible ? (
								<div className="align-centre">
									<h5 className="text-center">
										Updating.{getsingleuserdetailbyid.username}
										...
									</h5>
									<Spinner />
								</div>
							) : (
								<div></div>
							)}
						</div>
						<div className="col-lg-4"></div>
					</div>
					<div className="row">
						<div className="col-lg-2"></div>
						<div className="col-lg-8">
							<h2 className="text-center text-primary">
								{getsingleuserdetailbyid.username}
							</h2>
							<form onSubmit={handleSubmit(EditUser)}>
								<div className="form-group p-3">
									<label htmlFor="">Department Name</label>
									<br />
									<label htmlFor="" className="text-primary">
										Current Department : {getsingleuserdetailbyid.departmentname}
									</label>
									{loadeddepartments.length !== 0 && (
										<div>
											<Typeahead
												id="basic-typeahead-single"
												labelKey="departmentname"
												onChange={setDepartmentType as any}
												options={loadeddepartments}
												placeholder="Select Department"
												selected={departmenttype}
												//   required
												//   inputProps={{ required: true }}
											/>
										</div>
									)}
								</div>

								<div className="form-group p-3">
									<label htmlFor="">User Type</label>
									<br />
									<label htmlFor="" className="text-primary">
										Current User Type : {getsingleuserdetailbyid.usertype}
									</label>
									{loadedusertype.length !== 0 && (
										<div>
											<Typeahead
												id="basic-typeahead-single"
												labelKey="usertype"
												onChange={setUsertypes as any}
												options={loadedusertype}
												placeholder="Select User Type"
												selected={usertypes}
												//   required
												//   inputProps={{ required: true }}
											/>
										</div>
									)}
								</div>
								<div className="form-group p-2">
									<button type="submit" className="btn btn-primary col-12 mx-auto">
										Submit
									</button>
								</div>
							</form>
						</div>
						<div className="col-lg-2"></div>
					</div>
				</div>
			) : (
				<div className="align-centre">
					<h5 className="text-center">Loading invoices, please wait...</h5>
					<Spinner />
				</div>
			)}
		</div>
	);
};

export default EditUserById;
