import React, { useEffect, useState } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { Textarea } from "../ui/textarea";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { FaCheck, FaCheckDouble, FaXmark } from "react-icons/fa6";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	ManagerRemarkInvoice,
	ManagerRemark,
	ApprovalStatus,
} from "@/services/invoice-functions";
import { toast } from "sonner";
import { useNavigate } from "react-router-dom";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { getSystemUsers } from "@/services/auth-functions";
import { STALE_TIME } from "@/constants";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Check, ChevronsUpDown } from "lucide-react";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "../ui/command";
import { cn } from "@/lib/utils";

const formSchema = z.object({
	remarks: z.string().min(1, {
		message: "Remarks are required",
	}),
	managerid: z.number().optional(),
});

const ManagerApproveOrReject: React.FC<{
	invoicemasterid: number;
	accessToken: string;
}> = ({ invoicemasterid, accessToken }) => {
	const [open, setOpen] = useState(false);
	const [suOpen, setSuOpen] = useState(false);
	const [approvalStatus, setApprovalStatus] =
		useState<ApprovalStatus>("Approved");

	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const systemUsers = useQuery({
		queryKey: ["systemusers"],
		queryFn: () => getSystemUsers(accessToken),
		enabled: approvalStatus === "Secondary",
		staleTime: STALE_TIME,
	});

	const approveInvoice = useMutation({
		mutationKey: ["approveInvoiceManager", invoicemasterid],
		mutationFn: (remark: ManagerRemark) =>
			ManagerRemarkInvoice(accessToken, remark, approvalStatus),
		onSuccess: () => {
			queryClient.invalidateQueries(["invoice"]);
			toast.success("Remark submitted", {
				description: `Claim ${invoicemasterid} remark successfully submitted`,
			});
			setOpen(false);
			form.reset();
			approveInvoice.reset();
			navigate("/managerapprovelist");
		},
		onError: () => {
			toast.error("Error submitting remark");
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			remarks: "",
		},
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		if (approvalStatus === "Approved") {
			approveInvoice.mutate({
				managerapprovedremark: data.remarks,
				invoicemasterid,
			});
			return;
		}
		if (approvalStatus === "Secondary") {
			if (data.managerid === undefined) {
				form.setError("managerid", { message: "Manager is required" });
				return;
			}
			approveInvoice.mutate({
				managerapprovedremark: data.remarks,
				invoicemasterid,
				secondarymanagertobeapprovedby: data.managerid,
			});
			return;
		}
		if (approvalStatus === "Rejected") {
			approveInvoice.mutate({
				managerrejectedremark: data.remarks,
				invoicemasterid,
			});
			return;
		}
		approveInvoice.mutate({
			manageramendnote: data.remarks,
			invoicemasterid,
		});
	};

	const disabled = () => {
		if (approveInvoice.isLoading) return true;
		if (approveInvoice.isSuccess) return true;
		return false;
	};

	useEffect(() => {
		if (!open && form.getValues("managerid")) {
			form.setValue("managerid", undefined);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<div className="flex gap-2">
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button>Approve</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="w-80">
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Approved")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start  text-green-600 hover:text-green-600"
								>
									<FaCheck className="mr-2 h-4 w-4" />
									Approve Claim
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Secondary")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start"
								>
									<FaCheckDouble className="mr-2 h-4 w-4" />
									Request for Secondary Approval
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
				<DropdownMenu>
					<DropdownMenuTrigger asChild>
						<Button variant="secondary">Review/Reject</Button>
					</DropdownMenuTrigger>
					<DropdownMenuContent align="end" className="w-56">
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Amend")}
									variant="ghost"
									className="h-auto py-1 w-full justify-start"
								>
									<HiOutlinePencilAlt className="mr-2 h-4 w-4" />
									Request for Review
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
						<DropdownMenuItem>
							<DialogTrigger asChild>
								<Button
									onClick={() => setApprovalStatus("Rejected")}
									variant="ghost"
									className="h-auto py-1 text-red-500 hover:text-red-500 w-full justify-start"
								>
									<FaXmark className="mr-2 h-4 w-4" />
									Reject Claim
								</Button>
							</DialogTrigger>
						</DropdownMenuItem>
					</DropdownMenuContent>
				</DropdownMenu>
			</div>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					{approvalStatus === "Approved" ? (
						<>
							<DialogTitle className="text-green-500 flex gap-2 items-center mb-1">
								<div className="bg-green-200 p-1 rounded-full">
									<FaCheck className="text-xl" />
								</div>
								Approving Claim
							</DialogTitle>
							<DialogDescription>Add your remarks here.</DialogDescription>
						</>
					) : approvalStatus === "Secondary" ? (
						<>
							<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
								<div className="bg-purple-200 p-1 rounded-full">
									<FaCheckDouble className="text-xl" />
								</div>
								Request for Secondary Approval
							</DialogTitle>
							<DialogDescription>
								By submitting this request, you are approving the claim and forwarding
								it to the next approver set below for final approval before it proceeds
								to finance.
							</DialogDescription>
						</>
					) : approvalStatus === "Rejected" ? (
						<>
							<DialogTitle className="text-red-500 flex gap-2 items-center mb-1">
								<div className="bg-red-200 p-1 rounded-full">
									<FaXmark className="text-xl" />
								</div>
								Rejecting Claim
							</DialogTitle>
							<DialogDescription>Add your remarks here.</DialogDescription>
						</>
					) : (
						<>
							<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
								<div className="bg-purple-200 p-1 rounded-full">
									<HiOutlinePencilAlt className="text-xl" />
								</div>
								Request for Review
							</DialogTitle>
							<DialogDescription>Add your remarks here.</DialogDescription>
						</>
					)}
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex items-center space-x-2 mb-4">
							<div className="grid flex-1 gap-2">
								{approvalStatus === "Secondary" ? (
									systemUsers.isLoading ? (
										<span>Loading Users</span>
									) : systemUsers.data ? (
										<FormField
											name="managerid"
											render={({ field }) => (
												<FormItem className="flex flex-col">
													<FormLabel>To Be Approved By:</FormLabel>
													<Popover open={suOpen} onOpenChange={(open) => setSuOpen(open)}>
														<PopoverTrigger asChild>
															<FormControl>
																<Button
																	variant="outline"
																	role="combobox"
																	className={cn(
																		"justify-between font-normal",
																		!field.value && "text-muted-foreground"
																	)}
																	disabled={disabled()}
																>
																	{field.value
																		? systemUsers.data.find((user) => user.id === field.value)
																				?.username
																		: "Select approver"}
																	<ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
																</Button>
															</FormControl>
														</PopoverTrigger>
														<PopoverContent className="p-0 w-[calc(100vw-2rem)] sm:w-[400px]">
															<Command>
																<CommandInput placeholder="Search account..." />
																<CommandList>
																	<CommandEmpty>No account found.</CommandEmpty>
																	<CommandGroup>
																		{systemUsers.data.map((item) => (
																			<CommandItem
																				value={item.username}
																				key={item.id}
																				onSelect={() => {
																					form.setValue("managerid", item.id);
																					setSuOpen(false);
																				}}
																			>
																				<Check
																					className={cn(
																						"mr-2 h-4 w-4",
																						item.id.toString() === field.value
																							? "opacity-100"
																							: "opacity-0"
																					)}
																				/>
																				{item.username}
																			</CommandItem>
																		))}
																	</CommandGroup>
																</CommandList>
															</Command>
														</PopoverContent>
													</Popover>
													<FormMessage />
												</FormItem>
											)}
										/>
									) : null
								) : null}
								<FormField
									name="remarks"
									render={({ field }) => (
										<FormItem>
											{approvalStatus === "Secondary" ? (
												<FormLabel>Remarks</FormLabel>
											) : null}
											<FormControl>
												<Textarea {...field} disabled={disabled()} />
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>

						<DialogFooter className="sm:justify-end">
							<DialogClose asChild>
								<Button type="button" variant="ghost">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit" disabled={disabled()}>
								{disabled() ? "Submitting..." : "Submit"}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default ManagerApproveOrReject;
