import {
	convertToTimeFormat,
	DateConverter,
	getCountryData,
	getFileExtension,
	imageExtensions,
	STALE_TIME_INVOICE,
} from "@/constants";
import {
	getSingleMasterInvoiceDetail,
	Invoice,
} from "@/services/invoice-functions";
import React, { useEffect, useState } from "react";
import InvoiceStatus from "./InvoiceStatus";
import { useQuery } from "react-query";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "./ui/table";
import { FaCheck } from "react-icons/fa";
import { FaXmark } from "react-icons/fa6";
import InvoiceAttachments from "./InvoiceAttachments";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { cn } from "@/lib/utils";

type InvoiceDetailsProps = {
	invoice: Invoice;
	accessToken: string;
};

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
	invoice,
	accessToken,
}) => {
	const [images, setImages] = useState<string[]>([]);
	const [files, setFiles] = useState<string[]>([]);

	const invoiceDetails = useQuery({
		queryKey: ["invoice", "details", invoice.invoicemasterid],
		queryFn: () =>
			getSingleMasterInvoiceDetail(
				accessToken,
				invoice.invoicemasterid.toString()
			),
		staleTime: STALE_TIME_INVOICE,
	});

	const prepareAttachments = (attachments: string) => {
		const [im, fl] = attachments.split(",").reduce<[string[], string[]]>(
			([images, files], attachment) => {
				if (imageExtensions.includes(getFileExtension(attachment))) {
					images.push(attachment);
				} else {
					files.push(attachment);
				}
				return [images, files];
			},
			[[], []]
		);
		setImages(im);
		setFiles(fl);
	};

	useEffect(() => {
		if (invoice.files) prepareAttachments(invoice.files);
	}, [invoice.files]);

	return (
		<>
			<div className="flex gap-3 items-end pt-4">
				{/* <span className="text-xl">for</span> */}
				<span className="font-medium text-xl">
					{invoiceDetails.data && invoiceDetails.data.length > 0
						? invoiceDetails.data[0].currency
						: ""}{" "}
					{invoice.amount}
				</span>
				<InvoiceStatus data={invoice} />
			</div>
			<div className="border-y my-3 py-4 border-zinc-200">
				<div className="grid grid-cols-1 sm:grid-cols-2">
					<div className="flex gap-4">
						<div className="flex flex-col gap-2 text-zinc-500 font-light text-nowrap">
							<p>Claim ID</p>
							<p>Claim Type</p>
							<p>Amount</p>
							{invoiceDetails.data &&
							invoiceDetails.data.length > 0 &&
							invoiceDetails.data[0].kms ? (
								<p>Kms</p>
							) : null}
							{invoiceDetails.data && invoiceDetails.data.length > 0 ? (
								<p>Currency</p>
							) : null}
							{invoice.country ? <p>Country</p> : null}
							{invoice.continent ? <p>Continent</p> : null}
							{invoice.glaccountdescription ? <p>GL Account</p> : null}
							{invoice.product ? <p>Product</p> : null}
							{invoice.paymentmethoddes ? <p>Payment Method</p> : null}
							<p>Description</p>
						</div>
						<div className="flex flex-col gap-2">
							<p>{invoice.invoicemasterid}</p>
							<p>{invoice.invoicetype}</p>
							<p>{invoice.amount}</p>
							{invoiceDetails.data &&
							invoiceDetails.data.length > 0 &&
							invoiceDetails.data[0].kms ? (
								<p>{invoiceDetails.data[0].kms}</p>
							) : null}
							{invoiceDetails.data && invoiceDetails.data.length > 0 ? (
								<p>{invoiceDetails.data[0].currency}</p>
							) : null}
							{invoice.country ? (
								<p>
									<span
										className={` fi fi-${getCountryData(
											invoice.country
										)!.flag.toLowerCase()}`}
									></span>{" "}
									{invoice.country}
								</p>
							) : null}
							{invoice.continent ? <p>{invoice.continent}</p> : null}
							{invoice.glaccountdescription ? (
								<p>{invoice.glaccountdescription}</p>
							) : null}
							{invoice.product ? <p>{invoice.product}</p> : null}
							{invoice.paymentmethoddes ? <p>{invoice.paymentmethoddes}</p> : null}
							<p>{invoice.description}</p>
						</div>
					</div>
					<div className="flex gap-4 pt-2 md:pt-0">
						<div className="flex flex-col gap-2 text-zinc-500 font-light">
							<p>Raised By</p>
							<p>Department</p>
							{invoice.jobgradedescription ? <p>Job Grade</p> : ""}
							<p>Phone</p>
							<p>Raised On</p>
							<p>Raised At</p>
						</div>
						<div className="flex flex-col gap-2">
							<p>{invoice.raisedby}</p>
							<p>{invoice.department}</p>
							{invoice.jobgradedescription ? <p>{invoice.jobgradedescription}</p> : ""}
							<p>{invoice.phone}</p>
							<p>{DateConverter(invoice.raisedon)}</p>
							<p>{convertToTimeFormat(invoice.raisedat)}</p>
						</div>
					</div>
				</div>
				{invoiceDetails.isLoading ? (
					<div className="align-centre">
						<h5 className="text-center">Loading claim details, please wait...</h5>
					</div>
				) : invoiceDetails.isError ? (
					<div className="align-centre">
						<h5 className="text-center">Error loading claim details.</h5>
					</div>
				) : (
					invoiceDetails.data &&
					invoice.jobgradeid != null && (
						<>
							<hr className="my-3 border-zinc-200" />
							<h3 className="text-lg font-medium mb-2">Entries</h3>
							<Table>
								<TableHeader>
									<TableRow>
										<TableHead>Item</TableHead>
										<TableHead>No. of days/lunch/dinner</TableHead>
										{invoiceDetails.data[0].city ? <TableHead>City</TableHead> : null}
										<TableHead>Gross Amount</TableHead>
										<TableHead>Sub Total</TableHead>
									</TableRow>
								</TableHeader>
								<TableBody>
									{invoiceDetails.data.map((item) => (
										<TableRow key={item.invoiceid}>
											<TableCell>{item.perdiemtype}</TableCell>
											<TableCell>{item.days}</TableCell>
											{invoiceDetails.data[0].city ? (
												<TableCell>{item.city}</TableCell>
											) : null}
											<TableCell>{item.grossamount}</TableCell>
											<TableCell>
												{(parseFloat(item.grossamount) * item.days!).toFixed(2)}
											</TableCell>
										</TableRow>
									))}
									<TableRow>
										<TableCell></TableCell>
										<TableCell></TableCell>
										{invoiceDetails.data[0].city ? <TableCell></TableCell> : null}
										<TableCell className="font-semibold">Total</TableCell>
										<TableCell className="font-semibold">{invoice.amount}</TableCell>
									</TableRow>
								</TableBody>
							</Table>
						</>
					)
				)}
			</div>
			<div className="grid grid-cols-1 sm:grid-cols-2">
				<div>
					<h3 className="text-lg font-medium mb-3">Activity</h3>
					<div className="flex flex-col gap-6">
						{invoice.collected ? (
							<div className="flex gap-4">
								<div className="bg-green-100 p-2 rounded-lg self-start">
									<FaCheck className="text-green-500" />
								</div>
								<div>
									<h5 className="text-green-600 font-medium">Cash Collected</h5>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.datecollected
									)} at ${convertToTimeFormat(invoice.timecollected)}`}</small>
								</div>
							</div>
						) : null}
						{invoice.secondaryfinanceapproved !== null &&
						invoice.secondaryfinanceapprovedat ? (
							<div className="flex gap-4">
								<div
									className={cn(
										"p-2 rounded-lg self-start",
										invoice.secondaryfinanceapproved ? "bg-green-100" : "bg-red-100"
									)}
								>
									{invoice.secondaryfinanceapproved ? (
										<FaCheck className="text-green-500" />
									) : (
										<FaXmark className="text-red-500" />
									)}
								</div>
								<div>
									{invoice.secondaryfinanceapproved ? (
										<h5 className="text-green-600 font-medium">{`Approved by ${invoice.secondaryfinanceapprovedbyname}`}</h5>
									) : (
										<h5 className="text-red-600 font-medium">{`Disapproved by ${invoice.secondaryfinanceapprovedbyname}`}</h5>
									)}
									<p className="text-sm mt-2">
										Secondary Finance Remarks: {invoice.secondaryfinanceapprovalnote}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.secondaryfinanceapprovedon
									)} at ${convertToTimeFormat(
										invoice.secondaryfinanceapprovedat
									)}`}</small>
								</div>
							</div>
						) : null}
						{invoice.financeapproved && invoice.financeapprovedat ? (
							<div className="flex gap-4">
								<div className="bg-green-100 p-2 rounded-lg self-start">
									<FaCheck className="text-green-500" />
								</div>
								<div>
									<h5 className="text-green-600 font-medium">{`Approved by ${invoice.financeapprovedbyname}`}</h5>
									<p className="text-sm mt-2">
										Finance Remarks: {invoice.financeapprovedremark}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.financeapprovedon
									)} at ${convertToTimeFormat(invoice.financeapprovedat)}`}</small>
								</div>
							</div>
						) : invoice.financerejected && invoice.financerejectedat ? (
							<div className="flex gap-4">
								<div className="bg-red-100 p-2 rounded-lg self-start">
									<FaXmark className="text-red-500" />
								</div>
								<div>
									<h5 className="text-red-600 font-medium">{`Disapproved by ${invoice.financerejectedbyname}`}</h5>
									<p className="text-sm mt-2">
										Finance Remarks: {invoice.financerejectedremark}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.financerejectedon
									)} at ${convertToTimeFormat(invoice.financerejectedat)}`}</small>
								</div>
							</div>
						) : invoice.financeamend && invoice.financeamendat ? (
							<div className="flex gap-4">
								<div className="bg-purple-100 p-2 rounded-lg self-start">
									<HiOutlinePencilAlt className="text-purple-500" />
								</div>
								<div>
									<h5 className="text-purple-600 font-medium">
										Returned for Corrections
									</h5>
									<p className="text-sm mt-2">
										Finance Remarks: {invoice.financeamendnote}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.financeamendon
									)} at ${convertToTimeFormat(invoice.financeamendat)}`}</small>
								</div>
							</div>
						) : null}
						{invoice.secondarymanagerapproved !== null &&
						invoice.secondarymanagerapprovedat ? (
							<div className="flex gap-4">
								<div
									className={cn(
										"p-2 rounded-lg self-start",
										invoice.secondarymanagerapproved ? "bg-green-100" : "bg-red-100"
									)}
								>
									{invoice.secondarymanagerapproved ? (
										<FaCheck className="text-green-500" />
									) : (
										<FaXmark className="text-red-500" />
									)}
								</div>
								<div>
									{invoice.secondarymanagerapproved ? (
										<h5 className="text-green-600 font-medium">{`Approved by ${invoice.secondarymanagerapprovedbyname}`}</h5>
									) : (
										<h5 className="text-red-600 font-medium">{`Disapproved by ${invoice.secondarymanagerapprovedbyname}`}</h5>
									)}
									<p className="text-sm mt-2">
										Secondary Manager Remarks: {invoice.secondarymanagerapprovalnote}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.secondarymanagerapprovedon
									)} at ${convertToTimeFormat(
										invoice.secondarymanagerapprovedat
									)}`}</small>
								</div>
							</div>
						) : null}
						{invoice.managerapproved && invoice.managerapprovedat ? (
							<div className="flex gap-4">
								<div className="bg-green-100 p-2 rounded-lg self-start">
									<FaCheck className="text-green-500" />
								</div>
								<div>
									<h5 className="text-green-600 font-medium">{`Approved by ${invoice.managerapprovedbyname}`}</h5>
									<p className="text-sm mt-2">
										Manager Remarks: {invoice.managerapprovedremark}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.managerapprovedon
									)} at ${convertToTimeFormat(invoice.managerapprovedat)}`}</small>
								</div>
							</div>
						) : invoice.managerrejected && invoice.managerrejectedat ? (
							<div className="flex gap-4">
								<div className="bg-red-100 p-2 rounded-lg self-start">
									<FaXmark className="text-red-500" />
								</div>
								<div>
									<h5 className="text-red-600 font-medium">{`Disapproved by ${invoice.managerrejectedbyname}`}</h5>
									<p className="text-sm mt-2">
										Manager Remarks: {invoice.managerrejectedremark}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.managerrejectedon
									)} at ${convertToTimeFormat(invoice.managerrejectedat)}`}</small>
								</div>
							</div>
						) : invoice.manageramend && invoice.manageramendat ? (
							<div className="flex gap-4">
								<div className="bg-purple-100 p-2 rounded-lg self-start">
									<HiOutlinePencilAlt className="text-purple-500" />
								</div>
								<div>
									<h5 className="text-purple-600 font-medium">
										Returned for Corrections
									</h5>
									<p className="text-sm mt-2">
										Manager Remarks: {invoice.manageramendnote}
									</p>
									<small className="font-light text-zinc-600">{`${DateConverter(
										invoice.manageramendon
									)} at ${convertToTimeFormat(invoice.manageramendat)}`}</small>
								</div>
							</div>
						) : (
							<p>No recorded activity</p>
						)}
					</div>
				</div>
				<div>
					<h3 className="text-lg font-medium mb-2">Attachments</h3>
					{!invoice.files ? (
						<div>No files attached</div>
					) : (
						<InvoiceAttachments images={images} docs={files} />
					)}
				</div>
			</div>
		</>
	);
};

export default InvoiceDetails;
