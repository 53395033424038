/* eslint-disable import/no-anonymous-default-export */
import { SET_ALERT, REMOVE_ALERT } from "../types";

interface Alert {
	id: string;
	msg: string;
	type: string;
}

export type AlertState = Alert[];

export type AlertAction =
	| { type: typeof SET_ALERT; payload: Alert }
	| { type: typeof REMOVE_ALERT; payload: string };

export default (state: AlertState, action: AlertAction) => {
	switch (action.type) {
		case SET_ALERT:
			return [...state, action.payload];
		case REMOVE_ALERT:
			return state.filter((alert) => alert.id !== action.payload);
		default:
			return state;
	}
};
