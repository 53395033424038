import { getFileExtension, getS3FileUrl } from "@/constants";
import React, {useEffect, useState} from "react";
import { FaFile, FaFilePdf, FaFileWord } from "react-icons/fa";

const ReportDocuments: React.FC<{ docs: string[] }> = ({ docs }) => {

	const [urls, setUrls] = useState<string[]>([]);

	useEffect(() => {
		// Fetch URLs from S3 and set them in state
		const fetchUrls = async () => {
			const fetchedUrls = await Promise.all(
				docs.map((doc) => getS3FileUrl(doc)) // Resolves all URLs
			);
			setUrls(fetchedUrls);
		};

		fetchUrls();
	}, [docs]);

	return (
		<div className="flex">
			{urls.map((url, index) => (
				<a
					href={url}
					target="_blank"
					rel="noreferrer"
					key={index}
					className="hover:rounded-md flex gap-2 hover:bg-blue-50 items-center"
				>
					{getFileExtension(url) == "pdf" ? (
						<FaFilePdf className="text-base text-red-700" />
					) : getFileExtension(url) == "docx" || "doc" ? (
						<FaFileWord className="text-base text-blue-700" />
					) : (
						<FaFile className="text-base text-red-700" />
					)}
				</a>
			))}
		</div>
	);
};

export default ReportDocuments;
