import { createContext } from "react";
import { UserState } from "./UserReducer";

export type UserCheck = {
	id: number;
	first_name: string;
	last_name: string;
	email: string;
	is_staff: boolean;
	is_active: boolean;
	date_joined: string;
	last_login: string;
	is_superuser: boolean;
	username: string;
	department: string;
	managername: string;
	manageremail: string;
	phone: number;
	countrycode: number;
	usertypeid: number;
	departmentid: number;
	groups: string[];
	user_permissions: string[];
};

export type UserDepartment = {
	department: string;
	userPrincipalName: string;
	jobTitle: string;
	displayName: string;
	businessPhones: string[];
	mobilePhone: string;
	country: string;
	onPremisesDomainName: string;
	city: string;
	officeLocation: string;
};

export type UserManager = {
	id: string;
	businessPhones: string[];
	displayName: string;
	givenName: string;
	jobTitle: string;
	mail: string;
	mobilePhone: string | null;
	officeLocation: string;
	preferredLanguage: string | null;
	surname: string;
	userPrincipalName: string;
};

export type UserColleague = {
	id: string;
	businessPhones: string[];
	displayName: string;
	givenName: string;
	jobTitle: string;
	mail: string;
	mobilePhone: string;
	officeLocation: string;
	preferredLanguage: null | string;
	surname: string;
	userPrincipalName: string;
};

export type UserSystem = {
	id: number;
	last_login: string;
	username: string;
	first_name: string;
	last_name: string;
	email: string;
	countrycode: number;
	country: string;
	department: string;
	manageremail: string;
	managername: string;
	usertype: string;
	hodname: string;
	hodemail: string;
	departmentname: string;
	phone: string;
	usertypeid: number;
	departmentid: number;
};

export type Department = {
	departmentid: number;
	departmentname: string;
};

export type UserType = {
	usertypeid: number;
	usertype: string;
};

export type EditUserByIdParams = {
	department: number;
	usertypeid: number;
	userid: number;
};

export type UserJobGrade = {
	jobgradeid: number;
	jobgradedescription: string;
};

export type LocalCity = {
	cityid: number;
	city: string;
	countrycode: 1;
};

export const UserContext = createContext<UserState | null>(null);
