import { useContext } from "react";
import { UserContext } from "@/context/User/UserContext";
import { getSingleMasterInvoice } from "@/services/invoice-functions";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import InvoiceDetails from "@/components/InvoiceDetails";
import { STALE_TIME_INVOICE } from "@/constants";
import InvoiceSkeleton from "@/components/skeleton/InvoiceSkeleton";

const GeneralReport = () => {
	const { id } = useParams<{ id: string }>() as { id: string };
	const user = useContext(UserContext);

	const invoice = useQuery({
		queryKey: ["invoice", id],
		queryFn: () => getSingleMasterInvoice(user?.accessToken!, id),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME_INVOICE,
	});

	return (
		<div>
			{invoice.isLoading ? (
				<InvoiceSkeleton />
			) : invoice.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error loading claim.</h5>
				</div>
			) : (
				invoice.data && (
					<>
						<div className="flex gap-3 items-end justify-between">
							<h1 className="font-medium text-4xl">
								CLAIM NO: {invoice.data.invoicemasterid}
							</h1>
						</div>
						<InvoiceDetails invoice={invoice.data} accessToken={user?.accessToken!} />
					</>
				)
			)}
		</div>
	);
};

export default GeneralReport;
