import React from "react";
import { CashOfficeColumn } from "@/components/data-table/CashOfficeColumn";
import DataTable from "@/components/data-table/DataTable";
import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { UserContext } from "@/context/User/UserContext";
import { cn } from "@/lib/utils";
import { getPaidInvoices } from "@/services/invoice-functions";
import { useContext } from "react";
import { IoCalendarSharp } from "react-icons/io5";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { DateRange } from "react-day-picker";
import { format, subDays } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import { ISODateFormat, STALE_TIME_INVOICE } from "@/constants";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";

const CashOfficeReports = () => {
	const user = useContext(UserContext);

	const [date, setDate] = React.useState<DateRange | undefined>({
		from: subDays(new Date(), 3),
		to: new Date(),
	});
	const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);

	const paidInvoices = useQuery({
		queryKey: ["invoice", "paid", date?.from, date?.to],
		queryFn: () =>
			getPaidInvoices(
				user?.accessToken!,
				ISODateFormat(date?.from!),
				ISODateFormat(date?.to!)
			),
		enabled: !!user?.accessToken && !!date,
		staleTime: STALE_TIME_INVOICE,
	});

	const navigate = useNavigate();

	return (
		<div>
			<h1 className="text-4xl font-bold mb-2">Cash Office Reports</h1>
			<h3 className="text-zinc-600 font-light mb-4">
				Here are a list of successful invoices that have been paid.
			</h3>
			<div className="flex justify-end py-2">
				<Popover open={isPopUpOpen} onOpenChange={setIsPopUpOpen}>
					<PopoverTrigger asChild>
						<Button
							id="date"
							variant={"outline"}
							className={cn(
								"w-[300px] justify-start text-left font-normal",
								!date && "text-muted-foreground"
							)}
						>
							<IoCalendarSharp className="mr-2 h-4 w-4" />
							{date?.from ? (
								date.to ? (
									<>
										{format(date.from, "LLL dd, y")} - {format(date.to, "LLL dd, y")}
									</>
								) : (
									format(date.from, "LLL dd, y")
								)
							) : (
								<span>Pick a date</span>
							)}
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-auto p-2" align="start">
						<Select
							onValueChange={(value) => {
								setDate({
									from: subDays(new Date(), parseInt(value)),
									to: new Date(),
								});
								// setIsPopUpOpen(false);
							}}
						>
							<SelectTrigger>
								<SelectValue placeholder="Select" />
							</SelectTrigger>
							<SelectContent position="popper">
								<SelectItem value="0">Today</SelectItem>
								<SelectItem value="1">Yesterday</SelectItem>
								<SelectItem value="3">Past 3 days</SelectItem>
								<SelectItem value="7">Past week</SelectItem>
							</SelectContent>
						</Select>
						<Calendar
							initialFocus
							mode="range"
							defaultMonth={date?.from}
							selected={date}
							onSelect={setDate}
							numberOfMonths={1}
						/>
						<Button
							className="w-full hover:bg-primary/30"
							onClick={() => setIsPopUpOpen(false)}
						>
							Confirm
						</Button>
					</PopoverContent>
				</Popover>
			</div>
			{paidInvoices.data ? (
				<DataTable
					columns={CashOfficeColumn}
					data={paidInvoices.data}
					rowClick={(id: number) => navigate(`/cashcollection/${id}`)}
					rowId="invoicemasterid"
					searchColumn="raisedby"
				/>
			) : null}
			{paidInvoices.isLoading ? (
				<div className="flex justify-center">
					<span className="loader" />
				</div>
			) : paidInvoices.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error showing invoices.</h5>
				</div>
			) : (
				paidInvoices.data &&
				paidInvoices.data.length === 0 && (
					<div className="align-centre">
						<h5 className="text-center">No invoices found</h5>
					</div>
				)
			)}
		</div>
	);
};

export default CashOfficeReports;
