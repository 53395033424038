import React from "react";

const InvoiceTag: React.FC<{ id: number; type: string }> = ({ id, type }) => {
	const styles: { [key: string]: string } = {
		"1": "bg-blue-100 text-blue-600",
		"2": "bg-violet-100 text-violet-600",
		"3": "bg-purple-100 text-purple-600",
		"7": "bg-orange-100 text-orange-600",
		"5": "bg-stone-100 text-stone-600",
		"6": "bg-teal-100 text-teal-600",
		"4": "bg-indigo-100 text-indigo-600",
	};

	return (
		<span
			className={`text-xs rounded-full px-1.5 py-0.5 font-medium text-nowrap bg ${
				styles[id] ? styles[id] : ""
			}`}
		>
			{type}
		</span>
	);
};

export default InvoiceTag;
