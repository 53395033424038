import { buttonVariants } from "@/components/ui/button";
import { NavLink } from "react-router-dom";

const NotFound = () => {
	return (
		<div className="h-[calc(100vh-64px)] flex justify-center items-center">
			<div className="text-center border rounded-md p-4 max-w-sm grid gap-4">
				<h1 className="text-5xl font-bold">404</h1>
				<p>The page you are looking for does not exist.</p>
				<NavLink to="/" className={buttonVariants()}>
					Back to Home
				</NavLink>
			</div>
		</div>
	);
};

export default NotFound;
