import React, { useState, useContext, useEffect } from "react";
import AlertContext from "../../context/alert/AlertContext";
import AuthContext from "../../context/Auth/AuthContext";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm } from "react-hook-form";
import Spinner from "../layout/Spinner";
import { DepartmentType } from "../../context/Auth/AuthState";
const UpdateUserApproverDetails = () => {
	const alertContext = useContext(AlertContext);
	const authContext = useContext(AuthContext);

	// const { setAlert } = alertContext;

	const {
		getUserByEmail,
		getuserbyemail,
		clearAuthErrors,
		autherror,
		UpdateUserdepartment,
		updateuserdepartment,
		getAllDepartments,
		getalldepartments,
		getSingleUserDetails,
	} = authContext;

	const [departmenttype, setDepartmentType] = useState<DepartmentType[]>([]);
	const [visibleSearch, setVisibleSearch] = useState(false);
	const { register, handleSubmit, reset } = useForm();
	const {
		register: registerFinal,
		handleSubmit: handleSubmitFinal,
		reset: resetFinal,
	} = useForm();
	const [visibleupdate, setVisibleUpdate] = useState(false);

	useEffect(() => {
		getAllDepartments();
		getSingleUserDetails();
		if (autherror) {
			setVisibleSearch(false);
			setVisibleUpdate(false);
			if (alertContext) alertContext.setAlert("error finding user", "danger");
			clearAuthErrors();
		}

		if (getuserbyemail) {
			setVisibleSearch(false);
		}

		if (updateuserdepartment) {
			getSingleUserDetails();
			setVisibleSearch(false);
			setVisibleUpdate(false);
			if (alertContext)
				alertContext.setAlert(
					"department and approver details updated correctly",
					"success"
				);
			clearAuthErrors();
			reset();
			resetFinal();
			setDepartmentType([]);
		}
		// eslint-disable-next-line
	}, [getuserbyemail, autherror, updateuserdepartment, reset, resetFinal]);

	let loadeddepartments = [];

	if (getalldepartments) {
		loadeddepartments = getalldepartments;
	} else {
		loadeddepartments = [];
	}

	const searchUser = (data: any) => {
		// e.preventDefault();
		if (data.approveremail !== "") {
			getUserByEmail(data.approveremail);
			setVisibleSearch(true);
		}
	};

	const handleUpdateDetails = (data: any) => {
		// e.preventDefault();
		if (getuserbyemail) {
			let managername = getuserbyemail.displayName;
			let manageremail = getuserbyemail.mail;
			let department = departmenttype[0].departmentid;
			let phone = data.phone;
			UpdateUserdepartment({ manageremail, managername, department, phone });
			setVisibleUpdate(true);
		}
	};

	return (
		<div>
			<div className="row">
				<div className="col-lg-4"></div>
				<div className="col-lg-4">
					{visibleupdate ? (
						<div className="align-centre">
							<h5 className="text-center">Updating Details</h5>
							<Spinner />
						</div>
					) : (
						<div></div>
					)}
					{visibleSearch ? (
						<div className="align-centre">
							<h5 className="text-center">Searching For User, Kindly Wait...</h5>
							<Spinner />
						</div>
					) : (
						<div></div>
					)}
				</div>
				<div className="col-lg-4"></div>
			</div>
			<div className="row">
				<div>
					<div className="p-2 bg-gradient bg-info border border-info">
						<p>
							kindly update your Approver details and department details before you can
							raise an invoice.
						</p>
					</div>
					<form onSubmit={handleSubmit(searchUser)}>
						<div className="form-group p-2">
							<label htmlFor="">Approver Email</label>
							<input
								type="email"
								className="form-control inputbordersm"
								// name="approveremail"
								{...register("approveremail", {
									required: true,
								})}
							/>
						</div>
						<div className="form-group p-2">
							<button type="submit" className="btn btn-primary col-12 mx-auto">
								Search
							</button>
						</div>
					</form>
					<hr />
					<form onSubmit={handleSubmitFinal(handleUpdateDetails)}>
						{getuserbyemail && (
							<div>
								<h4 className="text-primary text-center">Approver Details</h4>
								<table className="table table-striped table-bordered table-responsive">
									<thead></thead>
									<tbody>
										<tr>
											<th className="table-primary text-primary text-lg-start">
												Approver
											</th>
											<td className="table-primary align-left">
												{getuserbyemail.displayName}
											</td>
										</tr>
										<tr>
											<th className="table-primary text-primary text-lg-start">
												Approver Email
											</th>
											<td className="table-primary align-left">{getuserbyemail.mail}</td>
										</tr>
										<tr>
											<th className="table-primary text-primary text-lg-start">
												Approver Business Phones
											</th>
											<td className="table-primary align-left">
												{getuserbyemail.businessPhones}
											</td>
										</tr>
									</tbody>
								</table>

								<div className="form-group p-3">
									<label htmlFor="">Department Name</label>
									{loadeddepartments.length !== 0 && (
										<Typeahead
											id="basic-typeahead-single"
											labelKey="departmentname"
											onChange={setDepartmentType as any}
											options={loadeddepartments}
											placeholder="Select Department"
											selected={departmenttype}
											// required
											inputProps={{ required: true }}
										/>
									)}
								</div>

								<div className="form-group p-3">
									<label htmlFor="">Phone</label>
									<input
										type="text"
										className="form-control inputbordersm"
										// name="phone"
										{...registerFinal("phone", {
											required: true,
										})}
									/>
								</div>

								<div className="form-group p-2">
									<button type="submit" className="btn btn-primary col-12 mx-auto">
										Update Details
									</button>
								</div>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	);
};

export default UpdateUserApproverDetails;
