import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { z } from "zod";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "../ui/select";
import { getJobGrades, getLocalCities } from "@/services/auth-functions";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { UserContext } from "@/context/User/UserContext";
import { useContext, useEffect, useState } from "react";
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from "../ui/table";
import { Textarea } from "../ui/textarea";
import AttachmentPreview, { FilePreview } from "./AttachmentPreview";
import {
	getPerdiemTypes,
	Invoice,
	InvoiceDetail,
	updateLocalPerDiem,
	AmendLocalPerDiem,
	AmendInternationalPerDiem,
	updateInternationalPerDiem,
} from "@/services/invoice-functions";
import { MdDelete, MdOutlineCloudUpload } from "react-icons/md";
import Countries from "@/data/countries.json";
import { toast } from "sonner";
import {
	acceptedFiles,
	getCountryData,
	getUploadedFile,
	getS3FileUrl,
	STALE_TIME,
} from "@/constants";
import { FiPlusCircle } from "react-icons/fi";
import { Label } from "../ui/label";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";

const EditClaimPerDiem: React.FC<{
	invoice: Invoice;
	invoiceDetails: InvoiceDetail[];
}> = ({ invoice, invoiceDetails }) => {
	const user = useContext(UserContext);
	const [open, setOpen] = useState(false);
	const [isLoadingAttachments, setIsLoadingAttachments] = useState(false);
	const [uploadedFiles, setUploadedFiles] = useState<FilePreview[]>([]);
	const [totalAmount, setTotalAmount] = useState(0);

	const rowSchema = z.object({
		city: z.number().default(-1),
		perdiemtype: z.number().nonnegative("Required"),
		numberofnights: z.number().min(1, {
			message: "No is required",
		}),
		amounts: z.number().min(1, {
			message: "Amount is required",
		}),
		fullamount: z.number(),
	});

	const formSchema = z
		.object({
			jobgradsid: z.number(),
			description: z.string().min(1, {
				message: "Description is required",
			}),
			attachment: z.array(z.instanceof(File)).min(0),
			rows: z.array(rowSchema).min(1),
			country: z.string().optional(),
			continent: z.string().optional(),
		})
		.refine(
			(data) => {
				if (invoice.traveltypeid === 1) return data.country !== undefined;
				return true;
			},
			{ message: "Country is required yeah", path: ["country"] }
		)
		.refine(
			(data) => {
				if (invoice.traveltypeid === 2)
					return data.rows.every((row) => row.city !== -1);
				if (invoice.traveltypeid === 2)
					return data.rows.every((row) => row.city !== undefined);
				return true;
			},
			{
				message: "One or more of the city fields above is undefined",
				path: ["rows"],
			}
		);

	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const userJobGrades = useQuery({
		queryKey: ["userJobGrades"],
		queryFn: () => getJobGrades(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const localCities = useQuery({
		queryKey: ["getlocalcities"],
		queryFn: () => getLocalCities(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const perDiemTypes = useQuery({
		queryKey: ["getperdiemtypes"],
		queryFn: () => getPerdiemTypes(user?.accessToken!),
		enabled: !!user?.accessToken,
		staleTime: STALE_TIME,
	});

	const amendLocalPerDiemInvoice = useMutation({
		mutationKey: ["updatelocalperdiem", invoice.invoicemasterid],
		mutationFn: (perd: AmendLocalPerDiem) =>
			updateLocalPerDiem(user?.accessToken!, perd),
		onSuccess: (data) => {
			success();
		},
		onError: (err) => {
			console.log(err);
			error();
		},
	});

	const amendInternationalPerDiemInvoice = useMutation({
		mutationKey: ["updateinternationalperdiem", invoice.invoicemasterid],
		mutationFn: (perd: AmendInternationalPerDiem) =>
			updateInternationalPerDiem(user?.accessToken!, perd),
		onSuccess: (data) => {
			success();
		},
		onError: (err) => {
			console.log(err);
			error();
		},
	});

	const defaultRows = invoiceDetails.map((item) => ({
		city: item.cityid ? item.cityid : undefined,
		perdiemtype: item.typeofperdiem!,
		numberofnights: 0,
		amounts: 0,
		fullamount: 0,
	}));

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		defaultValues: {
			country: invoice.country ? invoice.country : undefined,
			continent: invoice.continent ? invoice.continent : undefined,
			jobgradsid: invoice.jobgradeid!,
			description: invoice.description,
			attachment: [],
			rows: defaultRows,
		},
	});

	const fieldArray = useFieldArray({
		control: form.control,
		name: "rows",
	});

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawFiles = Array.from(event.target.files || []);
		const files = rawFiles.filter((file) => {
			if (acceptedFiles.includes(file.type)) return true;
			toast.error("File type not supported");
			return false;
		});
		const existingFiles = form.getValues("attachment");
		const allFiles = [...existingFiles, ...files];
		form.setValue("attachment", allFiles);
		const filePreviews = allFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(filePreviews);
	};

	const handlePreviousAttachments = async (files: string | null) => {
		if (!files) return;
		setIsLoadingAttachments(true);
		const splitFiles = files.split(",");
		const existingFiles = form.getValues("attachment");
		
		// Fetch files from S3 and update attachments using getS3FileUrl
		const promises = splitFiles.map(async (file) => {
			const fileUrl = await getS3FileUrl(file); // Await the S3 URL using your function
			const retrievedFile = await getUploadedFile(fileUrl);
			if (retrievedFile) {
				existingFiles.push(retrievedFile);
			}
		});
		await Promise.all(promises);
		
		// Set the updated files in the form state
		form.setValue("attachment", existingFiles);
		const updatedPreviews = existingFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
		setIsLoadingAttachments(false);
	};
	

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		if (invoice.traveltypeid === 2) {
			amendLocalPerDiemInvoice.mutate({
				invoicemasterid: invoice.invoicemasterid,
				description: data.description,
				jobgradeid: data.jobgradsid,
				totalamount: totalAmount,
				files: data.attachment,
				cities: data.rows.map((row) => row.city!),
				numberofnights: data.rows.map((row) => row.numberofnights),
				typeofpayments: data.rows.map((row) => row.perdiemtype),
				amounts: data.rows.map((row) => row.amounts),
			});
		} else {
			if (data.country === undefined || data.continent === undefined) {
				toast.error("Please select country");
				return;
			}
			amendInternationalPerDiemInvoice.mutate({
				invoicemasterid: invoice.invoicemasterid,
				description: data.description,
				submittedcountry: data.country,
				submittedcontinent: data.continent,
				jobgradeid: data.jobgradsid,
				totalamount: totalAmount,
				files: data.attachment,
				numberofnights: data.rows.map((row) => row.numberofnights),
				typeofpayments: data.rows.map((row) => row.perdiemtype),
				amounts: data.rows.map((row) => row.amounts),
			});
		}
	};

	const removeFile = (index: number) => {
		const allFiles = form.getValues("attachment");
		const updatedFiles = allFiles.filter((_, i) => i !== index);
		form.setValue("attachment", updatedFiles);

		const updatedPreviews = updatedFiles.map((file) => {
			const filename = file.name;
			const preview = URL.createObjectURL(file);
			return { filename, preview };
		});
		setUploadedFiles(updatedPreviews);
	};

	const rows = useWatch({
		name: "rows",
		control: form.control,
	});

	const updateTotalAmount = () => {
		const newTotalAmount = rows.reduce(
			(sum, row) => sum + (row.fullamount || 0),
			0
		);
		setTotalAmount(newTotalAmount);
	};

	const handleCountryChange = (country: string) => {
		const continent =
			Countries.find((c) => c.country === country)?.continent || "";
		form.setValue("country", country, {
			shouldDirty: true,
			shouldValidate: true,
		});
		form.setValue("continent", continent, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const disableFields = () => {
		if (amendLocalPerDiemInvoice.isLoading) return true;
		if (amendLocalPerDiemInvoice.isSuccess) return true;
		if (amendInternationalPerDiemInvoice.isLoading) return true;
		if (amendInternationalPerDiemInvoice.isSuccess) return true;
		return false;
	};

	const success = () => {
		toast.success(`Invoice ${invoice.invoicemasterid} Amended`, {
			description: "Your changes have been saved.",
		});
		queryClient.invalidateQueries(["invoice"]);
		amendLocalPerDiemInvoice.reset();
		amendInternationalPerDiemInvoice.reset();
		form.reset();
		setOpen(false);
		setUploadedFiles([]);
		navigate("/");
	};

	const error = () => {
		toast.error("Error creating claim, try again");
	};

	useEffect(() => {
		rows.forEach((row, index) => {
			const fullAmount =
				row.amounts && row.numberofnights ? row.amounts * row.numberofnights : 0;
			if (fullAmount !== row.fullamount)
				form.setValue(`rows.${index}.fullamount`, fullAmount);
		});
		updateTotalAmount();
	}, [rows]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		handlePreviousAttachments(invoice.files);
	}, [invoice.files]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Dialog
			open={open}
			onOpenChange={(change) => {
				setOpen(change);
				if (!change) form.reset();
			}}
		>
			<DialogTrigger asChild>
				<Button>Edit Per Diem</Button>
			</DialogTrigger>
			<DialogContent className="max-w-4xl">
				<div className="overflow-x-auto px-1">
					<DialogHeader>
						<DialogTitle className="text-left">Edit Per Diem</DialogTitle>
					</DialogHeader>
					<a
						href="media/perdiem.pdf"
						target="_blank"
						rel="noreferrer"
						className="text-sm text-primary hover:underline"
					>
						Per Diem Guide
					</a>
					<>
						{userJobGrades.data ? (
							<Form {...form}>
								<form onSubmit={form.handleSubmit(onSubmit)}>
									<div className="grid grid-cols-1 gap-2 md:grid-cols-2">
										<div
											className={`mb-2 ${
												invoice.traveltypeid === 2 ? "col-span-2" : "col-span-1"
											}`}
										>
											<Label>Travel Type</Label>
											<Select disabled value={invoice.traveltypeid!.toString()}>
												<SelectTrigger className="mt-2">
													<SelectValue />
												</SelectTrigger>
												<SelectContent>
													<SelectItem value="2">Local Travel</SelectItem>
													<SelectItem value="1">International Travel</SelectItem>
												</SelectContent>
											</Select>
										</div>
										<FormField
											name="country"
											render={({ field }) => (
												<FormItem
													className={invoice.traveltypeid === 2 ? "hidden" : "mb-2"}
												>
													<FormLabel>Country</FormLabel>
													<FormControl>
														<Select
															defaultValue={invoice.country!}
															onValueChange={(e) => {
																// field.onChange(parseFloat(e));
																handleCountryChange(e);
															}}
															disabled={disableFields()}
														>
															<SelectTrigger>
																<SelectValue />
															</SelectTrigger>
															<SelectContent className="max-h-96">
																{Countries.map((country) => (
																	<SelectItem value={country.country}>
																		<span
																			className={` fi fi-${getCountryData(
																				country.country
																			)!.flag.toLowerCase()}`}
																		></span>{" "}
																		{country.country}
																	</SelectItem>
																))}
															</SelectContent>
														</Select>
													</FormControl>
													{form.getValues("continent") ? (
														<span className="inline-block text-xs rounded-full bg-primary/20 px-2 py-1 mt-2">
															Continent - {form.getValues("continent")}
														</span>
													) : null}
													<FormMessage />
												</FormItem>
											)}
										/>
									</div>
									<FormField
										name="jobgradsid"
										render={({ field }) => (
											<FormItem className="mb-2">
												<FormLabel>Job Grade</FormLabel>
												<FormControl>
													<Select
														defaultValue={invoice.jobgradeid!.toString()}
														onValueChange={(e) => {
															field.onChange(parseFloat(e));
														}}
														disabled={disableFields()}
													>
														<SelectTrigger>
															<SelectValue placeholder="Select an option" />
														</SelectTrigger>
														<SelectContent className="max-h-48">
															{userJobGrades.data.map((item) => (
																<SelectItem value={item.jobgradeid.toString()}>
																	{item.jobgradedescription}
																</SelectItem>
															))}
														</SelectContent>
													</Select>
												</FormControl>
												<FormMessage />
											</FormItem>
										)}
									/>
									<FormField
										name="description"
										render={({ field }) => (
											<FormItem className="mb-2">
												<FormLabel>Description</FormLabel>
												<FormControl>
													<Textarea {...field} disabled={disableFields()} />
												</FormControl>
												<FormMessage className="font-light" />
											</FormItem>
										)}
									/>
									<Table className="mt-4">
										<TableHeader className="">
											<TableRow>
												<TableHead>#</TableHead>
												<TableHead className={invoice.traveltypeid === 1 ? "hidden" : ""}>
													City
												</TableHead>
												<TableHead>Per Diem type</TableHead>
												<TableHead className="w-24 text-center">No of Meals/Days</TableHead>
												<TableHead className="w-32 text-center">
													Amount per Day {invoice.traveltypeid === 2 ? "(KES)" : "(USD)"}
												</TableHead>
												<TableHead className="w-48 text-center">Full Amount</TableHead>
												<TableHead className="w-24">Action</TableHead>
											</TableRow>
										</TableHeader>
										<TableBody>
											{fieldArray.fields.map((fd, index) => (
												<TableRow key={fd.id}>
													<TableCell>{index + 1}</TableCell>
													<TableCell className={invoice.traveltypeid === 1 ? "hidden" : ""}>
														<FormField
															name={`rows.${index}.city`}
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Select
																			defaultValue={
																				index < invoiceDetails.length &&
																				invoiceDetails[index].cityid
																					? invoiceDetails[index].cityid!.toString()
																					: ""
																			}
																			onValueChange={(e) => {
																				field.onChange(parseFloat(e));
																			}}
																			disabled={disableFields()}
																		>
																			<SelectTrigger>
																				<SelectValue placeholder="City" />
																			</SelectTrigger>
																			<SelectContent className="max-h-48">
																				{localCities.data &&
																					localCities.data.map((item) => (
																						<SelectItem value={item.cityid.toString()}>
																							{item.city}
																						</SelectItem>
																					))}
																			</SelectContent>
																		</Select>
																	</FormControl>
																	<FormMessage />
																	{form.formState.errors.rows?.[index]?.city && (
																		<p>{form.formState.errors.rows?.[index]?.city?.message}</p>
																	)}
																</FormItem>
															)}
														/>
													</TableCell>
													<TableCell>
														<FormField
															name={`rows.${index}.perdiemtype`}
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Select
																			defaultValue={
																				index < invoiceDetails.length &&
																				invoiceDetails[index].typeofperdiem
																					? invoiceDetails[index].typeofperdiem!.toString()
																					: ""
																			}
																			onValueChange={(e) => {
																				field.onChange(parseFloat(e));
																			}}
																			disabled={disableFields()}
																		>
																			<SelectTrigger>
																				<SelectValue placeholder="Type" />
																			</SelectTrigger>
																			<SelectContent className="max-h-48">
																				{perDiemTypes.data &&
																					perDiemTypes.data.map((item) => (
																						<SelectItem value={item.perdiemtypeid.toString()}>
																							{item.perdiemtype}
																						</SelectItem>
																					))}
																			</SelectContent>
																		</Select>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</TableCell>
													<TableCell>
														<FormField
															name={`rows.${index}.numberofnights`}
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Input
																			type="number"
																			placeholder="No of meals/days"
																			className="mt-0"
																			onChange={(e) => field.onChange(e.target.valueAsNumber)}
																			min={0}
																			disabled={disableFields()}
																		/>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</TableCell>
													<TableCell>
														<FormField
															name={`rows.${index}.amounts`}
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Input
																			type="number"
																			placeholder="Amount"
																			className="mt-0"
																			onChange={(e) => field.onChange(e.target.valueAsNumber)}
																			min={0}
																			disabled={disableFields()}
																		/>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</TableCell>
													<TableCell>
														<FormField
															name={`rows.${index}.fullamount`}
															render={({ field }) => (
																<FormItem>
																	<FormControl>
																		<Input
																			type="number"
																			className="mt-0 min-w-[100px]"
																			readOnly
																			value={field.value}
																			// onChange={(e) => field.onChange(e.target.valueAsNumber)}
																		/>
																	</FormControl>
																	<FormMessage />
																</FormItem>
															)}
														/>
													</TableCell>
													<TableCell>
														<Button
															className="bg-red-600 hover:bg-red-500"
															onClick={() => fieldArray.remove(index)}
															disabled={disableFields()}
														>
															<MdDelete className="text-xl" />
														</Button>
													</TableCell>
												</TableRow>
											))}
											<TableRow>
												<TableCell></TableCell>
												<TableCell>
													<Button
														onClick={() =>
															fieldArray.append({
																city: -1,
																perdiemtype: -1,
																numberofnights: 0,
																amounts: 0,
																fullamount: 0,
															})
														}
														type="button"
														disabled={disableFields()}
													>
														<FiPlusCircle className="mr-2 text-lg" />
														Add New Row
													</Button>
												</TableCell>
												<TableCell></TableCell>
												<TableCell>Total Amount</TableCell>
												<TableCell className="w-48">
													<Input
														type="number"
														className="mt-0 min-w-[100px]"
														readOnly
														value={totalAmount}
													/>
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
									{form.formState.errors.rows?.root?.message && (
										<FormMessage>{form.formState.errors.rows.root.message}</FormMessage>
									)}
									<FormField
										name="attachment"
										render={({ field }) => (
											<FormItem className="mb-2" onDragStart={(e) => e.preventDefault()}>
												<FormLabel>Attachments</FormLabel>
												<FormLabel className="flex items-center justify-center border-dashed border-2 border-gray-300 rounded-lg p-4 cursor-pointer hover:bg-muted">
													<div className="flex flex-col items-center justify-center gap-2">
														<MdOutlineCloudUpload className="text-4xl" />
														<p>
															<b>Click to upload</b>
														</p>
														<p className="font-light">SVG, PNG, JPG or PDF</p>
													</div>
													<FormControl className="hidden">
														<Input
															type="file"
															multiple
															onChange={(e) => {
																handleFileChange(e);
															}}
															disabled={disableFields()}
														/>
													</FormControl>
												</FormLabel>
												<FormMessage />
											</FormItem>
										)}
									/>
									{isLoadingAttachments ? (
										<Skeleton className="h-24 w-full mt-4" />
									) : null}
									{uploadedFiles.length === 0 ? null : (
										<AttachmentPreview
											files={uploadedFiles}
											deleteFile={removeFile}
											disabled={false}
										/>
									)}
									<Button
										type="submit"
										className="mt-4 w-full"
										disabled={disableFields()}
									>
										{disableFields() ? "Updating..." : "Submit Claim"}
									</Button>
								</form>
							</Form>
						) : null}
					</>
				</div>

				{/* <DialogFooter>
					<Button type="submit">Save changes</Button>
				</DialogFooter> */}
			</DialogContent>
		</Dialog>
	);
};

export default EditClaimPerDiem;
