import React, { useEffect, useRef, useState } from "react";
import Lightbox, { ImageFit } from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import "yet-another-react-lightbox/styles.css";
import { getS3FileUrl } from "@/constants"; // Assuming getS3FileUrl is imported here

type ShowImagesProps = {
	images: string[];
};

type Slide = {
	src: string;
	imageFit: ImageFit;
};

const ShowImages: React.FC<ShowImagesProps> = ({ images }) => {
	const [open, setOpen] = useState(false);
	const [slides, setSlides] = useState<Slide[]>([]);
	const indexRef = useRef(0);

	useEffect(() => {
		const fetchSlides = async () => {
			const tempSlides: Slide[] = await Promise.all(
				images.map(async (image) => {
					const url = await getS3FileUrl(image); // Fetch the S3 URL asynchronously
					return {
						src: url,
						imageFit: "cover",
					};
				})
			);
			setSlides(tempSlides);
		};

		fetchSlides();
	}, [images]);

	return (
		<>
			<div className="flex gap-1">
				{slides.map((image, index) => (
					<div
						key={index}
						className="aspect-square w-5 rounded-full bg-primary/10 overflow-hidden cursor-pointer"
						onClick={() => {
							indexRef.current = index;
							setOpen(true);
						}}
					>
						<img
							src={image.src}
							alt={image.src}
							className="object-cover w-full h-full"
						/>
					</div>
				))}
			</div>
			<Lightbox
				open={open}
				close={() => setOpen(false)}
				slides={slides}
				index={indexRef.current}
				plugins={[Counter]}
				counter={{
					separator: "/",
					container: {
						className: "absolute left-2 top-2 text-white shadow-sm",
					},
				}}
			/>
		</>
	);
};

export default ShowImages;
