import { UserContext } from "@/context/User/UserContext";
import React, { useContext } from "react";
import { BsFileEarmarkCheckFill } from "react-icons/bs";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaMoneyBillTransfer, FaUserTie } from "react-icons/fa6";
import { HiUsers } from "react-icons/hi";
import { RiDashboardFill } from "react-icons/ri";
import { TbCalendarDollar } from "react-icons/tb";
import { NavLink } from "react-router-dom";
import { Skeleton } from "../ui/skeleton";

type MenuLink = {
	key: string;
	name: string;
	url: string;
	icon: React.ReactNode;
};

const menuLinks: MenuLink[] = [
	{
		key: "dashboard",
		name: "My Dashboard",
		url: "/",
		icon: <RiDashboardFill className="text-2xl" />,
	},
	{
		key: "manager-approvals",
		name: "Departmental Approvals",
		url: "/managerapprovelist",
		icon: <FaUserTie className="text-2xl" />,
	},
	{
		key: "manager-secondary-approvals",
		name: "Secondary Approvals",
		url: "/managersecondaryapprovals",
		icon: <FaUserTie className="text-2xl invisible" />,
	},
	{
		key: "finance-approvals",
		name: "Finance Approvals",
		url: "/financeapprovelist",
		icon: <BsFileEarmarkCheckFill className="text-2xl" />,
	},
	{
		key: "finance-secondary-approvals",
		name: "Secondary Approvals",
		url: "/financesecondaryapprovals",
		icon: <BsFileEarmarkCheckFill className="text-2xl invisible" />,
	},
	{
		key: "reports",
		name: "Reports",
		url: "/allclaims",
		icon: <FaFileInvoiceDollar className="text-2xl" />,
	},
	{
		key: "release-payments",
		name: "Release Payments",
		url: "/release-payments",
		icon: <FaMoneyBillTransfer className="text-2xl" />,
	},
	{
		key: "cash-office-reports",
		name: "Payment Reports",
		url: "/paidclaims",
		icon: <TbCalendarDollar className="text-2xl" />,
	},
	{
		key: "system-users",
		name: "System Users",
		url: "/allusers",
		icon: <HiUsers className="text-2xl" />,
	},
];

const accessLevels: { [key: string]: string[] } = {
	"5": [
		"dashboard",
		"my-invoices",
		"raise-claim",
		"raise-diem-claim",
		"manager-approvals",
		"finance-approvals",
		"reports",
		"release-payments",
		"cash-office-reports",
		"system-users",
	],
	"4": [
		"dashboard",
		"my-invoices",
		"raise-claim",
		"raise-diem-claim",
		"manager-approvals",
		"release-payments",
		"cash-office-reports",
	],
	"1": ["dashboard", "my-invoices", "raise-claim", "manager-approvals"],
	"3": [
		"dashboard",
		"my-invoices",
		"raise-claim",
		"raise-diem-claim",
		"manager-approvals",
		"finance-approvals",
		"reports",
	],
	"6": [
		"dashboard",
		"my-invoices",
		"raise-claim",
		"raise-diem-claim",
		"manager-approvals",
		"finance-approvals",
		"reports",
	],
	"7": [
		"dashboard",
		"my-invoices",
		"raise-claim",
		"raise-diem-claim",
		"manager-approvals",
		"finance-approvals",
		"reports",
	],
	"2": ["dashboard", "my-invoices", "raise-claim", "manager-approvals"],
	"34": ["dashboard", "my-invoices", "raise-claim", "manager-approvals"],
};

const Menu: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
	const user = useContext(UserContext);

	const getUserMenu = (
		userLevel: number,
		menuLinks: MenuLink[],
		accessLevels: { [key: string]: string[] }
	): MenuLink[] => {
		if (accessLevels.hasOwnProperty(userLevel)) {
			return menuLinks.filter((item) =>
				accessLevels[userLevel].includes(item.key)
			);
		}
		return [];
	};

	if (!user || !user.check)
		return (
			<ul className="flex flex-col gap-2">
				{Array.from({ length: 3 }).map((_, i) => (
					<Skeleton key={i} className="w-full h-10 bg-white" />
				))}
			</ul>
		);

	const userMenu: MenuLink[] = getUserMenu(
		user.check.usertypeid,
		menuLinks,
		accessLevels
	);

	return (
		<ul className="flex flex-col gap-2">
			{userMenu.map((u) => (
				<li key={u.key} onClick={onClick}>
					<NavLink to={u.url} className="sidebar-link">
						{u.icon}
						<span>{u.name}</span>
					</NavLink>
				</li>
			))}
		</ul>
	);
};

export default Menu;
