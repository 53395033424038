import { createContext } from "react";
import { AlertAction, AlertState } from "./AlertReducer";

type AlertContextType = {
	state: AlertState;
	setAlert: (msg: string, type: string, timeout?: number) => void;
};

const alertContext = createContext<AlertContextType | undefined>(undefined);

export default alertContext;
