import React, { useContext } from "react";
import AlertContext from "../../context/alert/AlertContext";

const Alert = () => {
	const alertContext = useContext(AlertContext);

	if (!alertContext) return <></>;
	if (alertContext.state.length === 0) return <></>;

	return (
		<>
			{alertContext.state.map((alert) => (
				<div key={alert.id} className={`zoom-body alert alert-${alert.type}`}>
					<i className="fas fa-info-circle"></i>
					{alert.msg}
				</div>
			))}
		</>
	);
};

export default Alert;
