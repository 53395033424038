import { DateConverter } from "@/constants";
import { Invoice } from "@/services/invoice-functions";
import { ColumnDef } from "@tanstack/react-table";
import ColumnHeader from "./ColumnHeader";
import InvoiceTag from "./InvoiceTag";

export const CashOfficeColumn: ColumnDef<Invoice>[] = [
	{
		accessorKey: "invoicemasterid",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Claim ID" />,
		size: 10,
	},
	{
		accessorKey: "description",
		header: "Description",
		cell: ({ row }) => {
			const invoice = row.original;
			return (
				<div className="text-sm flex gap-1 items-center">
					<InvoiceTag id={invoice.invoicetypeid} type={invoice.invoicetype} />
					<p>
						{invoice.description.length > 100
							? invoice.description.slice(0, 100) + "..."
							: invoice.description}
					</p>
				</div>
			);
		},
	},
	{
		accessorKey: "raisedby",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Raised By" />,
	},
	{
		accessorKey: "amount",
		header: ({ column }) => <ColumnHeader column={column} title="Amount" />,
		cell: ({ row }) => {
			return <div className="font-medium">Ksh {row.getValue("amount")}</div>;
		},
	},
	{
		accessorKey: "raisedon",
		header: ({ column }) => <ColumnHeader column={column} title="Raised On" />,
		cell: ({ row }) => {
			const date = new Date(row.getValue("raisedon"));
			return DateConverter(date);
		},
	},
];
