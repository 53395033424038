import React from "react";
import { Skeleton } from "../ui/skeleton";

const InvoiceSkeleton = () => {
	return (
		<>
			<Skeleton className="h-12 w-1/2 mb-6" />
			<div className="flex gap-4">
				<Skeleton className="h-8 w-36" />
				<Skeleton className="h-8 w-36" />
			</div>
			<div className="border-y grid grid-cols-1 sm:grid-cols-2 py-4 border-zinc-200 gap-4 my-4">
				{Array.from({ length: 10 }).map((_, i) => (
					<Skeleton key={i} className="w-full h-8" />
				))}
			</div>
		</>
	);
};

export default InvoiceSkeleton;
