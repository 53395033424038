import React, { useState } from "react";
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../ui/dialog";
import { Button } from "../ui/button";
import { z } from "zod";
import {
	Form,
	FormControl,
	FormField,
	FormItem,
	FormLabel,
	FormMessage,
} from "../ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "react-query";
import { toast } from "sonner";
import {
	getUserByEmail,
	UpdateUserdepartment,
} from "@/services/auth-functions";
import { Input } from "../ui/input";
import { UserDepartment } from "@/context/User/UserContext";
import { HiOutlinePencilAlt } from "react-icons/hi";

const formSchema = z.object({
	email: z.string().email(),
});

const UpdateManager: React.FC<{
	accessToken: string;
}> = ({ accessToken }) => {
	const [open, setOpen] = useState(false);

	const getManager = useMutation({
		mutationKey: ["getManager"],
		mutationFn: (email: string) => getUserByEmail(accessToken, email),
		onSuccess: (response: UserDepartment) => {
			setManager.mutate({
				managername: response.displayName,
				manageremail: response.userPrincipalName,
			});
		},
		onError: () => {
			toast.error("Error", { description: "User Not found" });
		},
	});

	const setManager = useMutation({
		mutationKey: ["setManager"],
		mutationFn: (manager: { managername: string; manageremail: string }) =>
			UpdateUserdepartment(accessToken, manager),
		onSuccess: () => {
			toast.success("Approver Updated", {
				description: "Your approver has successfully been updated",
			});
			setOpen(false);
			form.reset();
			setManager.reset();
			window.location.reload();
		},
		onError: () => {
			toast.error("Error: User not found", {
				description: "The email address provided does not belong to any user.",
			});
		},
	});

	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
	});

	const onSubmit = (data: z.infer<typeof formSchema>) => {
		getManager.mutate(data.email);
	};

	const disabled = () => {
		if (getManager.isLoading) return true;
		if (getManager.isSuccess) return true;
		return false;
	};

	return (
		<Dialog open={open} onOpenChange={(open) => setOpen(open)}>
			<DialogTrigger asChild>
				<Button>Update Approver</Button>
			</DialogTrigger>
			<DialogContent className="sm:max-w-md">
				<DialogHeader className="text-left">
					<DialogTitle className="text-purple-500 flex gap-2 items-center mb-1">
						<div className="bg-purple-200 p-1 rounded-full">
							<HiOutlinePencilAlt className="text-xl" />
						</div>
						Assign Approver
					</DialogTitle>
					<DialogDescription>Update or set your invoice approver.</DialogDescription>
				</DialogHeader>
				<Form {...form}>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<div className="flex items-center space-x-2 mb-4">
							<div className="grid flex-1 gap-2">
								<FormField
									name="email"
									render={({ field }) => (
										<FormItem className="mb-2">
											<FormLabel>Approver Email</FormLabel>
											<FormControl>
												<Input
													type="email"
													placeholder="Enter email"
													disabled={disabled()}
													{...field}
												/>
											</FormControl>
											<FormMessage />
										</FormItem>
									)}
								/>
							</div>
						</div>
						<DialogFooter className="sm:justify-end">
							<DialogClose asChild>
								<Button type="button" variant="ghost">
									Cancel
								</Button>
							</DialogClose>
							<Button type="submit" disabled={disabled()}>
								{disabled() ? "Submitting..." : "Submit"}
							</Button>
						</DialogFooter>
					</form>
				</Form>
			</DialogContent>
		</Dialog>
	);
};

export default UpdateManager;
