import React from "react";
import DataTable from "@/components/data-table/DataTable";
import { Button } from "@/components/ui/button";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { UserContext } from "@/context/User/UserContext";
import { cn } from "@/lib/utils";
import { getPaidInvoices, Invoice } from "@/services/invoice-functions";
import { useContext } from "react";
import { IoCalendarSharp } from "react-icons/io5";
import { useQuery } from "react-query";
import { NavLink } from "react-router-dom";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import {
	DateConverter,
	getFileExtension,
	imageExtensions,
	ISODateFormat,
	STALE_TIME_INVOICE,
} from "@/constants";
import { ColumnDef } from "@tanstack/react-table";
import ColumnHeader from "../data-table/ColumnHeader";
import InvoiceTag from "../data-table/InvoiceTag";
import { paymentMethodTag } from "@/pages/ReleasePayments";
import ReportImages from "../ReportImages";
import ReportDocuments from "../ReportDocuments";

const PaidInvoices = () => {
	const user = useContext(UserContext);

	const [date, setDate] = React.useState<Date | undefined>(new Date());
	const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);

	const paidInvoices = useQuery({
		queryKey: ["invoice", "paid", date],
		queryFn: () =>
			getPaidInvoices(
				user?.accessToken!,
				ISODateFormat(date),
				ISODateFormat(date)
			),
		enabled: !!user?.accessToken && !!date,
		staleTime: STALE_TIME_INVOICE,
	});

	return (
		<div>
			<div className="flex justify-end py-2">
				<Popover open={isPopUpOpen} onOpenChange={setIsPopUpOpen}>
					<PopoverTrigger asChild>
						<Button
							id="date"
							variant={"outline"}
							className={cn(
								"w-[300px] justify-start text-left font-normal",
								!date && "text-muted-foreground"
							)}
						>
							<IoCalendarSharp className="mr-2 h-4 w-4" />
							{date ? format(date, "LLL dd, y") : <span>Pick a date</span>}
						</Button>
					</PopoverTrigger>
					<PopoverContent className="w-auto p-2" align="start">
						<Calendar
							initialFocus
							defaultMonth={date}
							mode="single"
							selected={date}
							onSelect={(date) => {
								setDate(date);
								setIsPopUpOpen(false);
							}}
							numberOfMonths={1}
						/>
					</PopoverContent>
				</Popover>
			</div>
			{paidInvoices.data ? (
				<DataTable
					columns={Column}
					data={paidInvoices.data}
					searchColumn="raisedby"
				/>
			) : null}
			{paidInvoices.isLoading ? (
				<div className="align-centre">
					<h5 className="text-center">Loading claims, please wait...</h5>
				</div>
			) : paidInvoices.isError ? (
				<div className="align-centre">
					<h5 className="text-center">Error showing claims.</h5>
				</div>
			) : (
				paidInvoices.data &&
				paidInvoices.data.length === 0 && (
					<div className="align-centre">
						<h5 className="text-center">No claims found</h5>
					</div>
				)
			)}
		</div>
	);
};

export default PaidInvoices;

const Column: ColumnDef<Invoice>[] = [
	{
		accessorKey: "invoicemasterid",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Claim ID" />,
		size: 10,
		cell: ({ row }) => (
			<div className="flex items-center">
				<NavLink to={`/generalreport/${row.original.invoicemasterid}`}>
					<Button variant="link" className="px-2 py-1 font-light">
						{row.getValue("invoicemasterid")}
					</Button>
				</NavLink>
			</div>
		),
	},
	{
		accessorKey: "description",
		header: "Description",
		cell: ({ row }) => {
			const invoice = row.original;
			return (
				<div className="text-sm flex gap-1 items-center">
					<InvoiceTag id={invoice.invoicetypeid} type={invoice.invoicetype} />
					<p>
						{invoice.description.length > 100
							? invoice.description.slice(0, 100) + "..."
							: invoice.description}
					</p>
				</div>
			);
		},
	},
	{
		accessorKey: "raisedby",
		enableHiding: false,
		header: ({ column }) => <ColumnHeader column={column} title="Raised By" />,
	},
	{
		accessorKey: "amount",
		header: ({ column }) => <ColumnHeader column={column} title="Amount" />,
		cell: ({ row }) => {
			return <div className="font-medium">Ksh {row.getValue("amount")}</div>;
		},
	},
	{
		accessorKey: "raisedon",
		header: ({ column }) => <ColumnHeader column={column} title="Raised On" />,
		cell: ({ row }) => {
			const date = new Date(row.getValue("raisedon"));
			return DateConverter(date);
		},
	},
	{
		accessorKey: "paymentmethoddes",
		header: ({ column }) => (
			<ColumnHeader column={column} title="Payment Method" />
		),
		cell: ({ row }) => {
			return paymentMethodTag(row.getValue("paymentmethoddes"));
		},
	},
	{
		accessorKey: "files",
		header: "Attachments",
		cell: ({ row }) => {
			const files: string | null = row.getValue("files");
			const [im, fl] = files
				? files
						.split(",")
						.slice(0, 4)
						.reduce<[string[], string[]]>(
							([images, files], attachment) => {
								if (imageExtensions.includes(getFileExtension(attachment))) {
									images.push(attachment);
								} else {
									files.push(attachment);
								}
								return [images, files];
							},
							[[], []]
						)
				: [[], []];
			return (
				<div className="flex flex-wrap">
					{im ? <ReportImages images={im} /> : null}
					{fl ? <ReportDocuments docs={fl} /> : null}
				</div>
			);
		},
	},
];
