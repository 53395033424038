import {
	AuthenticatedTemplate,
	UnauthenticatedTemplate,
} from "@azure/msal-react";
import AuthState from "@/context/Auth/AuthState";
import InvoiceState from "@/context/Invoice/InvoiceState";
import AlertState from "@/context/alert/AlertState";
import RouteComponent from "./RouteComponent";
import UserState from "@/context/User/UserState";
import Login from "@/pages/Login";

const MainContent = () => {
	return (
		<div className="App">
			<AuthenticatedTemplate>
				<UserState>
					<AuthState>
						<InvoiceState>
							<AlertState>
								<RouteComponent />
							</AlertState>
						</InvoiceState>
					</AuthState>
				</UserState>
			</AuthenticatedTemplate>

			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</div>
	);
};

export default MainContent;
