import { UserContext } from "@/context/User/UserContext";
import {
	getSingleMasterInvoiceDetail,
	Invoice,
} from "@/services/invoice-functions";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import EditClaimPerDiem from "./EditClaimPerDiem";
import EditClaim from "./EditClaim";
import { STALE_TIME_INVOICE } from "@/constants";

const EditClaimPerDiemHolder: React.FC<{ invoice: Invoice }> = ({
	invoice,
}) => {
	const user = useContext(UserContext);
	const invoiceDetails = useQuery({
		queryKey: ["invoice", "details", invoice.invoicemasterid],
		queryFn: () =>
			getSingleMasterInvoiceDetail(
				user?.accessToken!,
				invoice.invoicemasterid.toString()
			),
		staleTime: STALE_TIME_INVOICE,
	});

	return (
		<>
			{invoiceDetails.data && invoiceDetails.data.length > 0 ? (
				invoice.invoicetypeid === 6 ? (
					<EditClaimPerDiem invoice={invoice} invoiceDetails={invoiceDetails.data} />
				) : (
					<EditClaim invoice={invoice} invoiceDetail={invoiceDetails.data} />
				)
			) : null}
		</>
	);
};

export default EditClaimPerDiemHolder;
