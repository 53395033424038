import React, { useReducer } from "react";
import InvoiceContext from "./InvoiceContext";
import InvoiceReducer from "./InvoiceReducer";
import axios from "axios";
import {
	CREATE_INVOICE,
	CREATE_INVOICE_ERROR,
	GET_INVOICE_TYPES,
	GET_INVOICE_TYPES_ERROR,
	INVOICE_TRACKING,
	INVOICE_TRACKING_ERROR,
	CREATE_PER_DIEM,
	CREATE_PER_DIEM_ERROR,
	INVOICE_TRACKING_DETAIL,
	INVOICE_TRACKING_DETAIL_ERROR,
	GET_FINANCE_APPROVE_LIST,
	GET_FINANCE_APPROVE_LIST_ERROR,
	GET_MANAGER_APPROVE_LIST,
	GET_MANAGER_APPROVE_LIST_ERROR,
	MANAGER_APPROVE,
	MANAGER_APPROVE_ERROR,
	CLEAR_INVOICE_ERRORS,
	FINANCE_APPROVE,
	FINANCE_APPROVE_ERROR,
	GET_PAYMENT_METHODS,
	GET_PAYMENT_METHODS_ERROR,
	FINANCE_REJECT,
	FINANCE_REJECT_ERROR,
	MANAGER_REJECT,
	MANAGER_REJECT_ERROR,
	GET_GL_ACCOUNTS,
	GET_GL_ACCOUNTS_ERROR,
	GET_COST_CENTRES,
	GET_COST_CENTRES_ERROR,
	INVOICE_MASTER_TRACKING,
	INVOICE_MASTER_TRACKING_ERROR,
	INVOICE_MASTER_FINANCE_TRACKING,
	INVOICE_MASTER_FINANCE_TRACKING_ERROR,
	CREATE_LOCAL_PER_DIEM,
	CREATE_LOCAL_PER_DIEM_ERROR,
	GET_TYPES_OF_PER_DIEMS,
	GET_TYPES_OF_PER_DIEMS_ERROR,
	GET_INVOICE_MASTER,
	GET_INVOICE_MASTER_ERROR,
	GET_INVOICE_MASTER_SINGLE,
	GET_INVOICE_MASTER_SINGLE_ERROR,
	GET_ALL_INVOICES_APPROVED,
	GET_ALL_INVOICES_APPROVED_ERROR,
	GET_ALL_INVOICES_REJECTED,
	GET_ALL_INVOICES_REJECTED_ERROR,
	GET_ALL_INVOICES_PROGRESS,
	GET_ALL_INVOICES_PROGRESS_ERROR,
	UPDATE_AWS_FILES,
	UPDATE_AWS_FILES_ERROR,
	CASH_OFFICE_COLLECTION,
	CASH_OFFICE_COLLECTION_ERROR,
	GET_ALL_INVOICES_APPROVED_NOT_PAID,
	GET_ALL_INVOICES_APPROVED_NOT_PAID_ERROR,
	GET_ALL_INVOICES_PAID,
	GET_ALL_INVOICES_PAID_ERROR,
} from "../types";

export interface Invoice {
	invoicemasterid: number;
	amount: string;
	raisedbyid: number;
	raisedon: string;
	raisedat: string;
	files: string | null;
	jobgradeid: number | null;
	raisedby: string;
	jobgradedescription: string | null;
	fullyapproved: null;
	invoicetypeid: number;
	invoicetype: string;
	description: string;
	managerapproved: boolean;
	managerapprovedby: number;
	financeapproved: boolean;
	financeapprovedby: number;
	financeapprovedremark: string;
	financerejectedremark: string | null;
	managerapprovedremark: string;
	managerrejectedremark: string | null;
	financerejected: null;
	managerrejected: null;
	financerejectedby: number | null;
	managerrejectedby: number | null;
	managerapprovedon: string | null;
	financeapprovedon: string | null;
	managerapprovedat: string | null;
	financeapprovedat: string | null;
	managerrejectedon: string | null;
	financerejectedon: string | null;
	managerrejectedat: string | null;
	financerejectedat: string | null;
	glaccountid: number;
	costcentreid: number;
	paymentmethod: number;
	managerapprovedbyname: string | null;
	financeapprovedbyname: string | null;
	financerejectedbyname: string | null;
	managerrejectedbyname: string | null;
	glaccountdescription: string;
	glaccount: number;
	costcentre: number;
	product: string;
	paymentmethoddes: string;
	continent: null;
	country: string | null;
	traveltypeid: number | null;
	traveltypedescription: string | null;
	department: string;
	hodemail: string;
	collected: boolean;
	amountcollected: string;
	collectedby: string;
	collectionfacilitator: number;
	datecollected: string;
	timecollected: string;
	phone: string;
}

export type PaymentMethod = {
	paymentmethodid: number;
	paymentmethoddes: string;
};

const InvoiceState = ({ children }: { children: React.ReactNode }) => {
	const initialState = {
		// token: localStorage.getItem("token"),
		invoicetypes: null,
		invoicecreate: null,
		getinvoicetracking: null,
		createperdiem: null,
		createlocalperdiem: null,
		getinvoicetrackingdetail: null,
		financelistinvoices: null,
		managerlistinvoices: null,
		managerapprove: null,
		financeapprove: null,
		getpaymentmethods: null,
		financereject: null,
		managerreject: null,
		getglaccounts: null,
		getcostcentres: null,
		getinvoicemastertrackingdetail: null,
		getinvoicemastertrackingfdetail: null,
		getperdiemtypes: null,
		getinvoicemaster: null,
		getinvoicemastersingle: null,
		invoicemasterapproved: null,
		invoicemasterrejected: null,
		invoicemasterprogress: null,
		updateawsfiles: null,
		invoiceerror: null,
		cashofficecollection: null,
		getallapprovednotpaid: null,
		getallpaid: null,
	};

	const [state, dispatch] = useReducer(InvoiceReducer, initialState);

	const server_url = process.env.REACT_APP_SERVER_DOMAIN;

	// GET INVOICE TYPES
	const getInvoiceTypes = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/invoicetype`, config);
			dispatch({
				type: GET_INVOICE_TYPES,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_INVOICE_TYPES_ERROR,
				payload: err.response,
			});
		}
	};

	const getInvoiceMaster = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/getinvoicemaster`, config);
			dispatch({
				type: GET_INVOICE_MASTER,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_INVOICE_MASTER_ERROR,
				payload: err.response,
			});
		}
	};

	// GET PAYMENT TYPES
	const getPaymentMethods = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/getpaymentmethods`,
				config
			);
			dispatch({
				type: GET_PAYMENT_METHODS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_PAYMENT_METHODS_ERROR,
				payload: err.response,
			});
		}
	};

	// GET INVOICE TRACKING
	const getInvoiceTracking = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/invoicetracking`, config);
			dispatch({
				type: INVOICE_TRACKING,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: INVOICE_TRACKING_ERROR,
				payload: err.response,
			});
		}
	};

	// GET FINANCE APPROVE LIST

	const FinanceListInvoices = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/financeapprovelist`,
				config
			);
			dispatch({
				type: GET_FINANCE_APPROVE_LIST,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_FINANCE_APPROVE_LIST_ERROR,
				payload: err.response,
			});
		}
	};

	const InvoiceMasterProgress = async (startdate: string, enddate: string) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/getallinprogress/${startdate}/${enddate}`,
				config
			);
			dispatch({
				type: GET_ALL_INVOICES_PROGRESS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_ALL_INVOICES_PROGRESS_ERROR,
				payload: err.response,
			});
		}
	};

	const InvoiceMasterRejected = async (startdate: string, enddate: string) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/getallrejected/${startdate}/${enddate}`,
				config
			);
			dispatch({
				type: GET_ALL_INVOICES_REJECTED,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_ALL_INVOICES_REJECTED_ERROR,
				payload: err.response,
			});
		}
	};

	const InvoiceMasterApproved = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/getallapproved`, config);
			dispatch({
				type: GET_ALL_INVOICES_APPROVED,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_ALL_INVOICES_APPROVED_ERROR,
				payload: err.response,
			});
		}
	};

	const InvoiceMasterApprovedNotPaid = async (
		startdate: string,
		enddate: string
	) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/getallapprovednotpaid/${startdate}/${enddate}`,
				config
			);
			dispatch({
				type: GET_ALL_INVOICES_APPROVED_NOT_PAID,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_ALL_INVOICES_APPROVED_NOT_PAID_ERROR,
				payload: err.response,
			});
		}
	};

	const InvoiceMasterPaid = async (startdate: string, enddate: string) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/getallpaid/${startdate}/${enddate}`,
				config
			);
			dispatch({
				type: GET_ALL_INVOICES_PAID,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_ALL_INVOICES_PAID_ERROR,
				payload: err.response,
			});
		}
	};

	// GET FINANCE APPROVE LIST

	const ManagerListInvoices = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/managerapprovelist`,
				config
			);
			dispatch({
				type: GET_MANAGER_APPROVE_LIST,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_MANAGER_APPROVE_LIST_ERROR,
				payload: err.response,
			});
		}
	};

	// GET INVOICE TRACKING
	const getInvoiceTrackingDetail = async (invoiceid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/invoicetrackingdetail/${invoiceid}`,
				config
			);
			dispatch({
				type: INVOICE_TRACKING_DETAIL,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: INVOICE_TRACKING_DETAIL_ERROR,
				payload: err.response,
			});
		}
	};

	// GET INVOICE MASTER TRACKING
	const getInvoiceMasterTrackingDetail = async (invoicemasterid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/invoicetrackingmasterdetail/${invoicemasterid}`,
				config
			);
			dispatch({
				type: INVOICE_MASTER_TRACKING,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: INVOICE_MASTER_TRACKING_ERROR,
				payload: err.response,
			});
		}
	};

	// getinvoicemastersingle
	const getInvoiceMasterSingle = async (invoicemasterid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/getinvoicemastersingle/${invoicemasterid}`,
				config
			);
			console.log(res.data);
			dispatch({
				type: GET_INVOICE_MASTER_SINGLE,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_INVOICE_MASTER_SINGLE_ERROR,
				payload: err.response,
			});
		}
	};

	// GET INVOICE FINANCE MASTER TRACKING
	const getInvoiceMasterTrackingFDetail = async (invoicemasterid: number) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(
				`${server_url}invoice/invoicetrackingmasterfinancedetail/${invoicemasterid}`,
				config
			);
			dispatch({
				type: INVOICE_MASTER_FINANCE_TRACKING,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: INVOICE_MASTER_FINANCE_TRACKING_ERROR,
				payload: err.response,
			});
		}
	};

	// GET GL ACCOUNTS
	const getGlAccounts = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/getglaccounts`, config);
			dispatch({
				type: GET_GL_ACCOUNTS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_GL_ACCOUNTS_ERROR,
				payload: err.response,
			});
		}
	};

	// getCostCentres
	const getCostCentres = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/getcostcentres`, config);
			dispatch({
				type: GET_COST_CENTRES,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_COST_CENTRES_ERROR,
				payload: err.response,
			});
		}
	};

	const getPerdiemTypes = async () => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};
		try {
			const res = await axios.get(`${server_url}invoice/perdiemtypes`, config);
			dispatch({
				type: GET_TYPES_OF_PER_DIEMS,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: GET_TYPES_OF_PER_DIEMS_ERROR,
				payload: err.response,
			});
		}
	};

	const InvoiceCreate = async (tableData: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object
		//  const formData = new FormData();
		tableData.forEach((data: any, index: number) => {
			data.files.forEach((file: any) => {
				formData.append(`files[${index}][]`, file);
			});
			formData.append("descriptions", data.description);
			formData.append("invoicetypeids", data.invoicetypeid);
			formData.append("amounts", data.amount);
			formData.append("kms", data.kms);
		});

		try {
			const res = await axios.post(
				`${server_url}invoice/createinvoice`,
				formData,
				config
			);
			dispatch({
				type: CREATE_INVOICE,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: CREATE_INVOICE_ERROR,
				payload: err.response.data,
			});
		}
	};

	const CreateLocalPerDiem = async (perd: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object

		formData.append("description", perd.description);
		formData.append("jobgradeids", perd.jobgradeid);
		formData.append("totalamount", perd.totalamount);
		perd.files.forEach((file: any) => {
			formData.append("files", file);
		});
		perd.cities.forEach((file: any) => {
			formData.append("cities", file);
		});
		perd.numberofnights.forEach((file: any) => {
			formData.append("numberofnights", file);
		});
		perd.typeofpayments.forEach((file: any) => {
			formData.append("typeofpayments", file);
		});

		perd.amounts.forEach((file: any) => {
			formData.append("amounts", file);
		});

		try {
			const res = await axios.post(
				`${server_url}invoice/createlocalperdiem`,
				formData,
				config
			);
			dispatch({
				type: CREATE_LOCAL_PER_DIEM,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: CREATE_LOCAL_PER_DIEM_ERROR,
				payload: err.response.data,
			});
		}
	};

	const CreatePerDiem = async (perd: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object

		formData.append("description", perd.description);
		formData.append("submittedcountry", perd.submittedcountry);
		formData.append("submittedcontinent", perd.submittedcontinent);
		formData.append("jobgradeids", perd.jobgradeid);
		formData.append("totalamount", perd.totalamount);
		perd.files.forEach((file: any) => {
			formData.append("files", file);
		});
		perd.numberofnights.forEach((file: any) => {
			formData.append("numberofnights", file);
		});
		perd.typeofpayments.forEach((file: any) => {
			formData.append("typeofpayments", file);
		});

		perd.amounts.forEach((file: any) => {
			formData.append("amounts", file);
		});

		try {
			// for (const value of formData.values()) {
			// 	console.log(value);
			// }
			const res = await axios.post(
				`${server_url}invoice/createperdiem`,
				formData,
				config
			);
			dispatch({
				type: CREATE_PER_DIEM,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: CREATE_PER_DIEM_ERROR,
				payload: err.response.data,
			});
		}
	};

	const UpdateAwsFiles = async (perd: any) => {
		const config = {
			headers: {
				Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
				"Content-Type": "multipart/form-data",
			},
		};

		const formData = new FormData();
		// Append the table data to the FormData object
		formData.append("invoiceid", perd.invoiceid);
		perd.files.forEach((file: any) => {
			formData.append("files", file);
		});
		perd.removedFiles.forEach((file: any) => {
			formData.append("removedfiles", file);
		});

		try {
			// for (const value of formData.values()) {
			// 	console.log(value);
			// }
			const res = await axios.post(
				`${server_url}invoice/updateawsfiles`,
				formData,
				config
			);
			dispatch({
				type: UPDATE_AWS_FILES,
				payload: res.data,
			});
		} catch (err: any) {
			console.log("====================================");
			console.log(err);
			console.log("====================================");
			dispatch({
				type: UPDATE_AWS_FILES_ERROR,
				payload: err.response,
			});
		}
	};

	const CashOfficeCollection = async (collection: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}invoice/cashcollection`,
				collection,
				config
			);
			dispatch({
				type: CASH_OFFICE_COLLECTION,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: CASH_OFFICE_COLLECTION_ERROR,
				payload: err.response.data,
			});
		}
	};

	const ManagerApprove = async (approval: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}invoice/ManagerApprove`,
				approval,
				config
			);
			dispatch({
				type: MANAGER_APPROVE,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: MANAGER_APPROVE_ERROR,
				payload: err.response.data,
			});
		}
	};

	const FinanceApprove = async (approval: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}invoice/FinanceApprove`,
				approval,
				config
			);
			dispatch({
				type: FINANCE_APPROVE,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: FINANCE_APPROVE_ERROR,
				payload: err.response.data,
			});
		}
	};

	const FinanceReject = async (approval: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}invoice/FinanceReject`,
				approval,
				config
			);
			dispatch({
				type: FINANCE_REJECT,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: FINANCE_REJECT_ERROR,
				payload: err.response.data,
			});
		}
	};

	const ManagerReject = async (approval: any) => {
		const headers = {
			Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
			"Content-Type": "application/json",
		};

		const config = {
			headers: headers,
		};

		try {
			const res = await axios.post(
				`${server_url}invoice/ManagerReject`,
				approval,
				config
			);
			dispatch({
				type: MANAGER_REJECT,
				payload: res.data,
			});
		} catch (err: any) {
			dispatch({
				type: MANAGER_REJECT_ERROR,
				payload: err.response.data,
			});
		}
	};

	// clear package errors
	const clearInvoiceErrors = () => {
		dispatch({
			type: CLEAR_INVOICE_ERRORS,
		});
	};

	return (
		<InvoiceContext.Provider
			value={{
				...state,
				getInvoiceTypes,
				InvoiceCreate,
				getInvoiceTracking,
				CreatePerDiem,
				getInvoiceTrackingDetail,
				FinanceListInvoices,
				ManagerListInvoices,
				ManagerApprove,
				clearInvoiceErrors,
				FinanceApprove,
				getPaymentMethods,
				FinanceReject,
				ManagerReject,
				getGlAccounts,
				getCostCentres,
				getInvoiceMasterTrackingDetail,
				getInvoiceMasterTrackingFDetail,
				CreateLocalPerDiem,
				getPerdiemTypes,
				getInvoiceMaster,
				getInvoiceMasterSingle,
				InvoiceMasterApproved,
				InvoiceMasterRejected,
				InvoiceMasterProgress,
				UpdateAwsFiles,
				CashOfficeCollection,
				InvoiceMasterApprovedNotPaid,
				InvoiceMasterPaid,
			}}
		>
			{children}
		</InvoiceContext.Provider>
	);
};

export default InvoiceState;
